import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import React, { useRef, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { createAssignment } from "../services/assignmentService";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyJoditEditor from "../helpers/MyJoditEditor";
import useMyJoditEditor from "../hooks/useMyJoditEditor";
import {
  addAssignmentSchema,
  getAddAssignmentSchema,
} from "./validations/addAssignmentSchema";
import NewFile from "../general/components/NewFile";
import { useTranslation } from "react-i18next";

function AddAssignmentT() {
  const { t } = useTranslation("global");
  const validationSchema = getAddAssignmentSchema(t);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { token, selectedGroupId } = useSelector((x) => x.security) || {};
  const { content, isDirty, onBlur, fileKey, isSaving } = useMyJoditEditor();
  const { mutate, isLoading } = useMutation(
    (data) => createAssignment(token, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["create-assignment"],
        });
        navigate("/teacher/assignments");
      },
    }
  );
  const [newFiles, setNewFiles] = useState([]);
  const formik = useFormik({
    initialValues: {
      name: null,
      deadline: null,
      isGraded: false,
      groupId: selectedGroupId,
      allowSubmissionEdit: false,
    },
    onSubmit: (v) => {
      let values = {...v}
      values.newFileKeys = newFiles.filter((x) => x.key).map((x) => x.key);
      values.description = fileKey;
      values.deadline =
        values.deadline == null || values.deadline == ""
          ? null
          : new Date(values.deadline).toISOString();
      mutate(values);
    },
    validationSchema: validationSchema,
  });

  const handleFileChange = (event) => {
    const files = event.currentTarget.files;
    const updatedFiles = [
      ...newFiles,
      ...Array.from(files).map((x) => ({ file: x, id: uuidv4() })),
    ];
    setNewFiles(updatedFiles);
  };

  const onFileUpload = (id, key) => {
    setNewFiles((prev) => {
      let file = prev.find((x) => x.id == id);
      if (file == null) return prev;
      file.key = key;
      return prev;
    });
  };

  const handleRemoveFile = (id) => {
    const updatedFiles = newFiles.filter((x) => x.id != id);
    setNewFiles(updatedFiles);
  };

  const fileInputRef = useRef(null);

  const handleIconClick = (e) => {
    fileInputRef.current.click();
  };

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <Box>
          <PageTitle
            status={"success"}
            title={t("Add Assignment")}
            icon={faClipboardList}
            backPath={'/teacher/assignments'}
          />
        </Box>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="100%"
          margin="8px auto"
          p="20px 0"
          gap="20px"
          bg="#EAF5F4"
        >
          <FormControl
            // border="1px solid red"
            w={["90%", "80%", "70%", "60%", "50%"]}
          >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Assignment name")}
            </FormLabel>
            <Input
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="name"
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="text"
              placeholder={t("Assignment name")}
              _placeholder={{ color: "#666666" }}
            />
            {formik.errors.name && formik.touched.name && (
              <Text p="0" pl="4px" m="0" color="red">
                {formik.errors.name}
              </Text>
            )}
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Description")}
            </FormLabel>
            <MyJoditEditor
              isSaving={isSaving}
              content={content}
              onBlur={onBlur}
            />
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Deadline")}
            </FormLabel>
            <Input
              value={formik.values.deadline}
              onChange={formik.handleChange}
              name="deadline"
              bg="white"
              color="#000000"
              borderRadius="4px"
              p="0 12px"
              type="datetime-local"
            />
          </FormControl>

          <FormControl display={"flex"} w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Add attachments")}
            </FormLabel>
            <FontAwesomeIcon
              color="green"
              fontSize="24px"
              icon={faPlusCircle}
              onClick={handleIconClick}
              style={{ cursor: "pointer" }}
            />
            <Input
              ref={fileInputRef}
              bg="white"
              color="#000000"
              borderRadius="4px"
              p="0 12px"
              type="file"
              multiple
              display="none"
              sx={{
                "::-webkit-file-upload-button": {
                  display: "none",
                },
              }}
              textStyle={{ placeholder: "There is no file" }}
              paddingTop="7px"
              onChange={handleFileChange}
            />
          </FormControl>
          {newFiles.length > 0 && (
            <Box w={["90%", "80%", "70%", "60%", "50%"]}>
              <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                {t("Selected attachments")}:
              </FormLabel>
              <Flex w="100%" flexWrap="wrap" gap="12px">
                {newFiles?.map((x) => (
                  <NewFile
                    onUploadFunc={(key) => onFileUpload(x.id, key)}
                    file={x.file}
                    key={x.id}
                    onRemoveFunc={() => handleRemoveFile(x.id)}
                  />
                ))}
              </Flex>
            </Box>
          )}
          <FormControl
            w={["90%", "80%", "70%", "60%", "50%"]}
            display="flex"
            alignItems="center"
          >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Graded assignment")}
              <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                {t(
                  "Assignments can be either graded or ungraded. Submissions of only graded assignments can be assessed. It is possible to change an assignment's status after it has been added."
                )}
              </FormLabel>
            </FormLabel>
            <Checkbox
              name="isGraded"
              onChange={formik.handleChange}
              isChecked={formik.values.isGraded}
              colorScheme="teal"
              borderColor={"teal"}
              size="lg"
            />
          </FormControl>
          <FormControl
            w={["90%", "80%", "70%", "60%", "50%"]}
            display="flex"
            alignItems="center"
          >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Allow submission edit")}
              <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                {t(
                  "If submission edits are enabled, students can modify their submissions after submitting. If disabled, they cannot change their submissions. This setting can be adjusted even after the assignment is created."
                )}
              </FormLabel>
            </FormLabel>
            <Checkbox
              name="allowSubmissionEdit"
              onChange={formik.handleChange}
              isChecked={formik.values.allowSubmissionEdit}
              colorScheme="teal"
              borderColor={"teal"}
              size="lg"
            />
          </FormControl>
          <Button
            isLoading={isLoading}
            isDisabled={(!formik.dirty && !isDirty) || isSaving}
            bg={colors.primary}
            color="white"
            w={["90%", "80%", "70%", "60%", "50%"]}
            h="40px"
            fontWeight="600"
            fontSize="16px"
            _hover={{ bg: "#2a6b68" }}
            onClick={formik.handleSubmit}
          >
            {t("Add")}
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
}

export default AddAssignmentT;
