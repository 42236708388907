import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { colors } from "../Constants";
import LogOutModal from "../student/components/LogOutModal";
import SelectGroupCard from "../student/components/card/SelectGroupCard";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { myGroupsPromise } from "../services/groupService";
import { useTranslation } from "react-i18next";
import { s3Client } from "../helpers/s3Client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEarthAmericas,
  faMoneyBillTransfer,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import NotificationModal from "../student/components/NotificationModal";
import { setLanguage } from "../redux/slices/languageSlice";

export default function SelectGroupT() {
  const { name, token } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(["my-groups", token], () =>
    myGroupsPromise(token)
  );
  const { t, i18n } = useTranslation("global");
  const { language } = useSelector((x) => x.language) || {};
  const navigate = useNavigate();

  const handleAccountNavigation = () => {
    navigate("/teacher/account", { state: { fromSelectGroup: true } });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };
  const handlePayrollNavigation = () => {
    navigate("/teacher/payroll", { state: { fromSelectGroup: true } });
  };

  const activeStyle = {
    backgroundColor: colors.primary,
    color: "white",
  };

  return (
    <>
      <Flex
        padding="24px"
        as="header"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          "@media (max-width: 500px)": {
            flexDirection: "column-reverse",
            paddingBottom: "12px",
          },
        }}
      >
        <Text
          fontWeight="bolder"
          mb="4px"
          fontSize="3xl"
          sx={{
            "@media (max-width: 600px)": {
              fontSize: "2xl",
            },
            "@media (max-width: 500px)": {
              fontSize: "xl",
              mb: "0px",
            },
          }}
        >
          {" "}
          {t("greating")}, {name}
        </Text>
        <HStack
          sx={{
            "@media (max-width: 500px)": {
              w: "100%",
              justifyContent: "end",
              mb: "20px",
            },
          }}
        >
          <NotificationModal
            bgColor={colors.primary}
            color="white"
            hoverColor="white"
            hoverBg="teal"
          />
          <Button
            colorScheme="teal"
            onClick={handlePayrollNavigation}
            p="6px"
            w="50px"
            borderRadius="4px"
            sx={{
              "@media (max-width: 700px)": {
                h: "32px",
                w: "32px",
              },
              "@media (max-width: 500px)": {
                h: "28px",
                w: "28px",
              },
            }}
          >
            <FontAwesomeIcon
              fontSize="20px"
              color="white"
              icon={faMoneyBillTransfer}
            />
          </Button>
          <Button
            colorScheme="teal"
            onClick={handleAccountNavigation}
            p="6px"
            w="50px"
            borderRadius="4px"
            sx={{
              "@media (max-width: 700px)": {
                h: "32px",
                w: "32px",
              },
              "@media (max-width: 500px)": {
                h: "28px",
                w: "28px",
              },
            }}
          >
            <FontAwesomeIcon fontSize="20px" color="white" icon={faUserEdit} />
          </Button>
          <Menu>
            <MenuButton
              colorScheme="teal"
              p="6px"
              w="50px"
              borderRadius="4px"
              as={Button}
              sx={{
                "@media (max-width: 700px)": {
                  h: "32px",
                  w: "32px",
                },
                "@media (max-width: 500px)": {
                  h: "28px",
                  w: "28px",
                },
              }}
            >
              <FontAwesomeIcon
                fontSize="20px"
                color="white"
                icon={faEarthAmericas}
              />
            </MenuButton>
            <MenuList padding={"10px"}>
              <MenuItem
                style={language === "en" ? activeStyle : {}}
                onClick={() => handleLanguageChange("en")}
                fontWeight="500"
                _hover={{ color: colors.primary }}
              >
               EN
              </MenuItem>
              <MenuItem
                style={language === "ru" ? activeStyle : {}}
                onClick={() => handleLanguageChange("ru")}
                fontWeight="500"
                _hover={{ color: colors.primary }}
              >
                RU
              </MenuItem>
              <MenuItem
                style={language === "aze" ? activeStyle : {}}
                onClick={() => handleLanguageChange("aze")}
                fontWeight="500"
                _hover={{ color: colors.primary }}
              >
                AZE
              </MenuItem>
            </MenuList>
          </Menu>
          <LogOutModal
            bgColor={colors.primary}
            color="white"
            hoverColor="white"
            hoverBg="teal"
          />
        </HStack>
      </Flex>
      <Text
        fontSize={["32px", "36px", "40px", "48px"]}
        fontWeight="700"
        textAlign="center"
        pb={6}
      >
        {t("selectGroup")}
      </Text>
      <Box
        position={"relative"}
        bg={colors.primary}
        minHeight="73.5vh"
        as="section"
      >
        {status == "loading" && (
          <Grid
            padding="24px"
            templateRows="repeat(1, 1fr)"
            templateColumns={{
              base: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(3, 1fr)",
              xl: "repeat(3, 1fr)",
            }}
            gap={3}
          >
            <GridItem colSpan={1}>
              <Skeleton>
                <SelectGroupCard />
              </Skeleton>
            </GridItem>
            <GridItem colSpan={1}>
              <Skeleton>
                <SelectGroupCard />
              </Skeleton>
            </GridItem>
            <GridItem colSpan={1}>
              <Skeleton>
                <SelectGroupCard />
              </Skeleton>
            </GridItem>
          </Grid>
        )}

        {status == "success" && resp?.data?.length == 0 && (
          <Box
            transform={"translate(-50%, -50%)"}
            left={"50%"}
            top={"50%"}
            position={"absolute"}
          >
            <Text
              fontSize={"xx-large"}
              fontWeight={"bolder"}
              color={"white"}
              width={"max-csontent"}
            >
              {t("empty")}
            </Text>
          </Box>
        )}

        {status == "success" && resp.data?.length > 0 && (
          <Grid
            padding="24px"
            templateRows="repeat(1, 1fr)"
            templateColumns={{
              base: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
              xl: "repeat(3, 1fr)",
            }}
            sx={{
              "@media (max-width: 576px)": {
                gridTemplateColumns: "repeat(1, 1fr)",
              },
            }}
            gap={3}
          >
            {resp.data.map((group) => {
              const groupPictureUrl = group.groupPictureKey
                ? s3Client.getUrl(group.groupPictureKey)
                : "https://static.vecteezy.com/system/resources/thumbnails/039/045/548/small_2x/realistic-water-background-animation-free-video.jpg";
              return (
                <GridItem colSpan={1} key={group.id}>
                  <SelectGroupCard
                    title={group.name}
                    src={groupPictureUrl}
                    id={group.id}
                  />
                </GridItem>
              );
            })}
          </Grid>
        )}
      </Box>
    </>
  );
}
