import * as Yup from "yup";

export function getAddTeacherSchema(t) {
  return Yup.object().shape({
    name: Yup.string().required(t("Name is required")),
    surname: Yup.string().required(t("Surname is required")),
    username: Yup.string()
      .required(t("Username is required"))
      .matches(/^\S*$/, t("Username cannot contain spaces")),
    email: Yup.string()
      .email(t("Invalid email"))
      .required(t("Email is required")),
    phone: Yup.string()
      .matches(/^\+994\d{9}$/, t("Phone number must be in format +994XXXXXXXXX"))
      .required(t("Phone number is required")),
    password: Yup.string()
      .required(t("Password is required"))
      .min(11, t("Password must be at least 11 characters long"))
      .matches(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{11,}$/,
        t("Password must contain at least one digit and one symbol")
      ),
  });
}
