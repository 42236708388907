import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import SearchBar from "../student/components/SearchBar";
import PageTitle from "../general/components/PageTitle";
import {
  faCheckCircle,
  faCircleExclamation,
  faClipboardList,
  faFilter,
  faMoneyBillTransfer,
  faPlus,
  faRightFromBracket,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format } from "date-fns";
import {
  deletePendingPayroll,
  getPayrollsForTeacher,
} from "../services/payrollService";
import ConfirmationModal from "./modal/ConfirmationModalT";
import { useTranslation } from "react-i18next";

function PayrollT({ hasLayout = true }) {
  const navigate = useNavigate();

  const { token } = useSelector((x) => x.security) || {};

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const scrollPositionRef = useRef(0);

  const [payrollType, setPayrollType] = useState(null);

  const {
    data: resp,
    status,
    refetch: refetchPayrolls,
  } = useQuery(
    ["teacher-payrolls", token, skip, take, payrollType],
    () => getPayrollsForTeacher(token, skip, take, payrollType),
    { keepPreviousData: take !== null }
  );

  const queryClient = useQueryClient();

  const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(
    (id) => deletePendingPayroll(id, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-payrolls"],
        });
      },
    }
  );

  const handlePayrollTypeChange = (type) => {
    setPayrollType(type);
    const params = new URLSearchParams(window.location.search);

    if (type == null) {
      params.delete("type");
    } else {
      params.set("type", type);
    }

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  };

  const handleDeleteConfirm = (id) => {
    if (id) {
      deleteMutate(id);
    }
  };

  const handleLoadMore = () => {
    scrollPositionRef.current = window.scrollY;
    const newTake = take + 6;
    setTake(newTake);
    refetchPayrolls();

    const params = new URLSearchParams(window.location.search);
    params.set("take", newTake);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlTake = params.get("take");
    const urlType = params.get('type')

    if (urlTake) {
      setTake(Number(urlTake));
    }

    if(urlType){
      setPayrollType(Number(urlType))
    }

    refetchPayrolls();
  }, [token, refetchPayrolls]);

  useEffect(() => {
    window.scrollTo(0, scrollPositionRef.current);
  }, [resp]);

  const handleLoadAll = () => {
    scrollPositionRef.current = window.scrollY;
    setTake(null);
    setSkip(0);
    refetchPayrolls();
  };

  const getStatusStyles = (isAccepted) => {
    switch (isAccepted) {
      case null:
        return {
          gridItemBg: "#FFFDF2",
          gridItemHoverBg: "#FFEC7F",
          borderColor: "#FFEC7F",
          buttonBg: "#FFEC7F",
          buttonHoverBg: "#FFDA36",
          icon: faSpinner,
          textColor: "black",
        };
      case false:
        return {
          gridItemBg: "#FEE6E6",
          gridItemHoverBg: "#F00000",
          borderColor: "#F00000",
          buttonBg: "#F00000",
          buttonHoverBg: "#B80000",
          icon: faCircleExclamation,
          textColor: "white",
        };
      case true:
        return {
          gridItemBg: "#EAF5F4",
          gridItemHoverBg: "#319795",
          borderColor: "#319795",
          buttonBg: "#319795",
          buttonHoverBg: "#EAF5F4",
          icon: faCheckCircle,
          textColor: "white",
        };
      default:
        return {};
    }
  };

  const getStatusText = (isAccepted) => {
    switch (isAccepted) {
      case null:
        return `${t("Pending")}`;
      case false:
        return `${t("Rejected")}`;
      case true:
        return `${t("Accepted")}`;
      default:
        return "";
    }
  };

  const activeStyle = {
    backgroundColor: colors.primary,
    color: "white",
  };

  const { t } = useTranslation("global");

  return (
    <Flex>
      {!hasLayout ? null : (
        <Box width="25%" display={["none", "none", "block", "block"]}></Box>
      )}
      <Container
        maxW={["100%", "100%", "75%", "75%"]}
        margin={!hasLayout ? "1rem auto 0" : "0rem auto .5rem "}
      >
        <PageTitle
          status="success"
          title={t("Payroll")}
          backPath={hasLayout ? "/teacher/general" : "/teacher/select-group"}
          icon={faMoneyBillTransfer}
        />
        <HStack justifyContent="space-between">
          <Box
            w="24%"
            sx={{
              "@media (min-width: 1024px)": {
                w: "240px",
              },
              "@media (max-width: 1024px)": {
                w: "210px",
              },
              "@media (max-width: 768px)": {
                w: "210px",
              },
              "@media (max-width: 425px)": {
                w: "210px",
              },
              "@media (max-width: 375px)": {
                w: "170px",
              },
            }}
          ></Box>
          <HStack gap="20px">
            <Button
              onClick={() =>
                navigate(
                  `/teacher${hasLayout ? "/group" : ""}/payroll/add-payroll`
                )
              }
              gap="16px"
              color={"white"}
              colorScheme="teal"
              border="1px solid "
              w="100%"
              h={{ base: "30px", sm: "40px", md: "40px" }}
              p={{ base: "0 12px", sm: "12px 32px", md: "12px 32px" }}
              fontSize={["12px", " 16px"]}
            >
              <FontAwesomeIcon icon={faPlus} /> <Text>{t("Add")}</Text>
            </Button>
            <Menu>
              <MenuButton bg="white" as={Button}>
                <FontAwesomeIcon color="teal" icon={faFilter} />
              </MenuButton>
              <MenuList padding={"10px"}>
                <MenuItem
                  style={payrollType === null ? activeStyle : {}}
                  _hover={{ color: colors.primary }}
                  onClick={() => handlePayrollTypeChange(null)}
                >
                  {t("All")}
                </MenuItem>
                <MenuItem
                  style={payrollType === 1 ? activeStyle : {}}
                  _hover={{ color: colors.primary }}
                  onClick={() => handlePayrollTypeChange(1)}
                >
                  {t("Pending")}
                </MenuItem>
                <MenuItem
                  style={payrollType === 0 ? activeStyle : {}}
                  _hover={{ color: colors.primary }}
                  onClick={() => handlePayrollTypeChange(0)}
                >
                  {t("Accepted")}
                </MenuItem>
                <MenuItem
                  style={payrollType === 2 ? activeStyle : {}}
                  _hover={{ color: colors.primary }}
                  onClick={() => handlePayrollTypeChange(2)}
                >
                  {t("Rejected")}
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>

        <Flex flexDir="column" gap="24px">
          {status === "loading" && (
            <Grid
              m="32px 0"
              templateRows="repeat(1, 1fr)"
              templateColumns={[
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
                "repeat(3, 1fr)",
              ]}
              gap={6}
            >
              {Array.from({ length: 10 })?.map((_, index) => (
                <Skeleton key={index}>
                  <GridItem
                    padding={[
                      "20px 20px",
                      "12px 12px",
                      "12px 8px",
                      "12px 12px",
                      "20px 12px",
                      "30px 24px",
                    ]}
                    // w="330px"
                    h="388px"
                    transition=" 0.3s"
                    cursor="default"
                  ></GridItem>
                </Skeleton>
              ))}
            </Grid>
          )}

          {status === "success" && (
            <Grid
              margin={!hasLayout ? "20px 8px" : "32px 0"}
              // templateColumns="repeat(3, 1fr)"
              templateRows="repeat(1, 1fr)"
              templateColumns={[
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
                "repeat(3, 1fr)",
              ]}
              gap={6}
            >
              {resp?.data?.map((x, index) => {
                const statusStyles = getStatusStyles(x.isAccepted);
                return (
                  <GridItem
                    onClick={() =>
                      navigate(
                        `/teacher${hasLayout ? "/group" : ""}/payroll/${x.id}`,
                        { state: { take, payrollType } }
                      )
                    }
                    key={index}
                    padding={[
                      "20px 20px",
                      "12px 12px",
                      "12px 8px",
                      "12px 12px",
                      "20px 12px",
                      "30px 24px",
                    ]}
                    // w="330px"
                    // h="388px"
                    bg={statusStyles.gridItemBg}
                    border={`3px solid ${statusStyles.borderColor}`}
                    transition=" 0.3s"
                    cursor="default"
                    _hover={{
                      backgroundColor: statusStyles.gridItemHoverBg,
                      borderColor: "white",
                      bg: "gray.200",
                      cursor: "pointer",
                    }}
                  >
                    <Text fontWeight="500" fontSize="24px" textAlign="center">
                      {t("Report")}
                    </Text>

                    <HStack m="20px 0" justifyContent="space-between">
                      <Text
                        color="#000000"
                        fontWeight="500"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {t("Period Start Date")}:
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {format(new Date(x.periodStartDate), "dd/MM/yyyy")}
                      </Text>
                    </HStack>
                    <HStack m="20px 0" justifyContent="space-between">
                      <Text
                        color="#000000"
                        fontWeight="500"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {t("Period End Date")}:
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {format(new Date(x.periodEndDate), "dd/MM/yyyy")}
                      </Text>
                    </HStack>

                    <HStack m="20px 0" justifyContent="space-between">
                      <Text
                        color="#000000"
                        fontWeight="500"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {t("Number of hours worked")}:
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {x.hoursWorked}
                      </Text>
                    </HStack>
                    <HStack m="20px 0" justifyContent="space-between">
                      <Text
                        color="#000000"
                        fontWeight="500"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {t("Salary")}:
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {x.salary}
                      </Text>
                    </HStack>
                    <HStack m="20px 0" justifyContent="space-between">
                      <Text
                        color="#000000"
                        fontWeight="500"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {t("Currency")}:
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {x.currency}
                      </Text>
                    </HStack>

                    <Flex alignItems="center" justifyContent="space-between">
                      <HStack
                        border="1px solid transparent"
                        cursor="pointer"
                        // padding="10px 0"
                        h="48px"
                        justifyContent="center"
                        fontWeight="500"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "13px",
                          "15px",
                          "17px",
                        ]}
                        alignItems="center"
                        gap="12px"
                        _hover="none"
                        w={
                          x.isAccepted == null
                            ? "60%"
                            : "100%"
                        }
                        color={statusStyles.textColor}
                        bg={statusStyles.buttonBg}
                      >
                        <FontAwesomeIcon icon={statusStyles.icon} />
                        <Text>{getStatusText(x.isAccepted)}</Text>
                      </HStack>

                      {x.isAccepted == null && (
                        <ConfirmationModal
                          onConfirm={() => {
                            handleDeleteConfirm(x.id);
                          }}
                          isLoading={isDeleting}
                          buttonWidth="36%"
                          fontSize={[
                            "12px",
                            "12px",
                            "12px",
                            "12px",
                            "14px",
                            "16px",
                          ]}
                          buttonH={"47px"}
                        />
                      )}
                    </Flex>
                  </GridItem>
                );
              })}
            </Grid>
          )}
        </Flex>

        <Box margin={!hasLayout ? "8px 8px" : "20px 0"}>
          {resp?.data?.length > 0 &&
            status == "success" &&
            take !== null &&
            resp?.data?.length >= take && (
              <Flex
                minH={resp?.data?.length < 2 ? "332px" : undefined}
                maxH={resp?.data?.length >= 2 ? "332px" : undefined}
                alignItems="end"
                justifyContent="center"
                gap="8px"
              >
                {/* <Flex
                  mt="24px"
                  justifyContent="center"
                  alignItems="center"
                  gap="24px"
                >
                  <Button
                    onClick={handleLoadMore}
                    bg="white"
                    color="#319795"
                    w="160px"
                    h="40px"
                    border={`1px solid ${colors.primary}`}
                  >
                    {t("Load More")}
                  </Button>
                  <Button
                    onClick={handleLoadAll}
                    bg="white"
                    color="#319795"
                    w="160px"
                    h="40px"
                    border={`1px solid ${colors.primary}`}
                  >
                    {t("Load All")}
                  </Button>
                </Flex> */}
                <Flex
                  mt="12px"
                  justifyContent="center"
                  alignItems="center"
                  gap="24px"
                  sx={{
                    "@media (max-width: 520px)": {
                      w: "60%",
                    },
                    "@media (max-width: 420px)": {
                      w: "80%",
                    },
                  }}
                >
                  <Button
                    onClick={handleLoadMore}
                    bg="white"
                    color="#319795"
                    w="70%"
                    h="40px"
                    border={`1px solid ${colors.primary}`}
                    sx={{
                      "@media (max-width: 520px)": {
                        fontSize: "12px",
                      },
                      "@media (max-width: 420px)": {
                        fontSize: "12px",
                        w: "100%",
                        p: "0 28px",
                      },
                    }}
                  >
                    {t("Load More")}
                  </Button>
                  <Button
                    onClick={handleLoadAll}
                    bg="white"
                    color="#319795"
                    w="70%"
                    h="40px"
                    sx={{
                      "@media (max-width: 520px)": {
                        fontSize: "12px",
                      },
                      "@media (max-width: 420px)": {
                        fontSize: "12px",
                        w: "100%",
                        p: "0 28px",
                      },
                    }}
                    border={`1px solid ${colors.primary}`}
                  >
                    {t("Load All")}
                  </Button>
                </Flex>
              </Flex>
            )}
        </Box>

        {status === "error" && (
          <>
            <Text
              textAlign="center"
              fontWeight="600"
              fontSize="24px"
              color="red"
            >
              {t("errMessages.message1")}
            </Text>
            <Text
              textAlign="center"
              fontWeight="600"
              fontSize="24px"
              color="red"
            >
              {t("errMessages.message2")}
            </Text>
          </>
        )}

        {status === "success" && resp?.data?.length === 0 && (
          <Text textAlign="center" fontWeight="600" color="red" fontSize="24px">
            {t("No payrolls found.")}
          </Text>
        )}
      </Container>
    </Flex>
  );
}

export default PayrollT;
