import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import { faCamera, faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import { createGroupPromise } from "../services/groupService";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addGroupSchema,
  getAddGroupSchema,
} from "./validations/addGroupSchema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Resizer from "react-image-file-resizer";
import { v4 as uuidv4 } from "uuid";
import { s3Client } from "../helpers/s3Client";

function AddGroup() {
  const { t } = useTranslation("global");
  const validationSchema = getAddGroupSchema(t);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { token } = useSelector((x) => x.security) || {};
  const { mutate, isLoading } = useMutation(
    (data) => createGroupPromise(data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["manager-groups"] });
        navigate("/manager/groups");
      },
    }
  );
  const [imageLoading, setImageLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: null,
      newGroupPictureKey: null,
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      mutate(values);
    },
  });

  const [uploadedImage, setUploadedImage] = useState(null);

  const defaultImage =
    "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png";

  const toast = useToast();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file == null) return;
    const fileExt = file.name.split(".").pop();
    const fileMbSize = file.size / 1024 / 1024;
    if (!file.type.match(/image-*/)) return;
    if (fileMbSize > 2) {
      toast({
        position: "top-right",
        title: `${t("Error")}`,
        description: `${t("Image should be less than 2MB!")}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    Resizer.imageFileResizer(
      file,
      file.width,
      file.height,
      fileExt,
      30,
      0,
      (reziedFile) => {
        setImageLoading(true);
        s3Client.uploadFile(`${uuidv4()}_${fileExt}`, reziedFile).then((x) => {
          formik.setFieldValue("newGroupPictureKey", x);
          setImageLoading(false);
        });
      },
      "file"
    );
    setUploadedImage(URL.createObjectURL(file));
  };

  const fileInputRef = useRef(null);

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <Box>
          <PageTitle
            status={"success"}
            title={t("addGroup.group")}
            icon={faClipboardList}
            backPath={"/manager/groups"}
          />
        </Box>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w={["95%", "95%", "90%", "80%", "70%"]}
          margin="8px auto"
          p="20px 0"
          gap="24px"
          bg="#EAF5F4"
          borderRadius="13px"
        >
          <FormControl w="90%">
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Group picture")}
            </FormLabel>
            <Input
              name="groupPicture"
              onChange={handleFileChange}
              bg="white"
              borderRadius="4px"
              p="0 12px"
              accept="image/*"
              type="file"
              id="fileInput"
              ref={fileInputRef}
              style={{ display: "none" }}
            />
          </FormControl>
          <Box
            //  border="1px solid red"
            sx={{
              "@media (max-width: 400px)": {
                width: "200px",
              },
            }}
            position="relative"
          >
            <Image
              accept="image/*"
              id="groupPicture"
              // objectFit="cover"
              objectFit="contain"
              borderRadius="12px"
              h="240px"
              // w="100%"
              w={["250px", "400px", "450px", "510px"]}
              alt="Group photo"
              src={uploadedImage || defaultImage}
            />
            <Center
              w="32px"
              h="32px"
              bg="white"
              bottom="8px"
              right="5px"
              borderRadius="50%"
              position="absolute"
              color="teal"
            >
              <FontAwesomeIcon
                icon={faCamera}
                onClick={handleUploadButtonClick}
                style={{ cursor: "pointer" }}
              />
            </Center>
          </Box>

          <FormControl w="90%">
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("addGroup.grName")}
            </FormLabel>
            <Input
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="text"
              placeholder={t("addGroup.grName")}
              _placeholder={{ color: "#666666" }}
            />
            {formik.errors.name && formik.touched.name && (
              <Text p="0" pl="4px" m="0" color="red">
                {formik.errors.name}
              </Text>
            )}
          </FormControl>

          <Button
            bg={colors.primary}
            color="white"
            onClick={formik.handleSubmit}
            w={["150px", "200px"]}
            h="40px"
            isLoading={isLoading}
            isDisabled={!formik.dirty || imageLoading}
            fontWeight="600"
            fontSize="16px"
            _hover={{ bg: "#2a6b68" }}
          >
            {t("actions.add")}
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
}

export default AddGroup;
