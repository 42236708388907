import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faCheckCircle,
  faCircleExclamation,
  faFilter,
  faMoneyBillTransfer,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import { getPayrollsForManagerPromise } from "../services/payrollService";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import ProfilePicture from "../general/components/ProfilePicture";

function PayrollM() {
  const navigate = useNavigate();
  const { token } = useSelector((x) => x.security) || {};
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(8);
  const [payrollType, setPayrollType] = useState(null);
  const {
    data: resp,
    status,
    refetch: refetchPayrolls,
  } = useQuery(["manager-payrolls", token, skip, take, payrollType], () =>
    getPayrollsForManagerPromise(token, skip, take, payrollType)
  );

  const scrollPositionRef = useRef(0);

  const handleLoadMore = () => {
    scrollPositionRef.current = window.scrollY;
    const newTake = take + 6;
    setTake(newTake);
    refetchPayrolls();

    const params = new URLSearchParams(window.location.search);
    params.set("take", newTake);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  };

  const handleLoadAll = () => {
    scrollPositionRef.current = window.scrollY;
    setTake(null);
    setSkip(0);
    refetchPayrolls();
  };

  const handlePayrollTypeChange = (type) => {
    setPayrollType(type);
    const params = new URLSearchParams(window.location.search);

    if (type == null) {
      params.delete("type");
    } else {
      params.set("type", type);
    }

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  };

  useEffect(() => {
    window.scrollTo(0, scrollPositionRef.current);
  }, [resp]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlTake = params.get("take");
    const urlType = params.get("type");

    if (urlTake) {
      setTake(Number(urlTake));
    }

    if (urlType) {
      setPayrollType(Number(urlType));
    }

    refetchPayrolls();
  }, [token, refetchPayrolls]);

  const activeStyle = {
    backgroundColor: colors.primary,
    color: "white",
  };

  const getStatusStyles = (isAccepted) => {
    switch (isAccepted) {
      case null:
        return {
          backgroundColor: "#FFFDF2",
          borderColor: "#FFEC7F",
          buttonBg: "#FFEC7F",
          buttonHoverBg: "#FFE066",
          icon: faSpinner,
          textColor: "black",
        };
      case false:
        return {
          backgroundColor: "#FEE6E6",
          borderColor: "#F00000",
          buttonBg: "#F00000",
          buttonHoverBg: "#DC0000",
          icon: faCircleExclamation,
          textColor: "white",
        };
      case true:
        return {
          backgroundColor: "#EAF5F4",
          borderColor: "#319795",
          buttonBg: "#319795",
          buttonHoverBg: "#2C7A7B",
          icon: faCheckCircle,
          textColor: "white",
        };
      default:
        return {};
    }
  };

  const getStatusText = (isAccepted) => {
    switch (isAccepted) {
      case null:
        return `${t("payroll.pending")}`;
      case false:
        return `${t("payroll.rejected")}`;
      case true:
        return `${t("payroll.accepted")}`;
      default:
        return "";
    }
  };

  const [t] = useTranslation("global");
  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          status={"success"}
          title={t("sidebar.payroll")}
          icon={faMoneyBillTransfer}
          backPath={"/manager/general"}
        />
        <HStack justifyContent="space-between">
          <Box w="24%"></Box>
          <Menu>
            <MenuButton bg="white" as={Button}>
              <FontAwesomeIcon color="teal" icon={faFilter} />
            </MenuButton>
            <MenuList padding={"10px"}>
              <MenuItem
                style={payrollType === null ? activeStyle : {}}
                _hover={{ color: colors.primary }}
                onClick={() => handlePayrollTypeChange(null)}
              >
                {t("All")}
              </MenuItem>
              <MenuItem
                style={payrollType === 1 ? activeStyle : {}}
                _hover={{ color: colors.primary }}
                onClick={() => handlePayrollTypeChange(1)}
              >
                {t("Pending")}
              </MenuItem>
              <MenuItem
                style={payrollType === 0 ? activeStyle : {}}
                _hover={{ color: colors.primary }}
                onClick={() => handlePayrollTypeChange(0)}
              >
                {t("Accepted")}
              </MenuItem>
              <MenuItem
                style={payrollType === 2 ? activeStyle : {}}
                _hover={{ color: colors.primary }}
                onClick={() => handlePayrollTypeChange(2)}
              >
                {t("Rejected")}
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>{" "}
        <Flex flexDir="column" gap="24px">
          {status === "loading" && (
            <Grid
              m="32px 0"
              templateRows="repeat(1, 1fr)"
              templateColumns={[
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
                "repeat(3, 1fr)",
              ]}
              gap={6}
            >
              {Array.from({ length: 3 })?.map((_, index) => (
                <Skeleton h="40vh" key={index}>
                  <Box
                    padding="40px 24px"
                    //  w="330px" h="388px"
                  ></Box>
                </Skeleton>
              ))}
            </Grid>
          )}

          {status === "success" && (
            <Grid
              m="32px 0"
              templateRows="repeat(1, 1fr)"
              templateColumns={[
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
                "repeat(3, 1fr)",
              ]}
              gap={6}
            >
              {resp?.data?.map((x) => {
                const statusStyles = getStatusStyles(x.isAccepted);
                return (
                  <GridItem
                    _hover={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(`/manager/payroll/${x.id}`, {
                        state: { take, payrollType },
                      })
                    }
                    padding={[
                      "20px 20px",
                      "12px 12px",
                      "12px 8px",
                      "12px 12px",
                      "20px 12px",
                      "30px 24px",
                    ]}
                    // w="330px"
                    // h="430px"
                    bg={getStatusStyles(x.isAccepted).backgroundColor}
                    border={`3px solid ${
                      getStatusStyles(x.isAccepted).borderColor
                    }`}
                  >
                    <Text
                      display={"flex"}
                      alignItems={"start"}
                      fontWeight="500"
                      fontSize={["14px", "14px", "16px", "18px", "24px"]}
                      textAlign="start"
                      // minH="70px"
                    >
                      <Flex>
                        <ProfilePicture url={x.profilePictureKey} />
                      </Flex>
                      <Box>
                        {" "}
                        <Text> {`${x.name} ${x.surname} `}</Text>
                        <Text>{`(${x.username})`}</Text>
                      </Box>
                    </Text>

                    <HStack m="20px 0" justifyContent="space-between">
                      <Text
                        color="#000000"
                        fontWeight="500"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {t("table.startDatePeriod")}:
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {format(x.periodStartDate, "dd/MM/yyy")}
                      </Text>
                    </HStack>

                    <HStack m="20px 0" justifyContent="space-between">
                      <Text
                        color="#000000"
                        fontWeight="500"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {t("table.endDatePeriod")}:
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {format(x.periodEndDate, "dd/MM/yyy")}
                      </Text>
                    </HStack>

                    <HStack m="20px 0" justifyContent="space-between">
                      <Text
                        color="#000000"
                        fontWeight="500"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {t("table.workedHours")}:
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {x.hoursWorked}
                      </Text>
                    </HStack>

                    <HStack m="20px 0" justifyContent="space-between">
                      <Text
                        color="#000000"
                        fontWeight="500"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {t("table.salary")}:
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {x.salary}
                      </Text>
                    </HStack>

                    <HStack m="20px 0" justifyContent="space-between">
                      <Text
                        color="#000000"
                        fontWeight="500"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {t("table.currency")}:
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "12px",
                          "14px",
                          "16px",
                        ]}
                      >
                        {x.currency}
                      </Text>
                    </HStack>

                    <Flex alignItems="center" justifyContent="space-between">
                      <HStack
                        border="1px solid transparent"
                        cursor="pointer"
                        // padding="10px 0"
                        h="48px"
                        justifyContent="center"
                        fontWeight="500"
                        fontSize={[
                          "12px",
                          "12px",
                          "12px",
                          "13px",
                          "15px",
                          "17px",
                        ]}
                        alignItems="center"
                        gap="12px"
                        _hover="none"
                        w={"100%"}
                        color={statusStyles.textColor}
                        bg={statusStyles.buttonBg}
                      >
                        <FontAwesomeIcon icon={statusStyles.icon} />
                        <Text>{getStatusText(x.isAccepted)}</Text>
                      </HStack>
                    </Flex>
                  </GridItem>
                );
              })}
            </Grid>
          )}
        </Flex>
        <Box margin={"32px 0 28px"}>
          {resp?.data?.length > 0 &&
            status == "success" &&
            take !== null &&
            resp?.data?.length >= take && (
              <Flex
                minH={resp?.data?.length < 2 ? "332px" : undefined}
                maxH={resp?.data?.length >= 2 ? "332px" : undefined}
                alignItems="end"
                justifyContent="center"
                gap="8px"
              >
                <Flex
                  mt="24px"
                  justifyContent="center"
                  alignItems="center"
                  gap="24px"
                >
                  <Button
                    onClick={handleLoadMore}
                    bg="white"
                    color="#319795"
                    w="70%"
                    h="40px"
                    border={`1px solid ${colors.primary}`}
                    sx={{
                      "@media (max-width: 520px)": {
                        fontSize: "12px",
                      },
                      "@media (max-width: 420px)": {
                        fontSize: "12px",
                        w: "100%",
                        p: "0 28px",
                      },
                    }}
                  >
                    {t("Load More")}
                  </Button>
                  <Button
                    onClick={handleLoadAll}
                    bg="white"
                    color="#319795"
                    w="70%"
                    h="40px"
                    border={`1px solid ${colors.primary}`}
                    sx={{
                      "@media (max-width: 520px)": {
                        fontSize: "12px",
                      },
                      "@media (max-width: 420px)": {
                        fontSize: "12px",
                        w: "100%",
                        p: "0 28px",
                      },
                    }}
                  >
                    {t("Load All")}
                  </Button>
                </Flex>
              </Flex>
            )}
        </Box>
        <Box textAlign="center">
          {status === "error" && (
            <>
              <Text fontWeight="600" fontSize="24px" color="red">
                {t("errMessages.message1")}
              </Text>
              <Text fontWeight="600" fontSize="24px" color="red">
                {t("errMessages.message1")}
              </Text>
            </>
          )}

          {status === "success" && resp?.data?.length === 0 && (
            <Text>{t("No payrolls found.")}</Text>
          )}
        </Box>
      </Container>
    </Flex>
  );
}

export default PayrollM;
