import * as Yup from "yup";

export function getSignInSchema(t) {
  return Yup.object().shape({
    username: Yup.string()
      .min(3, t("Username must be at least 3 characters long"))
      .required(t("Username is required")),
    password: Yup.string()
      .required(t("Password is required")),
  });
}
