import { httpClient } from "../helpers/httpClient";

export const getManagersForTeacherPromise = (token) => {
  return httpClient.get(`manager/my-managers`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getManagerForTeacherPromise = (token, managerId) => {
  return httpClient.get(`manager/my-managers/${managerId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCoManagersPromise = (token) => {
  return httpClient.get(`manager/co-managers`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCoManagerPromise = (token, managerId) => {
  return httpClient.get(`manager/co-managers/${managerId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
