import React from "react";
import PageTitle from "../general/components/PageTitle";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { colors } from "../Constants";
import {
  faClipboardList,
  faSpinner,
  faTableList,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSingleExamForStudent } from "../services/examService";
import { format } from "date-fns";
import { isValid } from "date-fns";
import { useQuery } from "react-query";
import InnerHtml from "../helpers/InnerHtml";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfilePicture from "../general/components/ProfilePicture";
import { useTranslation } from "react-i18next";

function SingleExam() {
  const { t } = useTranslation("global");

  const { selectedGroupId, token } = useSelector((x) => x.security);
  const { id } = useParams();

  const { data: resp, status } = useQuery(["student-single-exam", id], () =>
    getSingleExamForStudent(id, selectedGroupId, token)
  );
  const location = useLocation();
  const formatDate = (dateString, dateFormat) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return `${t("Invalid Date")}`;
    }
    return format(date, dateFormat);
  };

  const getDisplayDate = (item) => {
    const now = new Date();
    const deadline = new Date(item?.deadline);
    const createdTime = new Date(item?.createdTime);
    if (!item?.deadline || isNaN(deadline) || deadline < now) {
      if (isValid(createdTime)) {
        return `${t("Posted")}  ${formatDate(item?.createdTime, "MMM do")}`;
      } else {
        return `${t("Date is invalid")}`;
      }
    }

    return `${t("Due")}  ${formatDate(item?.deadline, "MMM do, HH:mm")}`;
  };

  const navigate = useNavigate();

  const getHumanizedTimerValue = (seconds) => {
    if (seconds == null) return "";
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

    return `${
      numhours
        ? numhours == 1
          ? `${1} ${t("hour")} `
          : `${numhours} ${t("hours")} `
        : ""
    }${
      numminutes == 1
        ? `${1} ${t("minute")} `
        : `${numminutes} ${t("minutes")} `
    }${
      numseconds == 1
        ? `${1} ${t("second")} `
        : `${numseconds} ${t("seconds")} `
    }`;
  };

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          status={status}
          title={`${resp?.data?.name}`}
          icon={faTableList}
          backPath={
            location.state
              ? `/student/exams?take=${location.state.take}`
              : "/student/exams"
          }
        />

        <HStack  mt="8px" alignItems="start" justifyContent="space-between">
          <VStack alignItems="start" gap="16px">
            <Skeleton isLoaded={status == "success"}>
              <Flex
                display={"flex"}
                alignItems={"center"}
                fontSize={["12px", "14px", "14px", "18px", "22px"]}
                fontWeight="600"
              >
                <ProfilePicture url={resp?.data?.teacherProfilePictureKey} />
                <HStack flexWrap="wrap">
                  <Text whiteSpace="nowrap">
                    {`${resp?.data?.teacherName} ${resp?.data?.teacherSurname} | `}
                  </Text>
                  <Text pl="4px" as="span">
                    {`${t("Posted")} - ${format(
                      resp?.data?.createdTime ?? new Date(),
                      "MMM do yyyy"
                    )}`}
                  </Text>
                </HStack>
              </Flex>
            </Skeleton>
          </VStack>
        </HStack>
        <Divider
          mt={["8px", "8px", "10px", "12px"]}
          borderColor="teal"
          borderWidth="2px"
        />
        <Skeleton mb="20px" minH="8vh" isLoaded={status == "success"}>
          <Box m="20px 0" lineHeight="22px">
            <Text
              color="#00000099"
              // w="50%"
            >
              {resp?.data?.description ? (
                <InnerHtml fileKey={resp?.data?.description} />
              ) : (
                <Text
                  fontWeight="600"
                  w="100%"
                  textAlign="center"
                  m="3rem auto 1rem"
                >
                  {t("No description")}
                </Text>
              )}
            </Text>
          </Box>
        </Skeleton>

        <Box
          w={["100%", "100%", "90%", "55%"]}
          bg={colors.lightGreen}
          padding="24px 20px"
          px={["8px", "12", "16px", "20px"]}
          m="2rem 0 2rem"
        >
          <Skeleton isLoaded={status == "success"}>
            <Text
              fontSize={["12px", "14px", "16px", "20px", "24px"]}
              fontWeight="600"
            >
              {t("Exam Details")}
            </Text>
            <Flex
              margin="20px 0"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <VStack alignItems="start" gap="40px">
                {}
                {resp?.data?.isGraded &&
                  resp?.data?.canSubmit &&
                  resp?.data?.hasSubmitted && (
                    <Text fontWeight="600" fontSize={["10px", "12px", "16px"]}>
                      {t("Grade")}
                    </Text>
                  )}
                <Text fontWeight="600" fontSize={["10px", "12px", "16px"]}>
                  {t("Deadline")}
                </Text>
                {resp?.data?.durationSeconds !=null && (
                  <Text fontWeight="600" fontSize={["10px", "12px", "16px"]}>
                    {t("Duration")}
                  </Text>
                )}
                <Text fontWeight="600" fontSize={["10px", "12px", "16px"]}>
                  {t("Graded")}
                </Text>
                <Text fontWeight="600" fontSize={["10px", "12px", "16px"]}>
                  {t("Allowed for submission view")}
                </Text>
                <Text fontWeight="600" fontSize={["10px", "12px", "16px"]}>
                  {t("Grading type")}
                </Text>
                <Text fontWeight="600" fontSize={["10px", "12px", "16px"]}>
                  {t("Questions")}
                </Text>
                {resp?.data?.submissionTime && (
                  <Text fontWeight="600" fontSize={["10px", "12px", "16px"]}>
                    {t("Submission time")}
                  </Text>
                )}
              </VStack>
              <VStack alignItems="end" gap="40px">
                {resp?.data?.isGraded &&
                  resp?.data?.canSubmit &&
                  resp?.data?.hasSubmitted &&
                  (resp?.data?.grade == null ? (
                    <Text
                      fontSize={["10px", "12px", "16px"]}
                      fontWeight="700"
                      color="#fcbe03"
                    >
                      {t("Grade is pending")}{" "}
                      <FontAwesomeIcon icon={faSpinner} />
                    </Text>
                  ) : (
                    <Text p="4px" fontSize={["10px", "12px", "16px"]} as="span">
                      {resp?.data?.grade}
                      {" / 100%"}
                    </Text>
                  ))}
                <Text fontWeight="700" fontSize={["10px", "12px", "16px"]}>
                  {resp?.data?.deadline
                    ? formatDate(resp?.data?.deadline, "HH:mm, MMM do, yyy")
                    : `${t("No deadline")}`}
                </Text>
                {resp?.data?.durationSeconds !=null &&(
                  <Text fontWeight="700" fontSize={["10px", "12px", "16px"]}>
                    {resp?.data?.durationSeconds != null
                      ? getHumanizedTimerValue(resp?.data?.durationSeconds)
                      : `${t("No time limit")}`}
                  </Text>
                )}
                <Text fontWeight="700" fontSize={["10px", "12px", "16px"]}>
                  {resp?.data?.isGraded ? `${t("Yes")}` : `${t("No")}`}
                </Text>
                <Text fontWeight="700" fontSize={["10px", "12px", "16px"]}>
                  {resp?.data?.isAllowedForSubmissionView
                    ? `${t("Yes")}`
                    : `${t("No")}`}
                </Text>
                <Text fontWeight="700" fontSize={["10px", "12px", "16px"]}>
                  {resp?.data?.gradingType
                    ? `${t("Maunal")}`
                    : `${t("Automatic")}`}
                </Text>
                <Text fontWeight="500" fontSize={["10px", "12px", "16px"]}>
                  {resp?.data?.questionCount}
                </Text>
                {resp?.data?.submissionTime && (
                  <Text fontWeight="700" fontSize={["10px", "12px", "16px"]}>
                    {formatDate(
                      resp?.data?.submissionTime,
                      "HH:mm, MMM do, yyy"
                    )}{" "}
                    {resp?.data?.deadline != null &&
                      new Date(resp?.data?.submissionTime) >
                        new Date(resp?.data?.deadline) &&
                      `(${t("Late")})`}
                  </Text>
                )}
              </VStack>
            </Flex>
          </Skeleton>
        </Box>

        <Skeleton isLoaded={status == "success"}>
          {resp?.data?.canSubmit ? (
            <Box m="4rem 0 2rem">
              {!resp?.data?.hasStarted && !resp?.data?.hasSubmitted && (
                <Button
                  onClick={() =>
                    navigate(`/student/exams/exam-for-submission/${id}`)
                  }
                  bg="white"
                  color="#319795"
                  w="160px"
                  h="40px"
                  border={`1px solid ${colors.primary}`}
                >
                  {t("Start")}
                </Button>
              )}

              {resp?.data?.hasStarted && !resp?.data?.hasSubmitted && (
                <Button
                  onClick={() =>
                    navigate(`/student/exams/exam-for-submission/${id}`)
                  }
                  bg="white"
                  color="#319795"
                  w="160px"
                  h="40px"
                  border={`1px solid ${colors.primary}`}
                >
                  {t("Continue")}
                </Button>
              )}

              {resp?.data?.hasSubmitted &&
                resp?.data?.isAllowedForSubmissionView && (
                  <Button
                    onClick={() =>
                      navigate(`/student/exams/exam-view-submission/${id}`)
                    }
                    bg="white"
                    color="#319795"
                    w="160px"
                    h="40px"
                    border={`1px solid ${colors.primary}`}
                  >
                    {t("View submission")}
                  </Button>
                )}

              {resp?.data?.hasSubmitted &&
                !resp?.data?.isAllowedForSubmissionView && (
                  <Button
                    isDisabled={true}
                    bg="white"
                    color="#319795"
                    w="240px"
                    h="40px"
                    border={`1px solid ${colors.primary}`}
                  >
                    {t("You've submitted the exam")}
                  </Button>
                )}
            </Box>
          ) : (
            <Text color="#fcbe03" m="20px 0 8px" fontWeight="600">
              {"The exam is not available for you."}
            </Text>
          )}
        </Skeleton>
      </Container>
    </Flex>
  );
}

export default SingleExam;
