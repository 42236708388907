import * as Yup from "yup";

export function getAddPayrollSchema(t) {
  return Yup.object().shape({
    periodStartDate: Yup.date().required(t("Start period is required")),
    periodEndDate: Yup.date()
      .required(t("End period is required"))
      .min(
        Yup.ref("periodStartDate"),
        t("End period must be after start period")
      ),
  });
}
