import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { createStudentPaymentPromise } from "../../services/studentPaymentService";
import { useTranslation } from "react-i18next";
import {
  addStudentPaymentSchema,
  getAddStudentPaymentSchema,
} from "../validations/addStudentPaymentSchema";

export default function StudentpaymentModal({ groups, setGroupStudentId }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const { token } = useSelector((x) => x.security) || {};
  const { mutate, isLoading } = useMutation(
    (data) => createStudentPaymentPromise(data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["groups-of-student"],
        });
        setGroupStudentId(formik.values.groupStudentId);
        onClose();
      },
    }
  );
  const { t } = useTranslation("global");
  const validationSchema = getAddStudentPaymentSchema(t);

  const formik = useFormik({
    initialValues: {
      paymentDate: null,
      periodStartDate: null,
      periodEndDate: null,
      amount: null,
      currency: null,
      groupStudentId: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        ...values,
        paymentDate:
          values.paymentDate && new Date(values.paymentDate).toISOString(),
        periodStartDate:
          values.periodStartDate &&
          new Date(values.periodStartDate).toISOString(),
        periodEndDate:
          values.periodEndDate && new Date(values.periodEndDate).toISOString(),
        currency: values.currency.toUpperCase(),
      };
      mutate(data);
    },
    validateOnBlur: true,
  });

  return (
    <>
      <Button
        gap="16px"
        colorScheme="teal"
        color={"white"}
        border="1px solid"
        w={["60px", "70px", "80px", "110px", "160px"]}
        h={["24px", "28px", "30px", "40px"]}
        fontSize={["10px", "10px", "12px", "14px", "16px"]}
        onClick={onOpen}
      >
        <FontAwesomeIcon icon={faPlus} />
        <Text> {t("actions.add")}</Text>
      </Button>

      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p="28px 8px 8px 8px">
          <ModalCloseButton />
          <ModalBody pb={5}>
            <VStack gap="16px">
              <FormControl
                isInvalid={
                  formik.errors.groupStudentId && formik.touched.groupStudentId
                }
              >
                <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                  {t("sidebar.groups.group")}
                </FormLabel>
                <Select
                  onChange={formik.handleChange}
                  value={formik.values.groupStudentId}
                  onBlur={formik.handleBlur}
                  name="groupStudentId"
                  bg="white"
                >
                  <option defaultChecked disabled value={0}>
                    {t("table.selectGr")}
                  </option>
                  {groups?.map((x) => (
                    <option value={x.groupStudentId}>{x.groupName}</option>
                  ))}
                </Select>
                {formik.errors.groupStudentId &&
                  formik.touched.groupStudentId && (
                    <Text p="0" pl="4px" m="0" color="red">
                      {formik.errors.groupStudentId}
                    </Text>
                  )}
              </FormControl>
              <FormControl>
                <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                  {t("table.amountOfPayment")}
                </FormLabel>
                <Input
                  onChange={formik.handleChange}
                  value={formik.values.amount}
                  name="amount"
                  type="number"
                  placeholder={t("table.amountOfPayment")}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.amount && formik.touched.amount && (
                  <Text p="0" pl="4px" m="0" color="red">
                    {formik.errors.amount}
                  </Text>
                )}
              </FormControl>
              <FormControl>
                <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                  {t("table.currency")}
                </FormLabel>
                <Input
                  onChange={formik.handleChange}
                  value={formik.values.currency}
                  name="currency"
                  placeholder={t("Currency")}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.currency && formik.touched.currency && (
                  <Text p="0" pl="4px" m="0" color="red">
                    {formik.errors.currency}
                  </Text>
                )}
              </FormControl>
              <FormControl>
                <FormLabel
                  fontSize="16px"
                  color="gray"
                  fontWeight="400"
                  m="8px"
                >
                  {t("table.paymentDate")}
                </FormLabel>
                <Input
                  name="paymentDate"
                  type="date"
                  value={formik.values.paymentDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.paymentDate && formik.touched.paymentDate && (
                  <Text p="0" pl="4px" m="0" color="red">
                    {formik.errors.paymentDate}
                  </Text>
                )}
              </FormControl>
              <FormControl>
                <FormLabel
                  fontSize="16px"
                  color="gray"
                  fontWeight="400"
                  m="8px"
                >
                  {t("table.startDatePeriod")}
                </FormLabel>
                <Input
                  type="date"
                  name="periodStartDate"
                  value={formik.values.periodStartDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.periodStartDate &&
                  formik.touched.periodStartDate && (
                    <Text p="0" pl="4px" m="0" color="red">
                      {formik.errors.periodStartDate}
                    </Text>
                  )}
              </FormControl>
              <FormControl>
                <FormLabel
                  fontSize="16px"
                  color="gray"
                  fontWeight="400"
                  m="8px"
                >
                  {t("table.endDatePeriod")}
                </FormLabel>
                <Input
                  type="date"
                  name="periodEndDate"
                  value={formik.values.periodEndDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.periodEndDate &&
                  formik.touched.periodEndDate && (
                    <Text p="0" pl="4px" m="0" color="red">
                      {formik.errors.periodEndDate}
                    </Text>
                  )}
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter pt="0px" justifyContent="center">
            <Button
              isLoading={isLoading}
              onClick={formik.handleSubmit}
              w="212px"
              colorScheme="teal"
              mr={3}
              mt="20px"
            >
              {t("actions.add")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
