import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Image,
} from "@chakra-ui/react";
import React from "react";
import { colors } from "../../../Constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_STUDENT, ROLE_TEACHER } from "../../../helpers/constants";
import { setSelectedGroup } from "../../../redux/slices/securitySlice";
import { useTranslation } from "react-i18next";

function SelectGroupCard({ title, src, id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { roleName } = useSelector((x) => x.security) || {};

  const handleCardClick = () => {
    let route;
    switch (roleName) {
      case ROLE_STUDENT:
        route = "/student/general";
        break;
      case ROLE_TEACHER:
        route = "/teacher/general";
        break;
      default:
        return;
    }
    dispatch(setSelectedGroup(id));
    navigate(route);
  };

  const [t] = useTranslation("global");

  return (
    <Card margin="12px 0">
      <CardHeader w="100%" p="20px" margin="0 auto">
        <Image
          margin="0 auto"
          objectFit="cover"
          width="100%"
          height={{ base: "100px", sm: "150px", md: "200px", lg: "200px" }}
          src={src}
          borderRadius="lg"
        />
      </CardHeader>
      <CardBody pt="0" paddingBottom={{ base: "4px", sm: "4px", md: "8px" }}>
        <Heading
          fontWeight="500"
          fontSize={{ base: "20px", sm: "24px", md: "24px", lg: "28px" }}
          color={colors.secondary}
          textTransform="uppercase"
        >
          {title}
        </Heading>
      </CardBody>
      <CardFooter
        paddingTop={{ base: "0", sm: "0", md: "8px", lg: "8px" }}
        paddingBottom={{ base: "0", sm: "0", md: "8px", lg: "8px" }}
      >
        <Box mb="1rem">
          <Button
            onClick={handleCardClick}
            margin="0 auto"
            fontSize={{ base: "16px", sm: "18px" }}
            padding="20px"
            fontWeight="600"
            w={{ base: "150px", sm: "180px", md: "200px" }}
            color="white"
            transition=".6s"
            _hover={{
              background: colors.hoverPrimaryColor,
            }}
            bg={colors.primary}
          >
            {t("buttonGo")}
          </Button>
        </Box>
      </CardFooter>
    </Card>
  );
}

export default SelectGroupCard;
