import { Flex, HStack, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { s3Client } from "../../helpers/s3Client";
import { colors } from "../../Constants";
import { faPaperclip, faXmark } from "@fortawesome/free-solid-svg-icons";

export default function ExistingFile({onRemoveFunc, fileKey}) {
  return (
    <Flex
      p={"10px"}
      w="220px"
      height={"max-content"}
      bg={"white"}
      gap="12px"
      borderRadius={"12px"}
      direction={"column"}
      border="2px solid #D9D9D975"
      _hover={{ cursor: "pointer" }}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <HStack gap="12px">
          <FontAwesomeIcon
            color={colors.gray}
            fontSize="18px"
            icon={faPaperclip}
          />
          <Text
            whiteSpace={"nowrap"}
            maxW={"130px"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            fontSize={["12px", "12px", "16px"]}
            onClick={() => window.open(s3Client.getUrl(fileKey, "_blank"))}
            _hover={{ color: "purple", textDecoration: "underline" }}
          >
          {fileKey.split(/_(.*)/s)[1]}
          </Text>
        </HStack>
        <FontAwesomeIcon
          onClick={onRemoveFunc}
          color="red"
          fontSize="20px"
          icon={faXmark}
        />
      </Flex>
    </Flex>
  );
}
