import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Skeleton,
  Stack,
  Switch,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Radio, RadioGroup } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addStudentToGroupPromise,
  getGroupsForManagerPromise,
} from "../../services/groupService";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { colors } from "../../Constants";
import { useTranslation } from "react-i18next";

export default function AddStudentToGroupModal({ studentId, currentGroups }) {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { token, institutionId } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(
    ["manager-groups", token, institutionId],
    () => getGroupsForManagerPromise(institutionId, token),
    { enabled: isOpen }
  );
  const formik = useFormik({
    initialValues: {
      groupId: null,
      studentId: studentId,
      treatAsNewJoiner: true,
    },
    onSubmit: (values) => {
      mutate(values);
    },
    enableReinitialize: true,
  });
  const { mutate, isLoading } = useMutation(
    (data) =>
      addStudentToGroupPromise(
        data.groupId,
        { studentId: data.studentId, treatAsNewJoiner: data.treatAsNewJoiner },
        token
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["single-student"],
        });
        onClose();
      },
    }
  );

  const [t] = useTranslation("global");
  
  return (
    <>
      <FontAwesomeIcon
        onClick={onOpen}
        color="green"
        fontSize={"22px"}
        icon={faPlusCircle}
      />
      <Modal size="sm" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p="28px 8px 8px 8px">
          <ModalCloseButton />
          <ModalBody pb={5}>
            <VStack gap="16px">
              <FormControl>
                <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                {t("sidebar.groups.group")}
                </FormLabel>
                <Skeleton isLoaded={status == "success"}>
                  <Select
                    onChange={formik.handleChange}
                    value={formik.values.groupId}
                    name="groupId"
                    defaultValue={0}
                    bg="white"
                  >
                    <option defaultChecked disabled value={0}>
                   
                    {t("table.selectGr")}
                    </option>
                    {resp?.data
                      ?.filter((x) => !currentGroups?.some((y) => y.id == x.id))
                      .map((x) => (
                        <option value={x.id}>{x.name}</option>
                      ))}
                  </Select>
                </Skeleton>
              </FormControl>
              <FormControl>
                <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                {t("table.newJoiner")}
                </FormLabel>
                <RadioGroup
                  onChange={(x) =>
                    formik.setFieldValue(
                      "treatAsNewJoiner",
                      JSON.parse(x.toLowerCase())
                    )
                  }
                  value={formik.values.treatAsNewJoiner}
                >
                  <Stack direction="row">
                    <Radio value={true}>{t("responses.yes")}</Radio>
                    <Radio value={false}>{t("responses.no")}</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <Button
                bg={colors.primary}
                color="white"
                isLoading={isLoading}
                onClick={formik.handleSubmit}
                w="200px"
                h="40px"
                fontWeight="600"
                fontSize="16px"
                isDisabled={!formik.dirty}
                _hover={{ bg: "#2a6b68" }}
              >
              {t("actions.add")}
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
