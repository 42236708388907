import { httpClient } from "../helpers/httpClient";

//! manager
export const getPayrollsForManagerPromise = (
  token,
  skip,
  take,
  payrollType
) => {
  return httpClient.get("payroll/for-manager", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      skip,
      take,
      payrollType,
    },
  });
};

export const getPayrollForManagerPromise = (id, token) => {
  return httpClient.get(`payroll/for-manager/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updatePayrollPromise = (id, data, token) => {
  return httpClient.put(`payroll/update/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSingleTeacherPayrollsForManager = (
  teacherId,
  token,
  skip,
  take,
  payrollType
) => {
  return httpClient.get(`payroll/for-manager/teacher/${teacherId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      skip,
      take,
      payrollType,
    },
  });
};

//! Teacher
export const getPayrollsForTeacher = (token, skip, take, payrollType) => {
  return httpClient.get("payroll/for-teacher", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      skip,
      take,
      payrollType,
    },
  });
};

export const getPayrollForTeacher = (payrollId, token) => {
  return httpClient.get(`payroll/for-teacher/${payrollId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createPayroll = (data, token) => {
  return httpClient.post(`payroll/create`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//! delete
export const deletePendingPayroll = (payrollId, token) => {
  return httpClient.delete(`payroll/delete/for-teacher/${payrollId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
