import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faCopy,
  faSpinner,
  faTable,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { format } from "date-fns";
import ProfilePicture from "../general/components/ProfilePicture";
import { getAssignmentGradesForStudentPromise } from "../services/assignmentService";
import { getExamGradesForStudentPromise } from "../services/examService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function Grades() {
  const { t } = useTranslation("global");

  const { token, selectedGroupId, name, surname, profilePictureKey } =
    useSelector((x) => x.security);
  const [tabIndex, setTabIndex] = useState(0);

  const { data: respAssignmentGrades, status: statusAssignmentGrades } =
    useQuery(
      ["student-assignment-grades", selectedGroupId, token],
      () => getAssignmentGradesForStudentPromise(token, selectedGroupId),
      { enabled: tabIndex == 0 }
    );

  const { data: respExamGrades, status: statusExamGrades } = useQuery(
    ["student-exam-grades", selectedGroupId, token],
    () => getExamGradesForStudentPromise(token, selectedGroupId),
    { enabled: tabIndex == 1 }
  );

  const studentName = `${name} ${surname}`;

  const assignmentScrollRef = useRef(null);
  const examScrollRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event, ref) => {
      ref.current.scrollLeft += event.deltaY; //dobavaleyem vertical scroll=> peredelali / convertirovali  vertical scroll into horizontal scroll.
      ref.current.scrollLeft += event.deltaX;
      event.preventDefault();
    };

    const assignmentContainer = assignmentScrollRef.current; // access to the actual DOM element that the ref is attached to.
    const examContainer = examScrollRef.current;

    const onWheelAssignment = (event) =>
      handleWheel(event, assignmentScrollRef);
    const onWheelExam = (event) => handleWheel(event, examScrollRef);

    if (assignmentContainer) {
      assignmentContainer.addEventListener("wheel", onWheelAssignment);
    }
    if (examContainer) {
      examContainer.addEventListener("wheel", onWheelExam);
    }

    // Cleanup
    return () => {
      if (assignmentContainer) {
        assignmentContainer.removeEventListener("wheel", onWheelAssignment);
      }
      if (examContainer) {
        examContainer.removeEventListener("wheel", onWheelExam);
      }
    };
  }, []);

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          title={t("Grades")}
          status={"success"}
          icon={faTable}
          backPath={"/student/general"}
        />
        <Tabs variant={"unstyled"} onChange={(i) => setTabIndex(i)}>
          <HStack gap="20px">
            <TabList gap="20px" w="100%">
              <Tab p={"0"}>
                <HStack
                  transition=".3s"
                  mt="3rem"
                  borderRadius="6px"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  gap="12px"
                  // w="180px"
                  p="12px"
                  height="48px"
                  fontWeight="500"
                  fontSize={["10px", "14px", "12px", "14px", "16px", "18px"]}
                  bg={!tabIndex && "#EAF5F4"}
                  _hover={tabIndex && { bg: "#EAF5F4", cursor: "pointer" }}
                  cursor="default"
                >
                  <FontAwesomeIcon icon={faCopy} />
                  <Text>Assignments</Text>
                </HStack>
              </Tab>
              <Tab p="0">
                <HStack
                  transition=".3s"
                  _hover={{ bg: "#EAF5F4", cursor: "pointer" }}
                  mt="3rem"
                  borderRadius="6px"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  gap="12px"
                  bg={tabIndex && "#EAF5F4"}
                  // width="180px"

                  p="12px"
                  height="48px"
                  fontWeight="500"
                  fontSize={["10px", "14px", "12px", "14px", "16px", "18px"]}
                >
                  <FontAwesomeIcon icon={faCopy} />
                  <Text cursor="pointer">Exams</Text>
                </HStack>
              </Tab>
            </TabList>
          </HStack>

          <TabPanels>
            <TabPanel p="0">
              <Flex m="20px 0" justifyContent="space-between">
                <Box
                  alignItems="start"
                  padding="20px 0"
                  h="430px"
                  w={["70%", "50%", "30%", "30%", "25%"]}
                  pr={["4px", "4px", "4px", "20px"]}
                  border="2px solid transparent"
                  borderRightColor={colors.primary}
                >
                  <Skeleton isLoaded={statusAssignmentGrades == "success"}>
                    <HStack
                      h="63px"
                      mt={[
                        "2.9rem",
                        "4.160rem",
                        "4.160rem",
                        "4.160rem",
                        "4.160rem",
                      ]}
                      gap={["0", "0", "0", "6px"]}
                    >
                      <Flex>
                        <ProfilePicture url={profilePictureKey} />
                      </Flex>
                      <Text
                        fontSize={[
                          "12px",
                          "18px",
                          "16px",
                          "16px",
                          "17px",
                          "20px",
                        ]}
                        fontWeight="600"
                        textAlign={"center"}
                      >
                        {studentName}
                      </Text>
                    </HStack>
                  </Skeleton>
                </Box>
                <Flex
                  w={["100%", "100%", "70%", "75%"]}
                  padding="0 8px 0 10px "
                  gap="12px"
                  sx={{
                    "::-webkit-scrollbar-thumb": {
                      borderRadius: "12px",
                      background: colors.primary,
                    },
                    "::-webkit-scrollbar": {
                      // display: "none",
                      height: "2px",
                    },
                    "::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                  }}
                  ref={assignmentScrollRef}
                  overflowX="scroll"
                  overflowY="hidden"
                >
                  {respAssignmentGrades?.data?.assignments?.map((item) => (
                    <Flex
                      flexDir="column"
                      gap="24px"
                      // minW="49%"
                      minW={["100%", "49%", "49%", "32.2%"]}
                    >
                      <Skeleton isLoaded={statusAssignmentGrades == "success"}>
                        <Box
                          sx={{
                            boxShadow:
                              "-8px 7px 6px 0px rgba(169,151,151,0.75)",
                          }}
                          color="white"
                          bg={colors.primary}
                          borderRadius="6px"
                          padding="12px"
                          h={["50px", "63px", "63px", "63px", "63px"]}
                          w="100%"
                          display="flex"
                          alignItems="center"
                        >
                          <Text
                            fontSize={[
                              "12px",
                              "18px",
                              "16px",
                              "16px",
                              "17px",
                              "20px",
                            ]}
                            fontWeight="500"
                          >
                            {item.name}
                          </Text>
                        </Box>
                      </Skeleton>

                      <Skeleton isLoaded={statusAssignmentGrades == "success"}>
                        <Box
                          display="flex"
                          alignItems="center"
                          bg="white"
                          color={"black"}
                          borderRadius="6px"
                          padding={["12px 2px", "12px 2", "12px"]}
                          h={["50px", "63px", "63px", "63px", "63px"]}
                          w="100%"
                          border={`1px solid ${colors.primary}`}
                          fontSize={[
                            "12px",
                            "18px",
                            "16px",
                            "16px",
                            "17px",
                            "20px",
                          ]}
                          fontWeight="500"
                        >
                          {item.submission == null ? (
                            <Text>{t("Not available")}</Text>
                          ) : item.submission.grade == null ? (
                            item.submission.submissionTime == null ? (
                              <Text fontWeight="700">
                                {t("You have not submitted yet")}
                              </Text>
                            ) : (
                              <Text fontWeight="700" color="#fcbe03">
                                {t("Grade is pending")}{" "}
                                <FontAwesomeIcon icon={faSpinner} />
                              </Text>
                            )
                          ) : (
                            `${item.submission.grade}%`
                          )}
                        </Box>
                      </Skeleton>
                    </Flex>
                  ))}
                  <Box m="3rem 0" w="100%" textAlign="center">
                    {statusAssignmentGrades === "error" && (
                      <>
                        <Text fontWeight="600" fontSize="24px" color="red">
                          {t("errMessages.message1")}
                        </Text>
                        <Text fontWeight="600" fontSize="24px" color="red">
                          {t("errMessages.message2")}
                        </Text>
                      </>
                    )}

                    {statusAssignmentGrades === "success" &&
                      respAssignmentGrades?.data?.assignments?.length === 0 && (
                        <Text
                          textAlign="center"
                          fontWeight="600"
                          color="gray"
                          fontSize="24px"
                        >
                          {t("No records.")}
                        </Text>
                      )}
                  </Box>
                </Flex>
              </Flex>
            </TabPanel>

            <TabPanel p="0">
              <Flex m="20px 0" justifyContent="space-between">
                <Box
                  alignItems="start"
                  padding="20px 0"
                  h="430px"
                  // w="25%"
                  w={["70%", "50%", "30%", "30%", "25%"]}
                  pr={["4px", "4px", "4px", "20px"]}
                  border="2px solid transparent"
                  borderRightColor={colors.primary}
                >
                  <Skeleton isLoaded={statusExamGrades == "success"}>
                    <HStack
                      h="63px"
                      mt={[
                        "2.9rem",
                        "4.160rem",
                        "4.160rem",
                        "4.160rem",
                        "4.160rem",
                      ]}
                      gap={["0", "0", "0", "6px"]}
                    >
                      <Flex>
                        <ProfilePicture url={profilePictureKey} />
                      </Flex>
                      <Text
                        fontSize={[
                          "12px",
                          "18px",
                          "16px",
                          "16px",
                          "17px",
                          "20px",
                        ]}
                        fontWeight="600"
                        textAlign={"center"}
                      >
                        {studentName}
                      </Text>
                    </HStack>
                  </Skeleton>
                </Box>

                <Flex
                  w={["100%", "100%", "70%", "70%", "75%"]}
                  padding="0 8px 0 10px "
                  gap="12px"
                  sx={{
                    "::-webkit-scrollbar-thumb": {
                      borderRadius: "12px",
                      background: colors.primary,
                    },
                    "::-webkit-scrollbar": {
                      // display: "none",
                      height: "8px",
                    },
                    "::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                  }}
                  ref={examScrollRef}
                  overflowX="scroll"
                  overflowY="hidden"
                >
                  {respExamGrades?.data?.exams?.map((item) => (
                    <Flex
                      flexDir="column"
                      gap="24px"
                      // minW="49%"
                      minW={["100%", "49%", "49%", "32.2%"]}
                    >
                      <Skeleton isLoaded={statusExamGrades == "success"}>
                        <Box
                          sx={{
                            boxShadow:
                              "-8px 7px 6px 0px rgba(169,151,151,0.75)",
                          }}
                          color="white"
                          bg={colors.primary}
                          borderRadius="6px"
                          padding="12px"
                          h={["50px", "63px", "63px", "63px", "63px"]}
                          w="100%"
                          display="flex"
                          alignItems="center"
                        >
                          <Text
                            fontSize={[
                              "12px",
                              "18px",
                              "16px",
                              "16px",
                              "17px",
                              "20px",
                            ]}
                            fontWeight="500"
                          >
                            {item.name}
                          </Text>
                        </Box>
                      </Skeleton>
                      <Skeleton isLoaded={statusExamGrades == "success"}>
                        <Box
                          display="flex"
                          alignItems="center"
                          bg="white"
                          color={"black"}
                          borderRadius="6px"
                          padding={["12px 2px", "12px 2", "12px"]}
                          h={["50px", "63px", "63px", "63px", "63px"]}
                          w="100%"
                          border={`1px solid ${colors.primary}`}
                          fontSize={[
                            "12px",
                            "18px",
                            "16px",
                            "16px",
                            "17px",
                            "20px",
                          ]}
                          fontWeight="500"
                        >
                          {item.submission == null ? (
                            <Text>{t("Not available")}</Text>
                          ) : item.submission.grade == null ? (
                            item.submission.submissionTime == null ? (
                              <Text fontWeight="700">
                                {t("You have not submitted yet")}
                              </Text>
                            ) : (
                              <Text fontWeight="700" color="#fcbe03">
                                {t("Grade is pending")}{" "}
                                <FontAwesomeIcon icon={faSpinner} />
                              </Text>
                            )
                          ) : (
                            `${item.submission.grade}%`
                          )}
                        </Box>
                      </Skeleton>
                    </Flex>
                  ))}
                  <Box m="3rem 0" w="100%" textAlign="center">
                    {statusExamGrades === "error" && (
                      <>
                        <Text fontWeight="600" fontSize="24px" color="red">
                          {"errMessages.message1"}
                        </Text>
                        <Text fontWeight="600" fontSize="24px" color="red">
                          {"errMessages.message2"}
                        </Text>
                      </>
                    )}

                    {statusExamGrades === "success" &&
                      respExamGrades?.data?.exams?.length === 0 && (
                        <Text
                          textAlign="center"
                          fontWeight="600"
                          color="gray"
                          fontSize="24px"
                        >
                          {t("No records.")}
                        </Text>
                      )}
                  </Box>
                </Flex>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Flex>
  );
}

export default Grades;
