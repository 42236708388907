import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { colors } from "../Constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getExamOverviewPromise } from "../services/examService";
import InnerHtml from "../helpers/InnerHtml";
import { useTranslation } from "react-i18next";

function OverviewExam() {
  const { t } = useTranslation("global");
  const { id } = useParams();
  const { token } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(
    ["teacher-exam-overview", token, id],
    () => getExamOverviewPromise(id, token)
  );
  const navigate = useNavigate();

  return (
    <Box bg={colors.lightGreen} minH="100vh">
      <HStack p="12px" justifyContent="space-between">
        <Button
          onClick={() => navigate(`/teacher/exams/${id}`)}
          w={["48%", "25%", "20%", "14%", "10%", "10%"]}
          colorScheme="teal"
        >
          {t("Back to exam")}
        </Button>
      </HStack>
      <VStack
        position="relative"
        alignItems="start"
        gap="20px"
        margin="0 auto"
        p={["2rem 0", "2rem 0", "4rem 0", "4rem 0"]}
        w={["94%", "98%", "80%", "70%", "50%"]}
      >
        <Skeleton w="100%" isLoaded={status == "success"}>
          <Box
            borderRadius="8px"
            border="1px solid transparent"
            borderBottomColor={colors.primary}
            w="100%"
            bg="white"
            padding="12px 18px"
            px={["8px", "12px", "14px", "16px", "18px"]}
          >
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Text
                fontSize={["16px", "20px", "20px", "24px", "32px", "36px"]}
                color={colors.primary}
                fontWeight="600"
                w="60%"
              >
                {resp?.data?.name}
              </Text>
            </Flex>
          </Box>
        </Skeleton>

        {resp?.data?.questions?.map((x, i) => {
          i++;
          if (x.questionType == 2) {
            return (
              <Flex
                borderRadius="12px"
                border={`1px solid ${colors.primary}`}
                w="100%"
                bg="white"
                gap="12px"
                padding="16px 10px"
              >
                <Box width="10px" h="inherit" bg={colors.primary}></Box>
                <FormControl>
                  <Flex mb={"1rem"} justifyContent={"space-between"}>
                    {resp?.data?.isGraded && x.coefficient != null ? (
                      <Text fontWeight={"bold"} color={colors.primary}>
                        {t("Max grade")}: {x.coefficient}
                      </Text>
                    ) : (
                      <></>
                    )}
                  </Flex>
                  <FormLabel
                    fontWeight="400"
                    p="10px 12px"
                    fontSize={["12px", "14px", "16px", "18px"]}
                    bg={colors.lightGreen}
                  >
                    {x.isRequired && <span style={{ color: "red" }}>* </span>}{" "}
                    {`${t("Question")} ${i}: `}
                    <InnerHtml fileKey={x.description} />
                  </FormLabel>
                  <Textarea
                    isDisabled={true}
                    fontWeight={"bold"}
                    color={"green"}
                    value={x.options[0]?.correctAnswerText}
                    border="none"
                    resize={"none"}
                    placeholder={t("Text...")}
                    rows="1"
                  />
                  <Divider borderWidth="1px" />
                </FormControl>
              </Flex>
            );
          }

          if (x.questionType == 1) {
            return (
              <Flex
                borderRadius="12px"
                border={`1px solid ${colors.primary}`}
                w="100%"
                bg="white"
                gap="12px"
                padding="16px 10px"
              >
                <Box width="10px" h="inherit" bg={colors.primary}></Box>
                <FormControl>
                  <Flex mb={"1rem"} justifyContent={"space-between"}>
                    {resp?.data?.isGraded && x.coefficient != null ? (
                      <Text fontWeight={"bold"} color={colors.primary}>
                        {t("Max grade")}: {x.coefficient}
                      </Text>
                    ) : (
                      <></>
                    )}
                  </Flex>
                  <FormLabel
                    fontWeight="400"
                    p="10px 12px"
                    fontSize={["12px", "14px", "16px", "18px"]}
                    bg={colors.lightGreen}
                  >
                    {x.isRequired && <span style={{ color: "red" }}>* </span>}{" "}
                    {`${t("Question")} ${i}: `}
                    <InnerHtml fileKey={x.description} />
                  </FormLabel>
                  <Stack gap="12px" direction="column">
                    {x.options.map((y) => {
                      return (
                        <HStack>
                          <Checkbox isDisabled={true} value={y.id}>
                            {y.description}
                          </Checkbox>
                          {y.isCorrect && (
                            <Text
                              ms={"1rem"}
                              color={"green"}
                              fontWeight={"bold"}
                            >
                              {t("Correct")}
                            </Text>
                          )}
                        </HStack>
                      );
                    })}
                  </Stack>
                </FormControl>
              </Flex>
            );
          }

          if (x.questionType == 0) {
            return (
              <Flex
                borderRadius="12px"
                border={`1px solid ${colors.primary}`}
                w="100%"
                bg="white"
                gap="12px"
                padding="16px 10px"
              >
                <Box width="10px" h="inherit" bg={colors.primary}></Box>
                <FormControl>
                  <Flex mb={"1rem"} justifyContent={"space-between"}>
                    {resp?.data?.isGraded && x.coefficient != null ? (
                      <Text fontWeight={"bold"} color={colors.primary}>
                        {t("Max grade")}: {x.coefficient}
                      </Text>
                    ) : (
                      <></>
                    )}
                  </Flex>
                  <FormLabel
                    fontWeight="400"
                    p="10px 12px"
                    fontSize={["12px", "14px", "16px", "18px"]}
                    bg={colors.lightGreen}
                  >
                    {x.isRequired && <span style={{ color: "red" }}>* </span>}
                    {`${t("Question")} ${i}: `}
                    <InnerHtml fileKey={x.description} />
                  </FormLabel>
                  <Stack gap="12px" direction="column">
                    {x.options.map((y) => {
                      return (
                        <HStack>
                          <Radio isDisabled={true} value={y.id}>
                            {y.description}
                          </Radio>
                          {y.isCorrect && (
                            <Text
                              ms={"1rem"}
                              color={"green"}
                              fontWeight={"bold"}
                            >
                              {t("Correct")}
                            </Text>
                          )}
                        </HStack>
                      );
                    })}
                  </Stack>
                </FormControl>
              </Flex>
            );
          }
        })}
      </VStack>
    </Box>
  );
}

export default OverviewExam;
