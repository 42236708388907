import { Box, Container, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import GeneralHeader from "../general/components/GeneralHeader";
import GeneralCards from "../general/cards/GeneralCards";
import GeneralCalendar from "../general/components/GeneralCalendar";
import GroupEvents from "../student/components/GroupEvents";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { whoAmIPromise } from "../services/securityService";
import { s3Client } from "../helpers/s3Client";
import GeneralCardsT from "./GeneralCardsT";

export default function GeneralT() {
  const { profilePictureKey } = useSelector((x) => x.security) || {};

  const profilePictureUrl = profilePictureKey
    ? s3Client.getUrl(profilePictureKey)
    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmJ_4IkdK5v2qxNiln5-ntq7WQBe03wykLdA&s";

  const { name, surname, roleName } = useSelector((x) => x.security) || {};
  const [isMobile, setIsMobile] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <GeneralHeader lastName={name} />

        <Flex
          justifyContent="space-between"
          padding={{
            base: "16px 0",
            sm: "20px 0",
            md: "24p 0x",
          }}
        >
          <GeneralCardsT />
          {isMobile && (
            <GeneralCalendar
              src={profilePictureUrl}
              lastName={surname}
              fName={name}
              position={roleName}
            />
          )}
        </Flex>
        <GroupEvents />
      </Container>
    </Flex>
  );
}
