import * as Yup from "yup";

export function getAddStudentPaymentSchema(t) {
  return Yup.object().shape({
    paymentDate: Yup.date().required(t("Payment date is required")),
    periodStartDate: Yup.date().required(t("Start period is required")),
    periodEndDate: Yup.date()
      .required(t("End period is required"))
      .min(
        Yup.ref("periodStartDate"),
        t("End period must be after start period")
      ),
    amount: Yup.number()
      .positive(t("Amount must be greater than zero"))
      .required(t("Amount is required")),
    currency: Yup.string()
      .test(
        "is-valid-currency",
        t("Currency must be one of AZN, EURO, USD"),
        (value) => ["AZN", "EURO", "USD"].includes(value?.toUpperCase())
      )
      .required(t("Currency is required")),
    groupStudentId: Yup.number()
      .min(1, t("Group is required"))
      .required(t("Group is required")),
  });
}
