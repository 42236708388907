import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import SearchBar from "../../student/components/SearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEarthAmericas,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LogOutModal from "../../student/components/LogOutModal";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../redux/slices/languageSlice";
import NotificationModal from "../../student/components/NotificationModal";

function GeneralHeader({ lastName }) {
  const location = useLocation();
  const account = location.pathname.includes("/student")
    ? "student/group/account"
    : location.pathname.includes("/teacher")
    ? "teacher/group/account"
    : "manager/account";

  const navigate = useNavigate();

  const { t, i18n } = useTranslation("global");
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };

  const activeStyle = {
    backgroundColor: colors.primary,
    color: "white",
  };

  return (
    <Flex alignItems="center" justifyContent="space-between" p="12px 0">
      <Box
        sx={{
          "@media (max-width: 500px)": {
            mt: "32px",
          },
          // "@media (max-width: 768px)": {
          //   minW: "45%",
          //   textAlign: "end",
          // },
          "@media (max-width: 768px)": {
            width: "100%",
            mt: "2.5rem",
            textAlign: "center",
          },
          "@media (max-width: 500px)": {
            textAlign: "center",
          },
        }}
      >
        <Text
          fontWeight="bolder"
          mb="4px"
          fontSize={{
            sm: "20px",
            md: "24px",
            lg: "30px",
          }}
        >
          {t("generalPage.greatingHeader")}, {lastName}
        </Text>
        <Text fontWeight="500" color="#00000099" as="p">
          {t("generalPage.undergreatingHeader")}
        </Text>
      </Box>

      <Flex
        gap={{
          base: "10px",
          sm: "12px",
          md: "16px",
        }}
        alignItems="end"
        sx={{
          "@media (max-width: 768px)": {
            position: "absolute",
            top: "10px",
            right: "38px",
          },
        }}
      >
        <NotificationModal
          bgColor={colors.primary}
          color="white"
          hoverColor="white"
          hoverBg="teal"
        />
        <Button
          colorScheme="teal"
          onClick={() => navigate(`/${account}`)}
          p="6px"
          w="50px"
          borderRadius="4px"
          sx={{
            "@media (max-width: 700px)": {
              h: "32px",
              w: "32px",
            },
            "@media (max-width: 500px)": {
              h: "28px",
              w: "28px",
            },
          }}
        >
          <FontAwesomeIcon fontSize="20px" color="white" icon={faUserEdit} />
        </Button>

        <Menu>
          <MenuButton
            colorScheme="teal"
            p="6px"
            w="50px"
            borderRadius="4px"
            as={Button}
            sx={{
              "@media (max-width: 700px)": {
                h: "32px",
                w: "32px",
              },
              "@media (max-width: 500px)": {
                h: "28px",
                w: "28px",
              },
            }}
          >
            <FontAwesomeIcon
              fontSize="20px"
              color="white"
              icon={faEarthAmericas}
            />
          </MenuButton>
          <MenuList padding={"10px"}>
            <MenuItem
              style={language === "en" ? activeStyle : {}}
              onClick={() => handleLanguageChange("en")}
              fontWeight="500"
              _hover={{ color: colors.primary }}
            >
              EN
            </MenuItem>
            <MenuItem
              style={language === "ru" ? activeStyle : {}}
              onClick={() => handleLanguageChange("ru")}
              fontWeight="500"
              _hover={{ color: colors.primary }}
            >
              RU
            </MenuItem>
            <MenuItem
              style={language === "aze" ? activeStyle : {}}
              onClick={() => handleLanguageChange("aze")}
              fontWeight="500"
              _hover={{ color: colors.primary }}
            >
              AZE
            </MenuItem>
          </MenuList>
        </Menu>
        <LogOutModal
          bgColor={colors.primary}
          color="white"
          hoverColor="white"
          hoverBg="teal"
        />
      </Flex>
    </Flex>
  );
}

export default GeneralHeader;
