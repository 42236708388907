import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  List,
  ListItem,
  Skeleton,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import GeneralHeader from "../general/components/GeneralHeader";
import GeneralCards from "../manager/cards/GeneralCards";
import GeneralCalendar from "../general/components/GeneralCalendar";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../Constants";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getUpcomingStudentPaymentsPromise } from "../services/studentPaymentService";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { ROLE_MANAGER, ROLE_STUDENT, ROLE_TEACHER } from "../helpers/constants";
import { whoAmIPromise } from "../services/securityService";
import { s3Client } from "../helpers/s3Client";
import SimpleBar from "simplebar-react";

export default function GeneralM() {
  let { name, surname, roleName, token, profilePictureKey } =
    useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(["upcoming-payments", token], () =>
    getUpcomingStudentPaymentsPromise(token)
  );
  const formatDate = (dateString, dateFormat) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return `${t("Invalid Date")}`;
    }
    return format(date, dateFormat);
  };

  const { t } = useTranslation("global");

  const profilePictureUrl = profilePictureKey
    ? s3Client.getUrl(profilePictureKey)
    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmJ_4IkdK5v2qxNiln5-ntq7WQBe03wykLdA&s";

  console.log(t("generalPage.upcomingPayments"));
  const [isMobile, setIsMobile] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isLessThan610px] = useMediaQuery("(max-width: 610px)");

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <GeneralHeader lastName={name} />

        <Flex
          justifyContent="space-between"
          padding={{
            base: "16px 0",
            sm: "20px 0",
            md: "24p 0x",
          }}
        >
          <GeneralCards />

          {isMobile && (
            <GeneralCalendar
              src={profilePictureUrl}
              lastName={surname}
              fName={name}
              position={roleName}
            />
          )}
        </Flex>

        <HStack
          border="1px solid #EAF5F4"
          borderRadius="6px"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          gap="12px"
          width="280px"
          height="48px"
          fontWeight="500"
          fontSize="18px"
          bg="#EAF5F4"
          my="3rem"
        >
          <FontAwesomeIcon icon={faCopy} />
          <Text>{t("generalPage.upcomingPayments")}</Text>
        </HStack>
        <Box m="28px 0" textAlign="center">
          {status === "error" && (
            <>
              <Text fontWeight="600" fontSize="24px" color="red">
                {t("errMessages.message1")}
              </Text>
              <Text fontWeight="600" fontSize="24px" color="red">
                {t("errMessages.message2")}
              </Text>
            </>
          )}

          {status === "success" && resp?.data?.length === 0 && (
            <Text fontWeight="600" fontSize="24px" color="gray">
              {t("No upcoming payments at the moment.")}
            </Text>
          )}
        </Box>

        {resp?.data?.length !== 0 && (
          <Box mb="28px" w="100%">
            {isLessThan610px ? (
              <SimpleBar
                autoHide={false}
                style={{ maxHeight: "calc(100vh - 100px)" }}
              >
                <Box
                  sx={{
                    "@media (max-width: 610px)": {
                      minW: "710px",
                    },
                  }}
                >
                  {(status == "loading" || resp?.data?.length > 0) && (
                    <HStack
                      m="3rem 0 1rem"
                      padding="14px"
                      bg="#EAF5F4"
                      fontSize="16px"
                      justifyContent="space-between"
                      h="60px"
                      color={colors.primary}
                    >
                      <Text w={`calc(100% / 3 )`} as="i">
                        {t("table.name")}
                      </Text>
                      <Text w={`calc(100% / 3 )`} as="i" textAlign="center">
                        {t("table.groups")}
                      </Text>
                      <Text
                        fontWeight="500"
                        w={`calc(100% / 3 )`}
                        pr="5rem"
                        textAlign="end"
                      >
                        {t("table.date")}
                      </Text>
                    </HStack>
                  )}
                  <List mb="2rem" w="100%">
                    {status == "loading" && (
                      <>
                        <Skeleton>
                          <ListItem
                            height={"45px"}
                            fontSize="16px"
                            padding="0 14px"
                          ></ListItem>
                          <Divider
                            w="100%"
                            mb="16px"
                            borderColor={colors.gray}
                            borderWidth="1px"
                          />
                        </Skeleton>
                        <Skeleton>
                          <ListItem
                            height={"45px"}
                            fontSize="16px"
                            padding="0 14px"
                          ></ListItem>
                          <Divider
                            w="100%"
                            mb="16px"
                            borderColor={colors.gray}
                            borderWidth="1px"
                          />
                        </Skeleton>
                        <Skeleton>
                          <ListItem
                            height={"45px"}
                            fontSize="16px"
                            padding="0 14px"
                          ></ListItem>
                          <Divider
                            w="100%"
                            mb="16px"
                            borderColor={colors.gray}
                            borderWidth="1px"
                          />
                        </Skeleton>
                      </>
                    )}

                    {status == "success" &&
                      resp?.data?.length > 0 &&
                      resp?.data?.map((x) => (
                        <>
                          <ListItem fontSize="16px" padding="0 14px">
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text
                                w={`calc(100% / 3 )`}
                                fontSize="16px"
                                fontWeight="500"
                              >
                                {`${x.name} ${x.surname}`}
                              </Text>
                              <Text
                                textAlign="center"
                                w={`calc(100% / 3 )`}
                                fontSize="16px"
                                fontWeight="500"
                              >
                                {x.groupName}
                              </Text>

                              <Text
                                w={`calc(100% / 3 )`}
                                mb="8px"
                                _hover={{ bg: "white" }}
                                padding="6px"
                                pr="40px"
                                fontWeight="400"
                                textAlign={"end"}
                              >
                                {formatDate(x.nextPaymentDate, "MMM  do, yyy")}
                              </Text>
                            </Flex>
                          </ListItem>
                          <Divider
                            w="100%"
                            mb="16px"
                            borderColor={colors.gray}
                            borderWidth="1px"
                          />
                        </>
                      ))}
                  </List>
                </Box>
              </SimpleBar>
            ) : (
              <Box>
                {(status == "loading" || resp?.data?.length > 0) && (
                  <HStack
                    m="3rem 0 1rem"
                    padding="14px"
                    bg="#EAF5F4"
                    fontSize="16px"
                    justifyContent="space-between"
                    h="60px"
                    color={colors.primary}
                  >
                    <Text w={`calc(100% / 3 )`} as="i">
                      {t("table.name")}
                    </Text>
                    <Text w={`calc(100% / 3 )`} as="i" textAlign="center">
                      {t("table.groups")}
                    </Text>
                    <Text
                      w={`calc(100% / 3 )`}
                      pr="3rem"
                      as="i"
                      textAlign="end"
                    >
                      {t("table.paymentDate")}
                    </Text>
                  </HStack>
                )}
                <List mb="2rem" w="100%">
                  {status == "loading" && (
                    <>
                      <Skeleton>
                        <ListItem
                          height={"45px"}
                          fontSize="16px"
                          padding="0 14px"
                        ></ListItem>
                        <Divider
                          w="100%"
                          mb="16px"
                          borderColor={colors.gray}
                          borderWidth="1px"
                        />
                      </Skeleton>
                      <Skeleton>
                        <ListItem
                          height={"45px"}
                          fontSize="16px"
                          padding="0 14px"
                        ></ListItem>
                        <Divider
                          w="100%"
                          mb="16px"
                          borderColor={colors.gray}
                          borderWidth="1px"
                        />
                      </Skeleton>
                      <Skeleton>
                        <ListItem
                          height={"45px"}
                          fontSize="16px"
                          padding="0 14px"
                        ></ListItem>
                        <Divider
                          w="100%"
                          mb="16px"
                          borderColor={colors.gray}
                          borderWidth="1px"
                        />
                      </Skeleton>
                    </>
                  )}

                  {status == "success" &&
                    resp?.data?.length > 0 &&
                    resp?.data?.map((x) => (
                      <>
                        <ListItem fontSize="16px" padding="0 14px">
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text
                              w={`calc(100% / 3 )`}
                              fontSize="16px"
                              fontWeight="500"
                            >
                              {`${x.name} ${x.surname}`}
                            </Text>
                            <Text
                              textAlign="center"
                              w={`calc(100% / 3 )`}
                              fontSize="16px"
                              fontWeight="500"
                            >
                              {x.groupName}
                            </Text>

                            <Text
                              w={`calc(100% / 3 )`}
                              mb="8px"
                              _hover={{ bg: "white" }}
                              padding="6px"
                              pr="40px"
                              fontWeight="400"
                              textAlign={"end"}
                            >
                              {formatDate(x.nextPaymentDate, "MMM  do, yyy")}
                            </Text>
                          </Flex>
                        </ListItem>
                        <Divider
                          w="100%"
                          mb="16px"
                          borderColor={colors.gray}
                          borderWidth="1px"
                        />
                      </>
                    ))}
                </List>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Flex>
  );
}
