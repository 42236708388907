import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import global_en from "./translations/en/global.json";
import global_ru from "./translations/ru/global.json";
import global_aze from "./translations/aze/global.json";

import i18next from "i18next";
import { I18nextProvider } from "react-i18next";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en", //def language
  resources: {
    en: {
      global: global_en,
    },
    ru: {
      global: global_ru,
    },
    aze: {
      global: global_aze,
    }
  },
});

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);
