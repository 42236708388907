import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Radio,
  RadioGroup,
  Input,
  Button,
  Checkbox,
  Flex,
  Select,
  Switch,
  Text,
  Divider,
} from "@chakra-ui/react";
import { colors } from "../Constants";
import MyJoditEditor from "../helpers/MyJoditEditor";
import useMyJoditEditor from "../hooks/useMyJoditEditor";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { useMutation, useQueryClient } from "react-query";
import { deleteQuestion, updateQuestionPromise } from "../services/examService";
import { useSelector } from "react-redux";
import {
  editQuestionSchema,
  getEditQuestionSchema,
} from "./validations/editQuestionSchema";
import ConfirmationModal from "./modal/ConfirmationModalT";
import { useTranslation } from "react-i18next";

const QuestionEdit = ({
  isGraded,
  id,
  description,
  isRequired,
  questionType,
  coefficient,
  fetchedOptions,
}) => {
  const { t } = useTranslation("global");
  const validationSchema = getEditQuestionSchema(t);

  const queryClient = useQueryClient();
  const { token } = useSelector((x) => x.security) || {};
  const [options, setOptions] = useState(fetchedOptions);
  const [initialOptions, setInitialOptions] = useState(fetchedOptions);
  const ref = useRef(null);
  const {
    content,
    isDirty,
    resetEditor,
    onBlur,
    fileKey,
    isSaving,
    initialize,
  } = useMyJoditEditor();

  useEffect(() => {
    initialize(description);
  }, [description]);

  useEffect(() => {
    setInitialOptions(fetchedOptions);
  }, [fetchedOptions]);

  const { mutate, isLoading } = useMutation(
    (data) => updateQuestionPromise(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-exam-overview"],
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      coefficient: coefficient,
      isRequired: isRequired,
      questionType: questionType,
      deletedOptionIds: [],
      updatedOptions: [],
      newOptions: [],
      validationOptions: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      delete values.validationOptions;
      values.description = fileKey;
      mutate(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue("validationOptions", [
      ...options,
      ...formik.values.updatedOptions,
      ...formik.values.newOptions,
    ]);
  }, [options, formik.values.newOptions, formik.values.updatedOptions]);

  const handleAddOption = () => {
    if (ref.current.value.trim() !== "") {
      formik.setFieldValue("newOptions", [
        ...formik.values.newOptions,
        {
          id: uuidv4(),
          description: ref.current.value,
          isCorrect: false,
          correctAnswerText: null,
        },
      ]);
      ref.current.value = "";
    }
  };

  const handleSelectChange = (e) => {
    let selectedOption = parseInt(e.target.value);
    formik.setFieldValue("questionType", selectedOption);

    formik.setFieldValue(
      "deletedOptionIds",
      options.map((x) => x.id)
    );
    formik.setFieldValue("updatedOptions", []);
    setOptions([]);
    if (selectedOption == 2) {
      formik.setFieldValue("newOptions", [
        {
          id: uuidv4(),
          description: null,
          isCorrect: true,
          correctAnswerText: null,
        },
      ]);
    } else {
      formik.setFieldValue("newOptions", []);
    }
  };

  const handleCorrectTextAnswerChange = (e) => {
    let updatedOption = options[0] ?? formik.values.updatedOptions[0];
    let newOption = formik.values.newOptions[0];

    if (newOption != null) {
      formik.setFieldValue("newOptions", [
        {
          id: newOption.id,
          description: newOption.description,
          isCorrect: newOption.isCorrect,
          correctAnswerText: e.target.value,
        },
      ]);
    }

    if (updatedOption != null) {
      formik.setFieldValue("updatedOptions", [
        {
          id: updatedOption.id,
          description: updatedOption.description,
          isCorrect: updatedOption.isCorrect,
          correctAnswerText: e.target.value,
        },
      ]);
      setOptions([]);
    }
  };

  const handleCorrectMultipleChoiceOptionChange = (id) => {
    let previousCorrectOption = optionsArrayForRender().find(
      (x) => x.isCorrect
    );
    let newOptions = [...formik.values.newOptions];
    let currentOptions = [...options];
    let updatedOptions = [...formik.values.updatedOptions];
    if (previousCorrectOption == null) {
      let newOption = newOptions.find((x) => x.id == id);
      if (newOption != null) {
        newOption.isCorrect = true;
        formik.setFieldValue("newOptions", newOptions);
        return;
      }
      let currentFutureCorrectOption = currentOptions.find((x) => x.id == id);
      let updatedFutureCorrectOption = updatedOptions.find((x) => x.id == id);

      if (updatedFutureCorrectOption != null) {
        updatedFutureCorrectOption.isCorrect = true;
        formik.setFieldValue("updatedOptions", updatedOptions);
        return;
      }

      if (currentFutureCorrectOption != null) {
        currentOptions = currentOptions.filter((x) => x.id != id);
        updatedOptions.push({ ...currentFutureCorrectOption, isCorrect: true });
        formik.setFieldValue("updatedOptions", updatedOptions);
        setOptions(currentOptions);
        return;
      }
    }
    if (previousCorrectOption.id == id) return;

    if (isNaN(previousCorrectOption.id)) {
      let previousNewCorrectOption = newOptions.find(
        (x) => x.id == previousCorrectOption.id
      );
      previousNewCorrectOption.isCorrect = false;
    } else {
      let currentPreviousCorrectOption = currentOptions.find(
        (x) => x.id == previousCorrectOption.id
      );
      let updatedPreviousOption = updatedOptions.find(
        (x) => x.id == previousCorrectOption.id
      );
      if (currentPreviousCorrectOption != null) {
        currentOptions = currentOptions.filter(
          (x) => x.id != previousCorrectOption.id
        );
        updatedOptions.push({
          ...currentPreviousCorrectOption,
          isCorrect: false,
        });
      }
      if (updatedPreviousOption != null) {
        updatedPreviousOption.isCorrect = false;
      }
    }

    if (isNaN(id)) {
      let futureNewCorrectOption = newOptions.find((x) => x.id == id);
      futureNewCorrectOption.isCorrect = true;
    } else {
      let currentFutureCorrectOption = currentOptions.find((x) => x.id == id);
      let updatedFutureCorrectOption = updatedOptions.find((x) => x.id == id);
      if (currentFutureCorrectOption != null) {
        currentOptions = currentOptions.filter((x) => x.id != id);
        updatedOptions.push({ ...currentFutureCorrectOption, isCorrect: true });
      }
      if (updatedFutureCorrectOption != null) {
        updatedFutureCorrectOption.isCorrect = true;
      }
    }

    setOptions(currentOptions);
    formik.setFieldValue("newOptions", newOptions);
    formik.setFieldValue("updatedOptions", updatedOptions);
  };

  const handleCorrectCheckboxOptionChange = (e) => {
    let isChecked = e.target.checked;
    let id = e.target.value;

    if (isNaN(id)) {
      let _newOptions = [...formik.values.newOptions];
      let newOption = _newOptions.find((x) => x.id == id);
      newOption.isCorrect = isChecked;
      formik.setFieldValue("newOptions", _newOptions);
    } else {
      let _currentOptions = [...options];
      let _updatedOptions = [...formik.values.updatedOptions];

      let currentOption = _currentOptions.find((x) => x.id == id);
      let updatedOption = _updatedOptions.find((x) => x.id == id);

      if (currentOption != null) {
        _currentOptions = _currentOptions.filter((x) => x.id != id);
        _updatedOptions.push({ ...currentOption, isCorrect: isChecked });
      }
      if (updatedOption != null) {
        updatedOption.isCorrect = isChecked;
      }
      setOptions(_currentOptions);
      formik.setFieldValue("updatedOptions", _updatedOptions);
    }
  };

  const handleDeleteOption = (id) => {
    if (isNaN(id)) {
      let newOptions = formik.values.newOptions.filter((x) => x.id != id);
      formik.setFieldValue("newOptions", newOptions);
    } else {
      setOptions((prev) => prev.filter((x) => x.id != id));
      let updatedOptions = formik.values.updatedOptions.filter(
        (x) => x.id != id
      );
      formik.setFieldValue("updatedOptions", updatedOptions);
      formik.setFieldValue("deletedOptionIds", [
        ...formik.values.deletedOptionIds,
        id,
      ]);
    }
  };

  const optionsArrayForRender = () => {
    return [
      ...options,
      ...formik.values.updatedOptions,
      ...formik.values.newOptions,
    ].sort((a, b) => a.id - b.id);
  };

  const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(
    () => deleteQuestion(id, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-exam-overview"],
        });
      },
    }
  );

  const handleDeleteConfirm = () => {
    deleteMutate();
  };

  return (
    <Box
      w="100%"
      // border="1px solid red"
    >
      <FormControl
        w="100%"
        // gap="20px"

        justifyContent="space-between"
        display="flex"
        sx={{
          "@media (max-width: 768px)": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box
          width={["96%", "90%", "50%", "50%", "50%"]}
          mt="12px"
          sx={{
            "@media (max-width: 768px)": {
              w: "100%",
              margin: "0 auto",
            },
          }}
        >
          <FormLabel fontWeight="600" pl="9px" fontSize={["16px", "18px"]}>
            {t("Question")}
          </FormLabel>
          <Box
            mb="1rem"
            pb={"20px"}
            border="2px solid"
            padding="8px"
            borderColor={colors.primary}
            borderRadius="12px"
          >
            <MyJoditEditor
              isSaving={isSaving}
              isInline={true}
              onBlur={onBlur}
              content={content}
            />
          </Box>
          {formik.values.questionType != null ? (
            <>
              {formik.values.questionType == 2 ? (
                <Textarea
                  mb="12px"
                  resize={"none"}
                  rows="1"
                  _hover="none"
                  _focus={{
                    boxShadow: "none",
                    outline: "none",
                    border: "1px solid transparent",
                    borderBottomColor: "#BDBCBC",
                  }}
                  border="1px solid transparent"
                  borderRadius="0"
                  borderBottomColor="#BDBCBC"
                  placeholder={t("Enter the correct answer, or leave empty")}
                  value={options[0]?.correctAnswerText}
                  onChange={handleCorrectTextAnswerChange}
                />
              ) : (
                <>
                  {formik.values.questionType == 0 ? (
                    <RadioGroup
                      value={
                        optionsArrayForRender().find((x) => x.isCorrect)?.id
                      }
                      onChange={handleCorrectMultipleChoiceOptionChange}
                    >
                      <VStack alignItems="start">
                        {optionsArrayForRender().map((option, index) => (
                          <Flex
                            minW="100px"
                            justifyContent="space-between"
                            alignItems={"center"}
                          >
                            <Radio key={index} value={option.id}>
                              {option.description}
                            </Radio>
                            <FontAwesomeIcon
                              onClick={() => handleDeleteOption(option.id)}
                              style={{ marginLeft: "20px", color: "red" }}
                              icon={faClose}
                            />
                          </Flex>
                        ))}
                      </VStack>
                    </RadioGroup>
                  ) : (
                    <></>
                  )}

                  {formik.values.questionType == 1 ? (
                    <VStack alignItems="start">
                      {optionsArrayForRender().map((option, index) => (
                        <Flex
                          minW="100px"
                          justifyContent="space-between"
                          alignItems={"center"}
                        >
                          <Checkbox
                            onChange={handleCorrectCheckboxOptionChange}
                            key={index}
                            value={option.id}
                            isChecked={option.isCorrect}
                          >
                            {option.description}
                          </Checkbox>
                          <FontAwesomeIcon
                            onClick={() => handleDeleteOption(option.id)}
                            style={{ marginLeft: "20px", color: "red" }}
                            icon={faClose}
                          />
                        </Flex>
                      ))}
                    </VStack>
                  ) : (
                    <></>
                  )}
                  {formik.errors.validationOptions &&
                    formik.touched.validationOptions && (
                      <Text pt="4px" m="0" color="red">
                        {formik.errors.validationOptions}
                      </Text>
                    )}
                  <Flex margin="20px 0" gap="12px" alignItems="center">
                    <Input ref={ref} placeholder={t("Enter new option...")} />
                    <Button w="140px" onClick={handleAddOption}>
                      {t("Add")}
                    </Button>
                  </Flex>
                </>
              )}
            </>
          ) : (
            <Text
              fontWeight={"700"}
              color={colors.primary}
              fontSize={["16px", "18px", "20px"]}
            >
              {t("Select question type to get started.")}
            </Text>
          )}
        </Box>
        <Box
          w="40%"
          sx={{
            "@media (max-width: 768px)": {
              w: "100%",
            },
          }}
        >
          <Divider
            w="100%"
            mb="16px"
            border="1px dashed "
            borderColor={colors.primary}
            borderWidth="1px"
            display={{ base: "block", sm: "block", md: "none" }}
          />
          <VStack alignItems="start">
            <FormControl w="90%" gap="20px">
              <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                {t("Question type")}
              </FormLabel>
              <Select
                value={formik.values.questionType}
                onChange={handleSelectChange}
              >
                <option value={0}>{t("Multiple choice")}</option>
                <option value={1}>{t("Checkboxes")}</option>
                <option value={2}>{t("Text")}</option>
              </Select>
            </FormControl>

            <FormControl w="90%" gap="20px">
              <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                {t("Coefficient")}
                <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                  {t(
                    "Coefficients are essential for calculating the grade proportionally. If you leave a coefficient blank, that question won't impact the overall grade. If all questions are left without coefficients, the overall grade will be 0, regardless of the answers."
                  )}
                </FormLabel>
              </FormLabel>
              <Input
                name="coefficient"
                onChange={formik.handleChange}
                value={formik.values.coefficient}
                onBlur={formik.handleBlur}
                type="number"
              />
              {formik.errors.coefficient && formik.touched.coefficient && (
                <Text pt="4px" m="0" color="red">
                  {formik.errors.coefficient}
                </Text>
              )}
            </FormControl>

            <FormControl gap="20px">
              <Box pointerEvents="none">
                <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                  {t("Required")}
                </FormLabel>
              </Box>
              <Switch
                name="isRequired"
                isChecked={formik.values.isRequired}
                onChange={formik.handleChange}
              />
            </FormControl>

            <Flex
              flexWrap="wrap"
              width={"100%"}
              rowGap={"10px"}
              // alignItems="center"
              justifyContent={"space-between"}
            >
              <Button
                isDisabled={!formik.dirty && !isDirty}
                mt={"1rem"}
                bg={colors.primary}
                color="white"
                onClick={() => {
                  formik.handleReset();
                  setOptions(initialOptions);
                  resetEditor();
                }}
                w="49%"
                h="40px"
                fontWeight="600"
                fontSize="16px"
                _hover={{ bg: "#2a6b68" }}
              >
                {t("Reset")}
              </Button>
              <Button
                isLoading={isLoading}
                isDisabled={(!formik.dirty && !isDirty) || isSaving}
                mt={"1rem"}
                bg={colors.primary}
                color="white"
                onClick={formik.handleSubmit}
                w="49%"
                h="40px"
                fontWeight="600"
                fontSize="16px"
                _hover={{ bg: "#2a6b68" }}
              >
                {t("Save")}
              </Button>

              <ConfirmationModal
                buttonWidth="50%"
                isLoading={isDeleting}
                onConfirm={handleDeleteConfirm}
              />
            </Flex>
          </VStack>
        </Box>
      </FormControl>
    </Box>
  );
};

export default QuestionEdit;
