import { Progress } from "@chakra-ui/react";
import React from "react";

export default function Loader() {
  return (
    <div style={{ position: "fixed", top: 0, left: 0, zIndex: 1000000, width:'100%' }}>
      <Progress size='xs' colorScheme="teal" isIndeterminate />
    </div>
  );
}
