// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_active__j3dZK {
  color: #319795;
  background-color: white;
  border-radius: 8px;
  display: block;
  border: 1px solid;
}
.header_list__Lqmk9 {
  border-radius: 8px;
  background: #000;
  padding: 12px 0 12px 24px;
}

ul > a > li {
  border-radius: 8px;
  transition: 0.4s !important  ;
  padding: 11px 24px;
  /* border: 1px solid red; */
}

/* @media screen {
  @media (max-width: 1024px) {
    ul > a > li {
      padding: 11px 0;
    }
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    ul > a > li {
      padding: 11px 0;
    }
  }
} */
`, "",{"version":3,"sources":["webpack://./src/assets/styles/header.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uBAAuB;EACvB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;;;;;;;;;;;;GAYG","sourcesContent":[".active {\n  color: #319795;\n  background-color: white;\n  border-radius: 8px;\n  display: block;\n  border: 1px solid;\n}\n.list {\n  border-radius: 8px;\n  background: #000;\n  padding: 12px 0 12px 24px;\n}\n\nul > a > li {\n  border-radius: 8px;\n  transition: 0.4s !important  ;\n  padding: 11px 24px;\n  /* border: 1px solid red; */\n}\n\n/* @media screen {\n  @media (max-width: 1024px) {\n    ul > a > li {\n      padding: 11px 0;\n    }\n  }\n\n  @media (max-width: 1024px) and (min-width: 768px) {\n    ul > a > li {\n      padding: 11px 0;\n    }\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": `header_active__j3dZK`,
	"list": `header_list__Lqmk9`
};
export default ___CSS_LOADER_EXPORT___;
