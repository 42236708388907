import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Toast,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { colors } from "../Constants";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { signInPromise } from "../services/securityService";
import { setSecurityInfo } from "../redux/slices/securitySlice";
import { useDispatch, useSelector } from "react-redux";
import useSmartRedirection from "../hooks/useSmartRedirection";
import { useTranslation } from "react-i18next";
import { getSignInSchema, signInSchema } from "./validations/signInSchema";
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import { setLanguage } from "../redux/slices/languageSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { color } from "framer-motion";
export default function SignIn() {
  const toast = useToast()
  const [show, setShow] = React.useState(false);
  const handleClickPassword = () => setShow(!show);
  const dispatch = useDispatch();
  useSmartRedirection();

  const { t, i18n } = useTranslation("global");
  const validationSchema = getSignInSchema(t);

  const language = useSelector((state) => state.language.language);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };

  const { mutate, isLoading } = useMutation(signInPromise, {
    onSuccess: (resp) => {
      dispatch(setSecurityInfo(resp.data));
      // todo: create hook that handles everything
    },
    onError: (e) => {
      toast({
        position: "top-right",
        title: `${t("Error")}`,
        description: `${t("signIn.signInError")}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  });

  const formik = useFormik({
    initialValues: {
      username: null,
      password: null,
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      mutate(values);
    },
  });

  const activeStyle = {
    backgroundColor: colors.primary,
    color: "white",
  };

  return (
    <>
      <style>{"body { background-color: #319795}"}</style>

      <Menu>
        <MenuButton
          position="absolute"
          top="12px"
          right="20px"
          bg="white"
          p="6px"
          w="40px"
          borderRadius="4px"
          as={Button}
          sx={{
            "@media (max-width: 900px)": {
              width: "30px",
            },
          }}
        >
          <FontAwesomeIcon
            fontSize="20px"
            color="teal"
            icon={faEarthAmericas}
          />
        </MenuButton>
        <MenuList padding={"10px"}>
          <MenuItem
            style={language === "en" ? activeStyle : {}}
            onClick={() => handleLanguageChange("en")}
            fontWeight="500"
            _hover={{ color: colors.primary }}
          >
            EN
          </MenuItem>
          <MenuItem
            style={language === "ru" ? activeStyle : {}}
            onClick={() => handleLanguageChange("ru")}
            fontWeight="500"
            _hover={{ color: colors.primary }}
          >
            RU
          </MenuItem>
          <MenuItem
            style={language === "aze" ? activeStyle : {}}
            onClick={() => handleLanguageChange("aze")}
            fontWeight="500"
            _hover={{ color: colors.primary }}
          >
            AZE
          </MenuItem>
        </MenuList>
      </Menu>
      <Flex
        height="90vh"
        justifyContent="center"
        alignItems="center"
        backgroundColor={colors.primary}
        sx={{
          "@media (max-width: 900px)": {
            height: "100vh",
          },
        }}
      >
        <Box
          margin={'25px'}
          w="768px"
          // h="68.5vh"
          bg="white"
          padding="32px 40px"
          borderRadius="28px"
          sx={{
            "@media (max-width: 576px)": {
              // width: "90%",
              padding: "29px 20px",
            },
          }}
        >
          <Text
            color={colors.primary}
            fontSize="36px"
            fontWeight="700"
            textAlign="center"
            pb="28px"
            sx={{
              "@media (max-width: 900px)": {
                fontSize: "32px",
              },
              "@media (max-width: 576px)": {
                fontSize: "28px",
                pb: "12px",
              },
            }}
          >
            {t("signIn.logIn")}
          </Text>
          <Flex
            flexDir="column"
            gap="20px"
            as="form"
            border="2px solid "
            borderColor={colors.gray}
            borderRadius="16px"
            padding="24px"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                formik.handleSubmit();
              }
            }}
            sx={{
              "@media (max-width: 576px)": {
                gap: "8px",
                padding: "12px",
              },
            }}
          >
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="16px"
                fontWeight="600"
                fontFamily="sans-serif"
                color={colors.primary}
              >
                {t("table.username")}
              </FormLabel>
              <Input
                fontSize="16px"
                type="text"
                _placeholder={{ color: colors.gray, fontSize: "sm" }}
                placeholder={t("table.username")}
                onChange={formik.handleChange}
                value={formik.values.username}
                onBlur={formik.handleBlur}
                name="username"
                size="lg"
                border="1px"
                borderColor={colors.gray}
                sx={{
                  "@media (max-width: 576px)": {
                    h: "40px",
                  },
                }}
              />
              {formik.errors.username && formik.touched.username && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.username}
                </Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="16px"
                fontWeight="600"
                fontFamily="sans-serif"
                color={colors.primary}
              >
                {t("table.password")}
              </FormLabel>

              <InputGroup size="md">
                <Input
                  sx={{
                    "@media (max-width: 576px)": {
                      h: "40px",
                    },
                  }}
                  name={"password"}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  fontSize="16px"
                  ms="4px"
                  _placeholder={{ color: colors.gray, fontSize: "sm" }}
                  placeholder={t("table.password")}
                  size="lg"
                  border="1px solid"
                  borderColor={colors.gray}
                  type={show ? "text" : "password"}
                />
                <InputRightElement
                  marginTop="5px"
                  sx={{
                    "@media (max-width: 576px)": {
                      marginTop: "0px",
                    },
                  }}
                  width="4.5rem"
                >
                  <Button
                    bg={'transparent'}
                    _hover={{ bg: "transparent" }}
                    h="2rem"
                    size="sm"
                    width={'max-content'}
                    sx={{
                      "@media (max-width: 576px)": {
                        fontSize: "14px",
                        h: "2rem",
                        w: "3.5rem",
                      },
                    }}
                    w="4rem"
                    onClick={handleClickPassword}
                  >
                    {show ?
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={`${colors.primary}`}>
                        <path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z" /></svg>
                      :
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={`${colors.primary}`}>
                        <path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 
                        0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" /></svg>

                    }
                  </Button>
                </InputRightElement>
              </InputGroup>
              {formik.errors.password && formik.touched.password && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.password}
                </Text>
              )}
            </FormControl>
            <Flex justifyContent="end" alignItems="center" maxW="100%" mt="0px">
              <Button
                variant="link"
                textDecor="underline"
                color={colors.primary}
                fontWeight="500"
                fontSize="15px"
                lineHeight="12px"
                p={'15px 0'}
              >
                <Link to="/forgot-password">{t("signIn.forgotP")}</Link>
              </Button>
            </Flex>
            <Button
              isLoading={isLoading}
              margin="0 auto"
              textAlign="center"
              fontSize="16px"
              fontWeight="600"
              w="100%"
              h="45"
              color="white"
              transition=".6s"
              _hover={{
                background: colors.hoverPrimaryColor,
              }}
              bg={colors.primary}
              onClick={formik.handleSubmit}
              textTransform="uppercase"
              sx={{
                "@media (max-width: 576px)": {
                  fontSize: "14px",
                  h: "40px",
                  mt: "12px",
                },
              }}
            >
              {t("signIn.signIn")}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}
