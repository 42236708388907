import {
  Box,
  Container,
  Flex,
  IconButton,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../Constants";
import {
  faArrowTrendUp,
  faChalkboardTeacher,
  faClipboardList,
  faCreditCard,
  faHouse,
  faMoneyBillTransfer,
  faPaste,
  faRightFromBracket,
  faTable,
  faTableList,
  faUserCheck,
  faUserGroup,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import syles from "../../assets/styles/header.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  ROLE_MANAGER,
  ROLE_STUDENT,
  ROLE_TEACHER,
} from "../../helpers/constants";
import { useQuery } from "react-query";
import { myGroupsPromise } from "../../services/groupService";
import { useTranslation } from "react-i18next";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useEffect, useRef, useState } from "react";
import Ellipse from "../components/Ellipse";
import GeneralCalendar from "../components/GeneralCalendar";
import { s3Client } from "../../helpers/s3Client";
import { setSelectedGroup } from "../../redux/slices/securitySlice";

export default function SideBar() {
  const location = useLocation();
  const { roleName, selectedGroupId, token, institutionName, currentLayout, isGroupViewOnly } =
    useSelector((x) => x.security) ?? {};

  const fetchEnabled = currentLayout === ROLE_TEACHER || currentLayout === ROLE_STUDENT;
  const { data: resp } = useQuery(
    ["my-groups", token],
    () => myGroupsPromise(token),
    {
      enabled: fetchEnabled,
      staleTime: Infinity,
    }
  );

  let selectedGroupName = null;
  if (fetchEnabled) {
    const selectedGroup = resp?.data?.find(
      (group) => group.id === selectedGroupId
    );
    selectedGroupName = selectedGroup?.name;
  }
  const { t } = useTranslation("global");

  const menuItems = [
    /* //!stundet */
    {
      to: "/student/general",
      text: `${t("sidebar.general")}`,
      icon: faHouse,
      role: ROLE_STUDENT,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/student/assignments",
      text: `${t("sidebar.assignments")}`,
      icon: faClipboardList,
      role: ROLE_STUDENT,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/student/materials",
      text: `${t("sidebar.materials")}`,
      icon: faPaste,
      role: ROLE_STUDENT,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/student/exams",
      text: `${t("sidebar.exams")}`,
      icon: faTableList,
      role: ROLE_STUDENT,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/student/attendance",
      text: `${t("sidebar.attendance")}`,
      icon: faUserCheck,
      role: ROLE_STUDENT,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/student/grades",
      text: `${t("Grades")}`,
      icon: faTable,
      role: ROLE_STUDENT,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/student/statistics",
      text: `${t("sidebar.statistics")}`,
      icon: faArrowTrendUp,
      role: ROLE_STUDENT,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/student/teachers",
      text: `${t("Your teachers")}`,
      icon: faChalkboardTeacher,
      role: ROLE_STUDENT,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/student/group/student-payment",
      text: `${t("My Payments")}`,
      icon: faCreditCard,
      role: ROLE_STUDENT,
      shouldGetDisplayed: () => true,
    },

    /* //!teacher */
    {
      to: "/teacher/general",
      text: `${t("sidebar.general")}`,
      icon: faHouse,
      role: ROLE_TEACHER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/teacher/assignments",
      text: `${t("sidebar.assignments")}`,
      icon: faClipboardList,
      role: ROLE_TEACHER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/teacher/materials",
      text: `${t("sidebar.materials")}`,
      icon: faPaste,
      role: ROLE_TEACHER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/teacher/exams",
      text: `${t("sidebar.exams")}`,
      icon: faTableList,
      role: ROLE_TEACHER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/teacher/attendance",
      text: `${t("sidebar.attendance")}`,
      icon: faUserCheck,
      role: ROLE_TEACHER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/teacher/grades",
      text: `${t("Grades")}`,
      icon: faTable,
      role: ROLE_TEACHER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/teacher/statistics",
      text: `${t("sidebar.statistics")}`,
      icon: faArrowTrendUp,
      role: ROLE_TEACHER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/teacher/group/payroll",
      text: `${t("sidebar.payroll")}`,
      icon: faMoneyBillTransfer,
      role: ROLE_TEACHER,
      shouldGetDisplayed: () => !isGroupViewOnly,
    },
    {
      to: "/teacher/students",
      text: isGroupViewOnly ? `${t("sidebar.students")}` : `${t("Your students")}`,
      icon: faUsers,
      role: ROLE_TEACHER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/teacher/managers",
      text: `${t("Your managers")}`,
      icon: faUsers,
      role: ROLE_TEACHER,
      shouldGetDisplayed: () => !isGroupViewOnly,
    },
    {
      to: "/teacher/co-teachers",
      text: isGroupViewOnly ? `${t("sidebar.teachers.teachers")}` : `${t("Co-teachers")}`,
      icon: faChalkboardTeacher,
      role: ROLE_TEACHER,
      shouldGetDisplayed: () => true,
    },

    /* //!manager */
    {
      to: "/manager/general",
      text: `${t("sidebar.general")}`,
      icon: faHouse,
      role: ROLE_MANAGER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/manager/groups",
      text: `${t("sidebar.groups.groups")}`,
      icon: faClipboardList,
      role: ROLE_MANAGER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/manager/student-payment",
      text: `${t("sidebar.studentPayments")}`,
      icon: faCreditCard,
      role: ROLE_MANAGER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/manager/students",
      text: `${t("sidebar.students")}`,
      icon: faPaste,
      role: ROLE_MANAGER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/manager/teachers",
      text: `${t("sidebar.teachers.teachers")}`,
      icon: faPaste,
      role: ROLE_MANAGER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/manager/co-managers",
      text: `${t("Co-managers")}`,
      icon: faChalkboardTeacher,
      role: ROLE_MANAGER,
      shouldGetDisplayed: () => true,
    },
    {
      to: "/manager/payroll",
      text: `${t("sidebar.payroll")}`,
      icon: faMoneyBillTransfer,
      role: ROLE_MANAGER,
      shouldGetDisplayed: () => true,
    },
  ];
  const filteredMenuItems = menuItems.filter(
    (x) => x.role == currentLayout && x.shouldGetDisplayed()
  );

  const isDisabled = (itemToCheck) => {
    return (
      itemToCheck.to === "/student/statistics" ||
      itemToCheck.to === "/teacher/statistics"
    );
  };

  const backToGroupsLink =
    currentLayout == ROLE_STUDENT
      ? "/student/select-group"
      : ROLE_TEACHER
      ? "/teacher/select-group"
      : "";

  const navigate = useNavigate();
  const dispatch = useDispatch()
  
  const handleBackToGroups = () => {
    dispatch(setSelectedGroup(null))
    navigate(backToGroupsLink);
  };

  const [display, setDisplay] = useState("none");
  const sidebarRef = useRef(null);

  useEffect(() => {
    const updateWindowSize = () => {
      if (window.innerWidth > 768) {
        setDisplay("none");
      }
    };
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setDisplay("none");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("resize", updateWindowSize);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  const toggleMenu = () => {
    setDisplay((prevDisplay) => (prevDisplay === "none" ? "flex" : "none"));
  };

  const { profilePictureKey } = useSelector((x) => x.security) || {};

  const profilePictureUrl = profilePictureKey
    ? s3Client.getUrl(profilePictureKey)
    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmJ_4IkdK5v2qxNiln5-ntq7WQBe03wykLdA&s";

  const { name, surname } = useSelector((x) => x.security) || {};

  const isGeneralPage = location.pathname.includes("/general");

  return (
    <>
      {/* Desktop */}
      <Box
        as="nav"
        pos="fixed"
        top="0"
        left="0"
        zIndex="sticky"
        h="full"
        overflowX="hidden"
        overflowY="auto"
        bg={colors.primary}
        w="24%"
        display={["none", "none", "block", "block"]}
      >
        {/* Header */}
        <Flex
          gap="10px"
          direction="column"
          fontSize="md"
          color="white"
          padding="8px 20px 4px 20px"
        >
          <Text
            textDecoration="underline"
            textUnderlineOffset="4px"
            fontWeight="800"
            fontSize="18px"
            letterSpacing={1}
          >
            {institutionName}
          </Text>
          {currentLayout != ROLE_MANAGER && (
            <Flex gap="14px" alignItems="center">
              <FontAwesomeIcon icon={faUserGroup} />
              <Text letterSpacing="1px" fontWeight="700" fontSize="14px">
                {selectedGroupName}  {isGroupViewOnly && `(${t("viewOnly")})` }
              </Text>
            </Flex>
          )}
        </Flex>

        {/* Menu items */}
        <List
          mt="20px"
          fontSize="md"
          fontWeight="600"
          display="flex"
          flexDir="column"
          gap="10px" // Increased distance among filteredMenuItems
          color="white"
          padding="0 20px"
          sx={{
            "@media (max-width: 1190px)": {
              padding: "0 12px",
            },
            "@media (max-width: 1000px)": {
              padding: "0 8px",
            },
          }}
        >
          {filteredMenuItems.map((item, index) => (
            <NavLink
              key={index}
              to={item.to}
              className={({ isActive }) => (isActive ? syles.active : "")}
              style={{
                pointerEvents: isDisabled(item) ? "none" : "auto",
                cursor: isDisabled(item) ? "not-allowed" : "pointer",
              }}
            >
              <ListItem
                paddingX="16px"
                sx={{
                  "@media (max-width: 1000px)": {
                    px: "8px !important",
                  },
                }}
                style={{
                  cursor: isDisabled(item) ? "not-allowed" : "pointer",
                  opacity: isDisabled(item) ? "0.5" : "1",
                }}
                _hover={!isDisabled(item) ? { opacity: "0.8" } : {}}
              >
                <Flex alignItems="center" justifyContent="space-between">
                  <Text
                    sx={{
                      " @media (max-width: 968px) and (min-width: 768px)": {
                        fontSize: "14px",
                      },
                      "@media (max-width: 580px)": {
                        fontSize: "13px",
                        pr: "4px",
                      },
                      "@media (max-width: 480px)": {
                        fontSize: "16px",
                      },
                    }}
                  >
                    {item.text}
                  </Text>
                  <FontAwesomeIcon icon={item.icon} />
                </Flex>
              </ListItem>
            </NavLink>
          ))}
          <ListItem
            ml={["20px", "20px", "10px", "20px", "20px"]}
            mt="12px"
            mb="20px"
          >
            {currentLayout != ROLE_MANAGER && (
              <Flex
                onClick={handleBackToGroups}
                _hover={{ cursor: "pointer" }}
                fontSize="16px"
                color="white"
                alignItems="center"
                gap="10px"
              >
                <FontAwesomeIcon icon={faRightFromBracket} />
                <Text>{t("sidebar.footer")} </Text>
              </Flex>
            )}
          </ListItem>
        </List>
      </Box>

      {/* Mobile  */}

      <IconButton
        position={"absolute"}
        {...(isGeneralPage ? { top: "8px" } : { top: "20px" })}
        {...(isGeneralPage ? { left: "20px" } : { left: "20px" })}
        aria-label="Open Menu"
        transition={".5s"}
        borderRadius="4px"
        _hover={{ color: "red", borderColor: "red" }}
        bg={"white"}
        border={`2px solid  ${colors.primary} `}
        color={colors.primary}
        mr={6}
        icon={<HamburgerIcon />}
        onClick={() => setDisplay("flex")}
        display={["flex", "flex", "none", "none"]}
        sx={{
          "@media (max-width: 700px)": {
            w: "40px",
          },
          "@media (max-width: 500px)": {
            w: "28px",
          },
        }}
      />
      <Flex
        // w="40vw"
        // w="30%"
        ref={sidebarRef}
        sx={{
          "@media (max-width: 768px)": {
            w: "50%",
          },
          "@media (max-width: 500px)": {
            w: "70%",
          },
          "@media (max-width: 375px)": {
            w: "100%",
          },

          "::-webkit-scrollbar": {
            width: "6px",
          },
          "::-webkit-scrollbar-thumb": {
            minHeight: "30px",
            borderRadius: "12px",
            background: "white",
            border: "1px solid teal",
          },
        }}
        display={display}
        zIndex={10000}
        // h="100vh"
        h="full"
        pos="fixed"
        top="0"
        left="0"
        overflowY="auto"
        bg={colors.primary}
        justifyContent="space-between"
        p="12px"
        pt="4px"
        pl="8px"
      >
        <Box w="100%">
          {/* Header */}
          <Flex
            gap="12px"
            direction="column"
            fontSize="md"
            color="white"
            padding="8px 20px"
          >
            <Text
              textDecoration="underline"
              textUnderlineOffset="4px"
              fontWeight="800"
              fontSize="18px"
              letterSpacing={1}
            >
              {institutionName}
            </Text>
            {currentLayout != ROLE_MANAGER && (
              <Flex gap="14px" alignItems="center">
                <FontAwesomeIcon icon={faUserGroup} />
                <Text letterSpacing="1px" fontWeight="700" fontSize="14px">
                  {selectedGroupName}
                </Text>
              </Flex>
            )}
          </Flex>

          {/* Menu items */}

          <GeneralCalendar
            src={profilePictureUrl}
            lastName={surname}
            fName={name}
            position={roleName}
          />
          <List
            mt="20px"
            w="100%"
            fontSize="md"
            fontWeight="600"
            display="flex"
            flexDir="column"
            gap="10px" // Increased distance among filteredMenuItems
            color="white"
            padding="0 8px"
          >
            {filteredMenuItems.map((item, index) => (
              <NavLink
                key={index}
                to={item.to}
                className={({ isActive }) => (isActive ? syles.active : "")}
                style={{
                  pointerEvents: isDisabled(item) ? "none" : "auto",
                  cursor: isDisabled(item) ? "not-allowed" : "pointer",
                }}
              >
                <ListItem
                  sx={{
                    "@media (max-width: 968px)": {
                      px: "8px !important",
                    },
                  }}
                  style={{
                    cursor: isDisabled(item) ? "not-allowed" : "pointer",
                    opacity: isDisabled(item) ? "0.5" : "1",
                  }}
                  _hover={!isDisabled(item) ? { opacity: "0.8" } : {}}
                  onClick={() => setDisplay("none")}
                >
                  <Flex
                    p="0 12px "
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text>{item.text}</Text>
                    <FontAwesomeIcon icon={item.icon} />
                  </Flex>
                </ListItem>
              </NavLink>
            ))}
            
            <ListItem ml="20px" mt="12px" mb="20px">
            {currentLayout != ROLE_MANAGER && (
              <Flex
                onClick={handleBackToGroups}
                _hover={{ cursor: "pointer" }}
                fontSize="16px"
                color="white"
                alignItems="center"
                gap="10px"
              >
                <FontAwesomeIcon icon={faRightFromBracket} />
                <Text>{t("sidebar.footer")} </Text>
              </Flex>
            )}
            </ListItem>
          </List>
        </Box>
        <IconButton
          mt={2}
          position={"absolute"}
          right={"8px"}
          aria-label="Open Menu"
          backgroundColor={"white"}
          size="sm"
          icon={<CloseIcon />}
          onClick={toggleMenu}
        />
      </Flex>
    </>
  );
}
