import React, { useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { colors } from "../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faPaperclip,
  faPaste,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { format } from "date-fns";
import {
  getSingleMaterialForStudent,
  materailComply,
} from "../services/materialService";
import InnerHtml from "../helpers/InnerHtml";
import ProfilePicture from "../general/components/ProfilePicture";
import ReadOnlyFile from "../general/components/ReadOnlyFile";
import { useTranslation } from "react-i18next";

function SingleMaterail() {
  const { t } = useTranslation("global");
  const [isMarkedAsRead, setIsMarkedAsRead] = useState(false);
  const { selectedGroupId, token } = useSelector((x) => x.security);
  const { id } = useParams();
  const { data: resp, status } = useQuery(["student-single-material", id], () =>
    getSingleMaterialForStudent(id, token)
  );
  const [isLoading, setIsLoading] = useState(false);

  const markAsRead = async () => {
    setIsLoading(true);
    try {
      if (!id || !token) {
        throw new Error("Missing required parameters: id or token");
      }
      await materailComply(id, selectedGroupId, token);
      setIsMarkedAsRead(true);
    } catch (error) {
      console.error("Error message", error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return `${t("Invalid Date")}`;
    }
    return format(date, "MMM do");
  };
  const location = useLocation();

  const complianceTime = resp?.data?.compliances[0]?.complianceTime;
  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          title={resp?.data?.name}
          status={status}
          icon={faPaste}
          backPath={
            location.state
              ? `/student/materials?take=${location.state.take}`
              : "/student/materials"
          }
        />

        <HStack alignItems="center" justifyContent="space-between" mt={'8px'}>
          <Skeleton minW="40%" maxW="80%" isLoaded={status == "success"}>
            <Flex
              display={"flex"}
              alignItems={"center"}
              fontSize={["12px", "14px", "14px", "18px", "22px"]}
              fontWeight="600"
            >
              <ProfilePicture url={resp?.data?.teacherProfilePictureKey} />
              <HStack flexWrap="wrap">
                <Text whiteSpace="nowrap">
                  {`${resp?.data?.teacherName} ${resp?.data?.teacherSurname} | `}
                </Text>
                <Text>
                  {`${t("Posted")} - ${format(
                    resp?.data?.createdTime ?? new Date(),
                    "MMM do yyyy"
                  )}`}
                </Text>
              </HStack>
            </Flex>
          </Skeleton>

          <Skeleton isLoaded={status == "success"}>
            {resp?.data?.canComply && (
              <Button
                fontSize={["10px", "10px", "12px", "14px", "16px"]}
                isLoading={isLoading}
                color={
                  isMarkedAsRead || complianceTime ? "gray.500" : colors.primary
                }
                bg={isMarkedAsRead || complianceTime ? "gray.200" : "white"}
                border={
                  isMarkedAsRead || complianceTime
                    ? "1px solid gray.500"
                    : `1px solid ${colors.primary}`
                }
                w={["70px", "80px", "110px", "160px"]}
                h={["28px", "30px", "40px"]}
                disabled={isMarkedAsRead || complianceTime !== null}
                onClick={markAsRead}
                _hover={{
                  bg: "gray.200",
                  cursor:
                    isMarkedAsRead || complianceTime
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                {isMarkedAsRead || complianceTime !== null
                  ? t("Read")
                  : t("Mark as read")}
              </Button>
            )}
          </Skeleton>
        </HStack>
        <Divider
          mt={["8px", "8px", "10px", "12px"]}
          borderColor="teal"
          borderWidth="2px"
        />
        <Flex
          // alignItems="center"
          gap="20px"
          w="100%"
          flexDir="column"
          m="12px 0 20px 0"
          lineHeight="24px"
        >
          <Skeleton isLoaded={status == "success"}>
            {resp?.data?.description ? (
              <Text color="#00000099">
                <InnerHtml fileKey={resp?.data?.description} />
              </Text>
            ) : (
              <Text
                fontWeight="600"
                w="100%"
                m="2rem auto 1rem"
                textAlign="center"
              >
                {t("No description")}
              </Text>
            )}
          </Skeleton>
          <Flex alignItems="center" flexWrap="wrap" gap="20px">
            {resp?.data?.fileAttachments?.map((x, index) => {
              return <ReadOnlyFile key={index} fileKey={x.key} />;
            })}
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
}

export default SingleMaterail;
