import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const initialState = {
  token: null,
  userId: null,
  roleName: null,
  additionalRoles: null,
  institutionId: null,
  name: null,
  surname: null,
  email: null,
  institutionName: null,
  selectedGroupId: null,
  profilePictureKey: null,
  currentLayout: null,
  isGroupViewOnly: null
};

export const securitySlice = createSlice({
  name: "security",
  initialState,
  reducers: {
    setSecurityInfo: (state, action) => {
      let token = action.payload;
      try {
        let decodedToken = jwtDecode(token);
        state.token = token;
        state.userId = decodedToken.user_id;
        state.roleName = decodedToken.role;
        state.currentLayout = decodedToken.role;
        state.additionalRoles = decodedToken.additional_roles;
        state.institutionId = decodedToken.institution_id;
      } catch (error) {
        state = initialState;
      }
    },
    setWhoAmI: (state, action) => {
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.email = action.payload.email;
      state.institutionName = action.payload.institutionName;
      state.profilePictureKey = action.payload.profilePictureKey
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroupId = action.payload?.id;
      state.isGroupViewOnly = action.payload?.isViewOnly
    },
    setCurrentLayout: (state, action) => {
      state.currentLayout = action.payload;
    },
    resetSecurity: (state) => {
      return initialState;
    },
  },
});

export const { setSecurityInfo, setWhoAmI, resetSecurity, setSelectedGroup, setCurrentLayout } =
  securitySlice.actions;

export default securitySlice.reducer;
