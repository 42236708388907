import { httpClient } from "../helpers/httpClient";

export const getTeachersForGroupPromise = (groupId, token) => {
  return httpClient.get(`teacher/group/${groupId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCoTeachersForGroupPromise = (groupId, token) => {
  return httpClient.get(`teacher/co-teachers/group/${groupId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCoTeacherByIdPromise = (coTeacherId, token) => {
  return httpClient.get(`teacher/co-teachers/${coTeacherId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getTeachersForInstitutionPromise = (institutionId, token) => {
  return httpClient.get(`teacher/institution/${institutionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateTeacherPromise = (id, data, token) => {
  return httpClient.put(`teacher/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getTeacherByIdPromise = (id, token) => {
  return httpClient.get(`teacher/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};


//! delete

export const deleteTeacher = (teacherId, token) => {
  return httpClient.delete(`teacher/delete/${teacherId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};