import * as Yup from "yup";

export function getEditQuestionSchema(t) {
  return Yup.object().shape({
    coefficient: Yup.number()
      .nullable()
      .positive(t("Coefficient must be a positive number")),
    deletedOptionIds: Yup.array(),
    updatedOptions: Yup.array(),
    newOptions: Yup.array(),
    validationOptions: Yup.array()
      .test(
        "required-options",
        t("At least one option is required"),
        function (options) {
          const { questionType } = this.parent;
          if (
            (questionType === 0 || questionType === 1) &&
            (!options || options.length === 0)
          ) {
            return false;
          }
          return true;
        }
      )
      .test("at-least-one-correct", function (options) {
        const { questionType } = this.parent;
        if (questionType === 0) {
          return (
            options?.some((option) => option.isCorrect) ||
            this.createError({
              message: t("No correct option selected !"),
            })
          );
        }
        if (questionType === 1) {
          return (
            options?.some((option) => option.isCorrect) ||
            this.createError({
              message: t("At least one option must be marked as correct !"),
            })
          );
        }

        return true;
      })
      .test("is-unique", t("Options must be unique"), function (options) {
        const optionDescriptions = options?.map((option) => option.description);
        const uniqueDescriptions = new Set(optionDescriptions);

        if (uniqueDescriptions.size !== options.length) {
          return this.createError({
            message: t("Duplicate options are not allowed"),
          });
        }
        return true;
      }),
  });
}
