import { lazy } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ROLE_MANAGER, ROLE_STUDENT, ROLE_TEACHER } from "./helpers/constants";
import ProtectedRoute from "./helpers/ProtectedRoute";
import useWhoAmI from "./hooks/useWhoAmI";
import LoginLink from "./general/LoginLink";
import DefaultRoute from "./helpers/DefaultRoute";
import Loadable from "./helpers/Loadable";

import SignIn from "./general/SignIn"
import ForgotPassword from "./general/ForgotPassword"
import NotFound from  "./general/NotFound"
import Layout from "./general/layouts/Layout"

const SelectGroup = Loadable(lazy(()=> import("./student/SelectGroup")));
const StudentGeneral = Loadable(lazy(()=>import("./student/StudentGeneral")));
const Assignments = Loadable(lazy(()=>import("./student/Assignments")));
const SingleAssignment = Loadable(lazy(()=>import("./student/SingleAssignment")));
const Materials = Loadable(lazy(()=>import("./student/Materials"))) ;
const SingleMaterial = Loadable(lazy(()=>import("./student/SingleMaterial"))) ;
const Exams = Loadable(lazy(()=>import("./student/Exams"))) ;
const SingleExam = Loadable(lazy(()=>import("./student/SingleExam"))) ;
const ExamViewSubmission = Loadable(lazy(()=>import("./student/ExamViewSubmission"))) ;
const Attendance = Loadable(lazy(()=>import("./student/Attendance"))) ;
const Payroll = Loadable(lazy(()=>import("./teacher/PayrollT"))) ;
const SelectGroupT = Loadable(lazy(()=>import("./teacher/SelectGroupT"))) ;
const GeneralT = Loadable(lazy(()=>import("./teacher/GeneralT"))) ;
const AssignmentsT = Loadable(lazy(()=>import("./teacher/Assignments"))) ;
const AddAssignmentT = Loadable(lazy(()=>import("./teacher/AddAssignmentT"))) ;
const AssignmentSubmission = Loadable(lazy(()=>import("./teacher/AssignmentSubmission"))) ;
const MaterialsT = Loadable(lazy(()=>import("./teacher/MaterialsT"))) ;
const AddMaterialT = Loadable(lazy(()=>import("./teacher/AddMaterailT"))) ;
const MaterialSubmission = Loadable(lazy(()=>import("./teacher/MaterialSubmission"))) ;
const ExamsT = Loadable(lazy(()=>import("./teacher/ExamsT"))) ;
const AddExamT = Loadable(lazy(()=>import("./teacher/AddExamT"))) ;
const CreateExam = Loadable(lazy(()=>import("./teacher/CreateExam"))) ;
const ExamSubmissionsT = Loadable(lazy(()=>import( "./teacher/ExamSubmissionsT")));
const AttendanceT = Loadable(lazy(()=>import("./teacher/AttendanceT"))) ;
const AddPayrollT = Loadable(lazy(()=>import("./teacher/AddPayrollT"))) ;
const AccountT = Loadable(lazy(()=>import("./teacher/AccountT"))) ;
const Account = Loadable(lazy(()=>import("./student/Account"))) ;
const GeneralM = Loadable(lazy(()=>import("./manager/GeneralM"))) ;
const Groups = Loadable(lazy(()=>import("./manager/Groups"))) ;
const SingleGroup = Loadable(lazy(()=>import("./manager/SingleGroup"))) ;
const AddGroup = Loadable(lazy(()=>import("./manager/AddGroup"))) ;
const EditGroup = Loadable(lazy(()=>import("./manager/EditGroup"))) ;
const Students = Loadable(lazy(()=>import("./manager/Students"))) ;
const AddStudent = Loadable(lazy(()=>import("./manager/AddStudent"))) ;
const AccountM = Loadable(lazy(()=>import("./manager/AccountM"))) ;
const Teachers = Loadable(lazy(()=>import("./manager/Teachers"))) ;
const AddTeacher = Loadable(lazy(()=>import("./manager/AddTeacher"))) ;
const PayrollM = Loadable(lazy(()=>import("./manager/PayrollM"))) ;
const SinglePayrollM = Loadable(lazy(()=>import("./manager/SinglePayrollM"))) ;
const StudentPayment = Loadable(lazy(()=>import("./manager/StudentPayment"))) ;
const StudentPaymentDetails = Loadable(lazy(()=>import("./manager/StudentPaymentDetails"))) ;
const SingleStudent = Loadable(lazy(()=>import("./manager/SingleStudent"))) ;
const SingleTeacher = Loadable(lazy(()=>import("./manager/SingleTeacher"))) ;
const EditAssignment = Loadable(lazy(()=>import("./teacher/EditAssignment"))) ;
const EditMaterial = Loadable(lazy(()=>import("./teacher/EditMaterial"))) ;
const SinglePayrollT = Loadable(lazy(()=>import("./teacher/SinglePayrollT"))) ;
const EditExam = Loadable(lazy(()=>import("./teacher/EditExam"))) ;
const ExamForSubmission = Loadable(lazy(()=>import( "./student/ExamForSubmission")));
const StudentExamViewSubmission = Loadable(lazy(()=>import( "./teacher/StudentExamViewSubmission")));
const TeachersS = Loadable(lazy(()=>import("./student/TeachersS"))) ;
const SingleTeacherS = Loadable(lazy(()=>import("./student/SingleTeacherS"))) ;
const StudentsT = Loadable(lazy(()=>import("./teacher/StudentsT"))) ;
const SingleStudentT = Loadable(lazy(()=>import("./teacher/SingleStudentT"))) ;
const StudentPaymentDetailsS = Loadable(lazy(()=>import("./student/StudentPaymentDetailsS"))) ;
const SignleTeacherPayrollsM = Loadable(lazy(()=>import("./manager/SignleTeacherPayrollsM"))) ;
const CoTeachers = Loadable(lazy(()=>import("./teacher/CoTeachers"))) ;
const SingleCoTeacher = Loadable(lazy(()=>import("./teacher/SingleCoTeacher"))) ;
const Grades = Loadable(lazy(()=>import("./student/Grades"))) ;
const GradesT = Loadable(lazy(()=>import("./teacher/GradesT"))) ;
const CoManagers = Loadable(lazy(()=>import( "./manager/CoManagers")));
const SingleCoManager = Loadable(lazy(()=>import("./manager/SingleCoManager"))) ;
const ManagersT = Loadable(lazy(()=>import("./teacher/ManagersT"))) ;
const SingleManagerT = Loadable(lazy(()=>import("./teacher/SingleManagerT"))) ;
const OverviewExam = Loadable(lazy(()=>import("./teacher/OverviewExam"))) ;

function App() {
  useWhoAmI();
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route element={<Layout />}>
            {/* //! Student */}
            <Route
              path="/student/general"
              element={
                <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                  <StudentGeneral />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/group/student-payment"
              element={
                <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                  <StudentPaymentDetailsS />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/teachers"
              element={
                <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                  <TeachersS />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/students"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <StudentsT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/co-teachers"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <CoTeachers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/co-teachers/:id"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <SingleCoTeacher />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/students/:id"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <SingleStudentT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/managers/:id"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER]} requireGroupId={true}>
                  <SingleManagerT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/managers"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER]} requireGroupId={true}>
                  <ManagersT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/teachers/:id"
              element={
                <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                  <SingleTeacherS />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/assignments"
              element={
                <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                  <Assignments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/assignments/:id"
              element={
                <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                  <SingleAssignment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/materials"
              element={
                <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                  <Materials />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/materials/:id"
              element={
                <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                  <SingleMaterial />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/exams"
              element={
                <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                  <Exams />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/exams/:id"
              element={
                <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                  <SingleExam />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/attendance"
              element={
                <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                  <Attendance />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/grades"
              element={
                <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                  <Grades />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/group/account"
              element={
                <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                  <Account />
                </ProtectedRoute>
              }
            />
            {/* //! Teacher */}
            <Route
              path="/teacher/general"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <GeneralT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/group/account"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <AccountT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/assignments"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <AssignmentsT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/assignments/add-assignment"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER]} requireGroupId={true} isAccessibleInViewOnlyMode={false}>
                  <AddAssignmentT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/assignments/:id"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <AssignmentSubmission />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/assignments/edit-assignment/:id"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER]} requireGroupId={true} isAccessibleInViewOnlyMode={false}>
                  <EditAssignment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/materials/edit-material/:id"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER]} requireGroupId={true} isAccessibleInViewOnlyMode={false}>
                  <EditMaterial />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/materials"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <MaterialsT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/materials/add-material"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER]} requireGroupId={true} isAccessibleInViewOnlyMode={false}>
                  <AddMaterialT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/materials/:id"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <MaterialSubmission />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/exams"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <ExamsT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/exams/add-exam"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER]} requireGroupId={true} isAccessibleInViewOnlyMode={false}>
                  <AddExamT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/exams/edit-exam/:id"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER]} requireGroupId={true} isAccessibleInViewOnlyMode={false}>
                  <EditExam />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/exams/:id"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <ExamSubmissionsT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/attendance"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <AttendanceT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/grades"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                  <GradesT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/group/payroll"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER]} requireGroupId={true}>
                  <Payroll />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/group/payroll/:id"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER]} requireGroupId={true}>
                  <SinglePayrollT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/group/payroll/add-payroll"
              element={
                <ProtectedRoute roles={[ROLE_TEACHER]} requireGroupId={true}>
                  <AddPayrollT />
                </ProtectedRoute>
              }
            />
            {/* //! Manager */}
            <Route
              path="/manager/general"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <GeneralM />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/account"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <AccountM />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/groups"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <Groups />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/groups/:id"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <SingleGroup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/groups/add-group"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <AddGroup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/groups/edit-group/:id"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <EditGroup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/students"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <Students />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/students/add-student"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <AddStudent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/students/:id"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <SingleStudent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/teachers/:id"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <SingleTeacher />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/teachers"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <Teachers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/teachers/add-teacher"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <AddTeacher />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/co-managers"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <CoManagers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/co-managers/:id"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <SingleCoManager />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/payroll"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <PayrollM />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/payroll/teacher/:id"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <SignleTeacherPayrollsM />
                </ProtectedRoute>
              }
            />
            // problem
            <Route
              path="/manager/payroll/:id"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <SinglePayrollM />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/student-payment"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <StudentPayment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/student-payment/:id"
              element={
                <ProtectedRoute roles={[ROLE_MANAGER]}>
                  <StudentPaymentDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/student/student-payment"
            element={
              <ProtectedRoute roles={[ROLE_STUDENT]}>
                <StudentPaymentDetailsS hasLayout={false} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/teacher/payroll"
            element={
              <ProtectedRoute roles={[ROLE_TEACHER]}>
                <Payroll hasLayout={false} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/teacher/payroll/:id"
            element={
              <ProtectedRoute roles={[ROLE_TEACHER]}>
                <SinglePayrollT hasLayout={false} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/teacher/payroll/add-payroll"
            element={
              <ProtectedRoute roles={[ROLE_TEACHER]}>
                <AddPayrollT hasLayout={false} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/student/account"
            element={
              <ProtectedRoute roles={[ROLE_STUDENT]}>
                <Account hasLayout={false} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/teacher/account"
            element={
              <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]}>
                <AccountT hasLayout={false} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/teacher/select-group"
            element={
              <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]}>
                <SelectGroupT />
              </ProtectedRoute>
            }
          />

          <Route
            path="/teacher/exams/create-exam/:id"
            element={
              <ProtectedRoute roles={[ROLE_TEACHER]} requireGroupId={true} isAccessibleInViewOnlyMode={false}>
                <CreateExam />
              </ProtectedRoute>
            }
          />

          <Route
            path="/teacher/exams/overview/:id"
            element={
              <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                <OverviewExam />
              </ProtectedRoute>
            }
          />

          <Route
            path="/student/select-group"
            element={
              <ProtectedRoute roles={[ROLE_STUDENT]}>
                <SelectGroup />
              </ProtectedRoute>
            }
          />
          <Route
            path="/student/exams/exam-view-submission/:id"
            element={
              <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                <ExamViewSubmission />
              </ProtectedRoute>
            }
          />

          <Route
            path="/teacher/exams/exam-view-submission/:id"
            element={
              <ProtectedRoute roles={[ROLE_TEACHER, ROLE_MANAGER]} requireGroupId={true}>
                <StudentExamViewSubmission />
              </ProtectedRoute>
            }
          />

          <Route
            path="/student/exams/exam-for-submission/:id"
            element={
              <ProtectedRoute roles={[ROLE_STUDENT]} requireGroupId={true}>
                <ExamForSubmission />
              </ProtectedRoute>
            }
          />
          <Route path="/sign-in" element={<SignIn />} />

          <Route path="/login-link" element={<LoginLink />} />

          <Route path="/" element={<DefaultRoute />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
