import * as Yup from "yup";

export function getSubmissionGradeSchema(t) {
  return Yup.object().shape({
    grade: Yup.number()
      .min(0, t("Grade cannot be less than 0"))
      .max(100, t("Grade cannot be more than 100"))
      .required(t("Grade is required!")),
  });
}
