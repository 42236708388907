import React, { useRef, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  List,
  ListItem,
  Skeleton,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { colors } from "../Constants";
import {
  faClipboardList,
  faEdit,
  faTableList,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteExam, getExamForTeacher } from "../services/examService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationModal from "./modal/ConfirmationModalT";
import InnerHtml from "../helpers/InnerHtml";
import ProfilePicture from "../general/components/ProfilePicture";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

function ExamSubmissionsT() {
  const { token, selectedGroupId } = useSelector((x) => x.security) || {};
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: resp, status } = useQuery(["teacher-exam", id], () =>
    getExamForTeacher(id, selectedGroupId, token)
  );

  const getHumanizedTimerValue = (seconds) => {
    if (seconds == null) return "";
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

    return `${
      numhours
        ? numhours == 1
          ? `${1} ${t("hour")} `
          : `${numhours} ${t("hours")} `
        : ""
    }${
      numminutes == 1
        ? `${1} ${t("minute")} `
        : `${numminutes} ${t("minutes")} `
    }${
      numseconds == 1
        ? `${1} ${t("second")} `
        : `${numseconds} ${t("seconds")} `
    }`;
  };

  const queryClient = useQueryClient();

  const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(
    () => deleteExam(id, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-single-exam"],
        });
        navigate("/teacher/exams");
      },
    }
  );

  const handleDeleteConfirm = () => {
    deleteMutate();
  };
  const formatDate = (dateString, dateFormat) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return `${t("Invalid Date")}`;
    }
    return format(date, dateFormat);
  };

  const getSubmissionColor = (deadline, submissionTime, isHover = false) => {
    return deadline == null
      ? isHover
        ? "#2a6b68"
        : colors.primary
      : new Date(submissionTime) <= new Date(deadline)
      ? isHover
        ? "#2a6b68"
        : colors.primary
      : isHover
      ? "darkred"
      : "red";
  };
  const location = useLocation();
  const { t } = useTranslation("global");
  const [isLessThan610px] = useMediaQuery("(max-width: 610px)");

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          status={status}
          title={resp?.data?.name}
          icon={faTableList}
          backPath={
            location.state
              ? `/teacher/exams?take=${location.state.take}`
              : `/teacher/exams`
          }
        />
        {/* <HStack alignItems="start" justifyContent="space-between">
          <VStack alignItems="start" gap="16px">
            {" "}
            <Skeleton isLoaded={status == "success"}>
              <Text fontSize="22px" fontWeight="600">
                {`${resp?.data?.teacherName} ${resp?.data?.teacherSurname}`}{" "}
                <Text as="span">
                  |{" "}
                  {`${format(resp?.data?.createdTime ?? new Date(), "MMM do")}`}
                </Text>
              </Text>
            </Skeleton>
          </VStack>

          <Skeleton isLoaded={status == "success"}>
            {resp?.data?.deadline && (
              <Text fontWeight="500" color="#00000099">
                {`Due ${format(
                  resp?.data?.deadline ?? new Date(),
                  "MMM do, HH:mm, yyy"
                )}`}
              </Text>
            )}
          </Skeleton>
        </HStack> */}
        <HStack mt="8px" justifyContent="space-between">
          <Skeleton minW="40%" maxW="80%" isLoaded={status == "success"}>
            <Flex
              display={"flex"}
              alignItems={"center"}
              fontSize={["8px", "12px", "14px", "14px", "18px", "22px"]}
              fontWeight="600"
            >
              <Flex>
                <ProfilePicture url={resp?.data?.teacherProfilePictureKey} />
              </Flex>{" "}
              <HStack flexWrap="wrap">
                <Text whiteSpace="nowrap">
                  {`${resp?.data?.teacherName} ${resp?.data?.teacherSurname} (${resp?.data?.teacherUsername})`}
                  {" | "}
                </Text>
                <Text pl="4px" as="span">
                  {`${t("Posted")} - ${format(
                    resp?.data?.createdTime ?? new Date(),
                    "MMM do yyy"
                  )}`}
                </Text>
              </HStack>
            </Flex>
          </Skeleton>

          <Flex gap={["14px", "20px", "20px"]}>
            <Button
              onClick={() => navigate(`/teacher/exams/edit-exam/${id}`)}
              fontSize={["10px", "10px", "12px", "14px", "16px"]}
              gap="16px"
              _hover={{ bg: "#2a6b68" }}
              color={"white"}
              bg={colors.primary}
              border="1px solid "
              w={["60px", "70px", "80px", "110px", "160px"]}
              h={["24px", "28px", "30px", "40px"]}
            >
              <FontAwesomeIcon icon={faEdit} /> <Text>{t("Edit")}</Text>
            </Button>

            <ConfirmationModal
              buttonWidth={["60px", "70px", "80px", "110px", "160px"]}
              buttonH={["24px", "28px", "30px", "40px"]}
              fontSize={["10px", "10px", "12px", "14px", "16px"]}
              isLoading={isDeleting}
              onConfirm={handleDeleteConfirm}
            />
          </Flex>
        </HStack>
        <Divider mt="20px" borderColor="teal" borderWidth="2px" />
        <Skeleton mb="20px" minH="8vh" isLoaded={status == "success"}>
          <Box m="20px 0" lineHeight="22px">
            <Text color="#00000099">
              {resp?.data?.description ? (
                <InnerHtml fileKey={resp?.data?.description} />
              ) : (
                <Text
                  fontWeight="600"
                  w="100%"
                  textAlign="center"
                  m="2rem auto"
                >
                  {t("No description")}
                </Text>
              )}
            </Text>
          </Box>
        </Skeleton>
        <Box
          w={["100%", "100%", "90%", "60%"]}
          bg={colors.lightGreen}
          padding="24px 20px"
          px={["8px", "12", "16px", "20px"]}
          m="2rem 0 2rem"
        >
          <Skeleton isLoaded={status == "success"}>
            <Text
              fontSize={["12px", "14px", "16px", "20px", "24px"]}
              fontWeight="600"
            >
              {t("Exam Details")}
            </Text>

            <VStack w="100%" margin="20px 0" alignItems="start" gap="40px">
              <HStack
                gap="8px"
                alignItems="start"
                w="100%"
                fontWeight="600"
                justifyContent="space-between"
                fontSize={["10px", "12px", "16px"]}
              >
                <Text> {t("Deadline")}</Text>
                <Text>
                  {resp?.data?.deadline
                    ? formatDate(resp?.data?.deadline, "HH:mm, MMM do, yyy")
                    : `${t("No deadline")}`}
                </Text>
              </HStack>
              {resp?.data?.durationSeconds != null && (
                <HStack
                  gap="8px"
                  alignItems="start"
                  w="100%"
                  fontWeight="600"
                  justifyContent="space-between"
                  fontSize={["10px", "12px", "16px"]}
                >
                  <Text> {t("Duration")}</Text>
                  <Text>
                    {getHumanizedTimerValue(resp?.data?.durationSeconds)}
                  </Text>
                </HStack>
              )}
              <HStack
                gap="8px"
                alignItems="start"
                w="100%"
                fontWeight="600"
                justifyContent="space-between"
                fontSize={["10px", "12px", "16px"]}
              >
                <Text> {t("Graded")}</Text>
                <Text>
                  {" "}
                  {resp?.data?.isGraded ? `${t("Yes")}` : `${t("No")}`}
                </Text>
              </HStack>
              <HStack
                gap="8px"
                alignItems="start"
                w="100%"
                fontWeight="600"
                justifyContent="space-between"
                fontSize={["10px", "12px", "16px"]}
              >
                <Text> {t("Allowed for submission view")}</Text>
                <Text>
                  {resp?.data?.isAllowedForSubmissionView
                    ? `${t("Yes")}`
                    : `${t("No")}`}
                </Text>
              </HStack>
              <HStack
                gap="8px"
                alignItems="start"
                w="100%"
                fontWeight="600"
                justifyContent="space-between"
                fontSize={["10px", "12px", "16px"]}
              >
                <Text>
                  {" "}
                  {t("Visible for students")}{" "}
                  <Text fontSize="12px" fontWeight="400" color="#fcbe03">
                    {t(
                      "The exam cannot be made invisible after someone has started it."
                    )}
                  </Text>
                </Text>
                <Text>
                  {" "}
                  {resp?.data?.isVisible ? `${t("Yes")}` : `${t("No")}`}
                </Text>
              </HStack>{" "}
              <HStack
                gap="8px"
                alignItems="start"
                w="100%"
                fontWeight="600"
                justifyContent="space-between"
                fontSize={["10px", "12px", "16px"]}
              >
                <Text> {t("Grading type")}</Text>
                <Text> {resp?.data?.gradingType ? "Maunal" : "Automatic"}</Text>
              </HStack>
              <HStack
                gap="8px"
                alignItems="start"
                w="100%"
                fontWeight="600"
                justifyContent="space-between"
                fontSize={["10px", "12px", "16px"]}
              >
                <Text> {t("Questions")}</Text>
                <Text> {resp?.data?.questionCount}</Text>
              </HStack>
            </VStack>
          </Skeleton>
        </Box>
        {resp?.data?.questionCount > 0 && (
          <Flex flexDirection={"column"} width={"180px"}>
            <Button
              onClick={() => navigate(`/teacher/exams/overview/${id}`)}
              gap="16px"
              _hover={{ bg: "#2a6b68" }}
              color={"white"}
              bg={colors.primary}
              border="1px solid "
              w="100%"
            >
              {t("Overview exam")}
            </Button>
          </Flex>
        )}
        {resp?.data?.isQuestionModificationAllowed && (
          <Flex mt={"10px"} flexDirection={"column"} width={"180px"}>
            <Button
              onClick={() =>
                navigate(`/teacher/exams/create-exam/${id}`, {
                  state: { examId: id },
                })
              }
              gap="16px"
              _hover={{ bg: "#2a6b68" }}
              color={"white"}
              bg={colors.primary}
              border="1px solid "
              w="100%"
            >
              {t("Continue creating")}
            </Button>
            <Text fontSize="12px" fontWeight="400" color="#fcbe03">
              {t(
                "Once a student has begun the exam, it will not be possible to add, delete, or modify any questions."
              )}
            </Text>
          </Flex>
        )}
        <Box mb="28px" w="100%">
          {isLessThan610px ? (
            <SimpleBar
              autoHide={false}
              style={{ maxHeight: "calc(100vh - 100px)" }}
            >
              <Box
                sx={{
                  "@media (max-width: 610px)": {
                    minW: "710px",
                  },
                }}
              >
                <HStack
                  m="2rem 0"
                  mt={["1rem", "2rem"]}
                  padding="14px"
                  bg="#EAF5F4"
                  fontSize={["12px", "12px", "12px", "12px", "16px"]}
                  justifyContent="space-between"
                  h={["20px", "40px", "60px"]}
                  color={colors.primary}
                >
                  <Text textAlign="start" w="25%" as="i">
                    {t("Name")}
                  </Text>
                  {resp?.data?.durationSeconds != null && (
                    <Text textAlign="center" w="25%" as="i">
                      {t("Spent time")}
                    </Text>
                  )}
                  {resp?.data?.isGraded && (
                    <Text textAlign="center" w="25%" as="i">
                      {t("Grade")}
                    </Text>
                  )}
                  <Text textAlign="end" w="25%" as="i">
                    {t("Submissions")}
                  </Text>
                </HStack>

                <List mb={["1rem", "1.5rem", "2rem"]} w="100%">
                  {status == "loading" &&
                    Array.from({ length: 5 }).map(() => {
                      return (
                        <Skeleton>
                          <ListItem
                            fontSize={["12px", "12px", "8px", "12px", "16px"]}
                            padding="0px 14px"
                          ></ListItem>
                          <Divider
                            w="100%"
                            m="16px"
                            borderColor={colors.gray}
                            borderWidth="1px"
                          />
                          ◊
                        </Skeleton>
                      );
                    })}
                  {status == "success" &&
                    resp?.data?.submissions?.map((x) => {
                      return (
                        <>
                          <ListItem
                            fontSize={["12px", "12px", "12px", "16px"]}
                            fontWeight="400"
                            w="100%"
                            // padding="4px 14px"
                            px={["12px", "12px", "8px", "10px", "12px", "16px"]}
                          >
                            <Flex
                              justifyContent={"space-between"}
                              alignItems="center"
                            >
                              <Text
                                display={"flex"}
                                alignItems={"center"}
                                fontSize={[
                                  "12px",
                                  "12px",
                                  "12px",
                                  "14px",
                                  "16px",
                                ]}
                                fontWeight="500"
                                w="25%"
                                textAlign="start"
                              >
                                <Flex>
                                  <ProfilePicture
                                    url={x.studentProfilePictureKey}
                                  />
                                </Flex>{" "}
                                {`${x.studentName} ${x.studentSurname} (${x.studentUsername})`}
                              </Text>
                              {resp?.data?.durationSeconds != null &&
                              x.spentTimeSeconds != null ? (
                                <Text
                                  w="25%"
                                  textAlign="center"
                                  color={
                                    x.spentTimeSeconds <=
                                    resp.data.durationSeconds
                                      ? "green"
                                      : "red"
                                  }
                                >
                                  {getHumanizedTimerValue(x.spentTimeSeconds)}
                                </Text>
                              ) : (
                                resp?.data?.durationSeconds != null && (
                                  <Text w="25%" textAlign="center">
                                    {t("Not available")}
                                  </Text>
                                )
                              )}
                              {resp?.data?.isGraded &&
                                (x.grade != null ? (
                                  <Text w="25%" textAlign="center" as="i">
                                    {x.grade}%
                                  </Text>
                                ) : (
                                  <Text w="25%" textAlign="center">
                                    {t("Not available")}
                                  </Text>
                                ))}

                              <Text textAlign="end" w="25%">
                                {x.submissionTime ? (
                                  <Button
                                    mb="8px"
                                    onClick={() =>
                                      navigate(
                                        `/teacher/exams/exam-view-submission/${x.id}`
                                      )
                                    }
                                    // minW={["140px", "180px", "190px"]}
                                    _hover={{
                                      bg: getSubmissionColor(
                                        resp?.data?.deadline,
                                        x.submissionTime,
                                        true
                                      ),
                                    }}
                                    borderRadius="4px"
                                    bg={getSubmissionColor(
                                      resp?.data?.deadline,
                                      x.submissionTime
                                    )}
                                    border="1px solid"
                                    padding="6px"
                                    fontSize={["10px", "12px", "16px"]}
                                    color="white"
                                    fontWeight="400"
                                  >
                                    <i>
                                      {format(
                                        x.submissionTime ?? new Date(),
                                        "HH:mm, MMM do, yyy"
                                      )}
                                    </i>
                                  </Button>
                                ) : (
                                  <Button
                                    mb="8px"
                                    fontSize={[
                                      "12px",
                                      "12px",
                                      "12px",
                                      "14px",
                                      "16px",
                                    ]}
                                    // minW={["140px", "180px", "190px"]}
                                    _hover={{ bg: "white" }}
                                    cursor="default"
                                    borderRadius="4px"
                                    color={colors.primary}
                                    border="1px solid"
                                    padding="6px"
                                    bg="white"
                                    fontWeight="400"
                                  >
                                    <i>{t("No submission")}</i>
                                  </Button>
                                )}
                              </Text>
                            </Flex>
                          </ListItem>
                          <Divider
                            w="100%"
                            mb={["8px", "8px", "16px"]}
                            borderColor={colors.gray}
                            borderWidth="1px"
                          />
                        </>
                      );
                    })}
                </List>
              </Box>
            </SimpleBar>
          ) : (
            <Box>
              <HStack
                m="2rem 0"
                mt={["1rem", "2rem"]}
                padding="14px"
                bg="#EAF5F4"
                fontSize={["12px", "12px", "12px", "12px", "16px"]}
                justifyContent="space-between"
                h={["20px", "40px", "60px"]}
                color={colors.primary}
              >
                <Text textAlign="start" w="25%" as="i">
                  {t("Name")}
                </Text>
                {resp?.data?.durationSeconds != null && (
                  <Text textAlign="center" w="25%" as="i">
                    {t("Spent time")}
                  </Text>
                )}
                {resp?.data?.isGraded && (
                  <Text textAlign="center" w="25%" as="i">
                    {t("Grade")}
                  </Text>
                )}
                <Text textAlign="end" w="25%" as="i">
                  {t("Submissions")}
                </Text>
              </HStack>

              <List mb={["1rem", "1.5rem", "2rem"]} w="100%">
                {status == "loading" &&
                  Array.from({ length: 5 }).map(() => {
                    return (
                      <Skeleton>
                        <ListItem
                          fontSize={["12px", "12px", "8px", "12px", "16px"]}
                          padding="0px 14px"
                        ></ListItem>
                        <Divider
                          w="100%"
                          m="16px"
                          borderColor={colors.gray}
                          borderWidth="1px"
                        />
                        ◊
                      </Skeleton>
                    );
                  })}
                {status == "success" &&
                  resp?.data?.submissions?.map((x) => {
                    return (
                      <>
                        <ListItem
                          fontSize={["12px", "12px", "12px", "16px"]}
                          fontWeight="400"
                          w="100%"
                          // padding="4px 14px"
                          px={["12px", "12px", "8px", "10px", "12px", "16px"]}
                        >
                          <Flex
                            justifyContent={"space-between"}
                            alignItems="center"
                          >
                            <Text
                              display={"flex"}
                              alignItems={"center"}
                              fontSize={[
                                "12px",
                                "12px",
                                "12px",
                                "14px",
                                "16px",
                              ]}
                              fontWeight="500"
                              w="25%"
                              textAlign="start"
                            >
                              <Flex>
                                <ProfilePicture
                                  url={x.studentProfilePictureKey}
                                />
                              </Flex>{" "}
                              {`${x.studentName} ${x.studentSurname} (${x.studentUsername})`}
                            </Text>
                            {resp?.data?.durationSeconds != null &&
                            x.spentTimeSeconds != null ? (
                              <Text
                                w="25%"
                                textAlign="center"
                                color={
                                  x.spentTimeSeconds <=
                                  resp.data.durationSeconds
                                    ? "green"
                                    : "red"
                                }
                              >
                                {getHumanizedTimerValue(x.spentTimeSeconds)}
                              </Text>
                            ) : (
                              resp?.data?.durationSeconds != null && (
                                <Text w="25%" textAlign="center">
                                  {t("Not available")}
                                </Text>
                              )
                            )}
                            {resp?.data?.isGraded &&
                              (x.grade != null ? (
                                <Text w="25%" textAlign="center" as="i">
                                  {x.grade}%
                                </Text>
                              ) : (
                                <Text w="25%" textAlign="center">
                                  {t("Not available")}
                                </Text>
                              ))}

                            <Text textAlign="end" w="25%">
                              {x.submissionTime ? (
                                <Button
                                  mb="8px"
                                  onClick={() =>
                                    navigate(
                                      `/teacher/exams/exam-view-submission/${x.id}`
                                    )
                                  }
                                  // minW={["140px", "180px", "190px"]}
                                  _hover={{
                                    bg: getSubmissionColor(
                                      resp?.data?.deadline,
                                      x.submissionTime,
                                      true
                                    ),
                                  }}
                                  borderRadius="4px"
                                  bg={getSubmissionColor(
                                    resp?.data?.deadline,
                                    x.submissionTime
                                  )}
                                  border="1px solid"
                                  padding="6px"
                                  fontSize={["10px", "12px", "16px"]}
                                  color="white"
                                  fontWeight="400"
                                >
                                  <i>
                                    {format(
                                      x.submissionTime ?? new Date(),
                                      "HH:mm, MMM do, yyy"
                                    )}
                                  </i>
                                </Button>
                              ) : (
                                <Button
                                  mb="8px"
                                  fontSize={[
                                    "12px",
                                    "12px",
                                    "12px",
                                    "14px",
                                    "16px",
                                  ]}
                                  // minW={["140px", "180px", "190px"]}
                                  _hover={{ bg: "white" }}
                                  cursor="default"
                                  borderRadius="4px"
                                  color={colors.primary}
                                  border="1px solid"
                                  padding="6px"
                                  bg="white"
                                  fontWeight="400"
                                >
                                  <i>{t("No submission")}</i>
                                </Button>
                              )}
                            </Text>
                          </Flex>
                        </ListItem>
                        <Divider
                          w="100%"
                          mb={["8px", "8px", "16px"]}
                          borderColor={colors.gray}
                          borderWidth="1px"
                        />
                      </>
                    );
                  })}
              </List>
            </Box>
          )}
        </Box>{" "}
      </Container>
    </Flex>
  );
}

export default ExamSubmissionsT;
