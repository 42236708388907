import React, { useEffect, useState } from 'react'
import { s3Client } from './s3Client';

export default React.memo(function InnerHtml({fileKey}) {
  const [html, setHtml] = useState('')
  useEffect(()=>{
    if(fileKey == null) return
    
    let url = s3Client.getUrl(fileKey)
    fetch(url)
    .then((res) => {
      if(!res.ok){
        throw new Error()
      }
      return res.text()
    })
    .catch(e=>{})
    .then(x=>setHtml(x))
  },[fileKey])

  return (
    <div
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  ></div>
  )
})
