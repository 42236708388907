import { Button, Flex, HStack, Input, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { colors } from "../Constants";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import { gradeSubmittedQuestionPromise } from "../services/examService";
import { useSelector } from "react-redux";
import {
  getGradeQuestionSchema,
  gradeQuestionSchema,
} from "./validations/gradeQuestionSchema";
import { useTranslation } from "react-i18next";

export default function GradeQuestion({
  submittedQuestionId,
  grade,
  maxGrade,
}) {
  const { t } = useTranslation("global");
  const validationSchema = getGradeQuestionSchema(maxGrade, t);

  const queryClient = useQueryClient();
  const { token } = useSelector((x) => x.security) || {};
  const { mutate, isLoading } = useMutation(
    (data) => gradeSubmittedQuestionPromise(submittedQuestionId, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["teacher-exam-submission-for-student"]);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      grade: grade,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate(values);
    },
    enableReinitialize: true,
  });

  return (
    <HStack alignItems="center">
      {" "}
      <Text p="0" pl="4px" m="0" color="red">
        {formik.errors.grade}
      </Text>
      <Flex columnGap={"10px"}>
        <Input
          //  min={0}
          max={maxGrade}
          name="grade"
          onChange={formik.handleChange}
          type="number"
          onBlur={formik.handleBlur}
          width={"60px"}
          value={formik.values.grade}
        />
        <Button
          isDisabled={!formik.dirty}
          isLoading={isLoading}
          bg={colors.primary}
          color="white"
          w="60px"
          onClick={formik.handleSubmit}
          fontWeight="600"
          fontSize="16px"
          _hover={{ bg: "#2a6b68" }}
        >
          {t("Save")} {/* grade question */}
        </Button>
      </Flex>
    </HStack>
  );
}
