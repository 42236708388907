import { httpClient } from "../helpers/httpClient";

export const getStudentsForGroupPromise = (groupId, token) => {
  return httpClient.get(`student/group/${groupId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getGroupsOfStudentForManagerPromise = (studentId, token) => {
  return httpClient.get(`student/get-groups/${studentId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getGroupsOfStudentForStudentPromise = (token) => {
  return httpClient.get(`student/get-groups`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getStudentsForInstitutionPromise = (institutionId, token) => {
  return httpClient.get(`student/institution/${institutionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateStudentPromise = (id, data, token) => {
  return httpClient.put(`student/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getStudentByIdPromise = (id, token) => {
  return httpClient.get(`student/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//! delete

export const deleteStudent = (studentId, token) => {
  return httpClient.delete(`student/delete/${studentId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
