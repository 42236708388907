import { Avatar, Box } from "@chakra-ui/react";
import React from "react";

function Ellipse({ src }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      margin="0 auto"
      position={"relative"}
      w="180px"
      h="180px"
      sx={{
        "@media (max-width: 580px)": {
          w: "100px",
          h: "100px",
        },
        "@media (max-width: 500px)": {
          w: "80px",
          h: "80px",
        },
        "@media (max-width: 500px)": {
          w: "70px",
          h: "70px",
        },
        "@media (max-width: 800px)": {
          w: "140px !important",
          h: "140px !important",
        },
        "@media (max-width: 992px)": {
          w: "160px",
          h: "160px",
        },
        "@media (max-width: 560px)": {
          w: "100px",
          h: "100px",
        },
      }}
      borderRadius="50%"
      bgGradient="linear(90deg, rgba(214,248,248,1) 0%, rgba(148,235,229,1) 35%, rgba(77,167,162,1) 100%)"
    >
      <Box
        w="160px"
        top="50%"
        left="50%"
        h="160px"
        sx={{
          "@media (max-width: 800px)": {
            boxSize: "82%",
          },
          "@media (max-width: 992px)": {
            boxSize: "90%",
          },
        }}
        position="absolute"
        transform="translate(-50%, -50%);"
        borderRadius="50%"
        bg={"white"}
      >
        <Avatar
          top="50%"
          left="50%"
          position="absolute"
          transform="translate(-50%, -50%);"
          borderRadius="50%"
          zIndex="200"
          w="76%"
          h="120px"
          sx={{

            "@media (max-width: 992px)": {
              boxSize: "70%",
            },

            "@media (max-width: 800px)": {
              boxSize: "62%",
            },
            "@media (max-width: 768px)": {
              boxSize: "80%",
            },
          }}
          src={src}
        />
      </Box>
      <Box
        width="12px"
        h="20px"
        border="1px solid white"
        sx={{
          "@media (max-width: 768px)": {
            display: "none",
          },
        }}
        bg="white"
        position="absolute"
        top="1"
        left="50%"
        transform="translate(-50%, -50%);"
      ></Box>

      <Box
        height="12px"
        width="34px"
        border="1px solid white"
        bg="white"
        sx={{
          "@media (max-width: 768px)": {
            display: "none",
          },
        }}
        position="absolute"
        top="32%"
        right="0%"
        w={{ base: "18px", sm: "24px", md: "29px" }}
      ></Box>

      <Box
        height="12px"
        width="30px"
        border="1px solid white"
        sx={{
          "@media (max-width: 768px)": {
            display: "none",
          },
        }}
        bg="white"
        position="absolute"
        top="32%"
        left="0%"
        w={{ base: "14px", sm: "24px", md: "29px" }}
      ></Box>
      <Box
        w={{ base: "80%", md: "100%" }}
        h="32px"
        bg="white"
        sx={{
          "@media (max-width: 768px)": {
            display: "none",
          },
        }}
        position="absolute"
        top="84%"
      ></Box>
    </Box>
  );
}

export default Ellipse;
