import { httpClient } from "../helpers/httpClient";

export const getMyNotificationsPromise = (token) => {
  return httpClient.get("notification/my-notifications", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteNotificationPromise = (token, notificationId) => {
  return httpClient.delete(`notification/delete/${notificationId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
