import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faPowerOff, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteNotificationPromise,
  getMyNotificationsPromise,
} from "../../services/notificationService";
import { format } from "date-fns";

export default function NotificationModal({
  hoverColor = "red",
  hoverBg = "none",
  color = "black",
  bgColor = "transparent",
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleDelete = (id) => {
    mutate(id);
  };
  const queryClient = useQueryClient();
  const { token } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(
    ["my-notifications", token],
    () => getMyNotificationsPromise(token),
    { enabled: isOpen }
  );
  const { mutate } = useMutation(
    (notificationId) => deleteNotificationPromise(token, notificationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["my-notifications"],
        });
      },
    }
  );

  const formatDate = (dateString, dateFormat) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return `${t("Invalid Date")}`;
    }
    return format(date, dateFormat);
  };

  const getDisplayDate = (dateStr) => {
    if (new Date(dateStr).getFullYear() != new Date().getFullYear()) {
      return formatDate(dateStr, "MMM do, HH:mm, yyyy");
    } else {
      return formatDate(dateStr, "MMM do, HH:mm");
    }
  };

  const getNotificationText = (notification) => {
    let {
      assignmentCreationNotification,
      assignmentGradedNotification,
      assignmentSubmissionNotification,
      duePaymentReminderNotification,
      examCreationNotification,
      examGradedNotification,
      examSubmissionNotification,
      materialComplianceNotification,
      materialCreationNotification,
      upcomingPaymentReminderNotification,
      studentAddedNotification,
      studentRemovedNotification,
    } = notification;
    if (assignmentCreationNotification != null) {
      return `${t("Assignment named")} "${assignmentCreationNotification.assignmentName}" ${t("for group")} "${assignmentCreationNotification.groupName}" ${t("has been created!")}`;
    }
    if (assignmentGradedNotification != null) {
      return `${t("Assignment named")} "${assignmentGradedNotification.assignmentName}" ${t("for group")} "${assignmentGradedNotification.groupName}" ${t("has been graded as")} ${assignmentGradedNotification.grade}%!`;
    }
    if (assignmentSubmissionNotification != null) {
      return `${assignmentSubmissionNotification.studentName} ${assignmentSubmissionNotification.studentSurname} ${t("has submitted the assignment named")} "${assignmentSubmissionNotification.assignmentName}" ${t("for group")} "${assignmentSubmissionNotification.groupName}".`;
    }
    if (duePaymentReminderNotification != null) {
      return `${t("Payment for group")} "${duePaymentReminderNotification.groupName} ${t("is due!")}"`;
    }
    if (examCreationNotification != null) {
      return `${t("Exam named")} "${examCreationNotification.examName}" ${t("for group")} "${examCreationNotification.groupName}" ${t("has been created!")}`;
    }
    if (examGradedNotification != null) {
      return `${t("Exam named")} "${examGradedNotification.examName}" ${t("for group")} "${examGradedNotification.groupName}" ${t("has been graded as")} ${examGradedNotification.grade}%!`;
    }
    if (examSubmissionNotification != null) {
      return `${examSubmissionNotification.studentName} ${examSubmissionNotification.studentSurname} ${t("has submitted the exam named")} "${examSubmissionNotification.examName}" ${t("for group")} "${examSubmissionNotification.groupName}".`;
    }
    if (materialComplianceNotification != null) {
      return `${materialComplianceNotification.studentName} ${materialComplianceNotification.studentSurname} ${t("has read the material named")} "${materialComplianceNotification.materialName}" ${t("for group")} "${materialComplianceNotification.groupName}".`;
    }
    if (materialCreationNotification != null) {
      return `${t("Material named")} "${materialCreationNotification.materialName}" ${t("for group")} "${materialCreationNotification.groupName}" ${t("has been created!")}`;
    }
    if (upcomingPaymentReminderNotification != null) {
      return `${t("Payment for group")} "${upcomingPaymentReminderNotification.groupName}" is soon!`;
    }
    if (studentAddedNotification != null) {
      return `Student ${studentAddedNotification.studentName} ${studentAddedNotification.studentSurname} was added to "${studentAddedNotification.groupName}" group.`;
    }
    if (studentRemovedNotification != null) {
      return `Student ${studentRemovedNotification.studentName} ${studentRemovedNotification.studentSurname} was removed from "${studentRemovedNotification.groupName}" group.`;
    }
  };

  const { t } = useTranslation("global");

  return (
    <>
      <Button
        sx={{
          "@media (max-width: 700px)": {
            h: "32px",
            w: "32px",
          },
          "@media (max-width: 500px)": {
            h: "28px",
            w: "28px",
          },
        }}
        variant="ghost"
        onClick={onOpen}
        fontSize="16px"
        transition=".5s"
        _hover={{ bg: hoverBg, color: hoverColor }}
        color={color}
        bgColor={bgColor}
      >
        <FontAwesomeIcon fontSize="20px" color="white" icon={faBell} />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          // w="32%"

          width={{ base: "88%", sm: "80%", md: "60%", lg: "42%" }}
          containerProps={{ justifyContent: "center" }}
        >
          <ModalHeader>
            <Text as="h1" fontSize="20px" fontWeight="bold">
              {t("Notifications")}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            {status == "loading" &&
              Array.from({ length: 5 }).map(() => {
                return (
                  <Skeleton>
                    <Flex mt={"20px"} p={"20px"}></Flex>
                  </Skeleton>
                );
              })}

            {resp?.data.length === 0 ? (
              <Text>{t("No notifications available")}</Text>
            ) : (
              resp?.data.map((notification) => (
                <Flex p={"10px"} justifyContent={"space-between"}>
                  <div key={notification.id}>
                    <Text fontSize="16px" fontWeight="medium">
                      {getNotificationText(notification)}
                    </Text>
                    <Text fontSize="12px" color="gray.500">
                      {getDisplayDate(notification.createdTime)}
                    </Text>
                  </div>
                  <Button
                    size="sm"
                    height={"34px"}
                    width={"34px"}
                    // marginLeft={"5px"}
                    marginTop={"5px"}
                    colorScheme="red"
                    onClick={() => handleDelete(notification.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Flex>
              ))
            )}
          </ModalBody>

          <ModalFooter
            justifyContent="center"
            w="100%"
            margin="4px auto"
          ></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
