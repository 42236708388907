import * as Yup from "yup";

export function getEditExamSchema(t) {
  return Yup.object().shape({
    name: Yup.string().required(t("Exam name is required!")),
    hours: Yup.number()
      .nullable()
      .min(0, t("Hours cannot be negative"))
      .max(23, t("Hours cannot be more than 23"))
      .typeError(t("Wrong")),
    minutes: Yup.number()
      .nullable()
      .min(0, t("Minutes cannot be negative"))
      .max(59, t("Minutes cannot be more than 59"))
      .typeError(t("Wrong")),
    seconds: Yup.number()
      .nullable()
      .min(0, t("Seconds cannot be negative"))
      .max(59, t("Seconds cannot be more than 59"))
      .typeError(t("Wrong")),
  });
}
