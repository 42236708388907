import { httpClient } from "../helpers/httpClient";

export const getUpcomingStudentPaymentsPromise = (token) => {
  return httpClient.get(`student-payment/upcoming`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getStudentsForPaymentPromise = (token) => {
  return httpClient.get(`student-payment/students`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getStudentPaymentsPromise = (groupStudentId, token) => {
  return httpClient.get(`student-payment/group-student/${groupStudentId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createStudentPaymentPromise = (data, token) => {
  return httpClient.post("student-payment/create", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPaymentReminderPromise = (groupStudentId, token) => {
  return httpClient.get(`student-payment/payment-reminder/${groupStudentId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updatePaymentReminderPromise = (paymentReminderId, data, token) => {
  return httpClient.put(`student-payment/update-payment-reminder/${paymentReminderId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};


//! delete

export const deleteStudentPayment = (studentPaymentId, token) => {
  return httpClient.delete(`student-payment/delete/${studentPaymentId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};