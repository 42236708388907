import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import React, { useRef, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  getSingleAssignmentForTeacher,
  updateAssignment,
} from "../services/assignmentService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMyJoditEditor from "../hooks/useMyJoditEditor";
import MyJoditEditor from "../helpers/MyJoditEditor";
import { getEditAssignmentSchema } from "./validations/editAssignmentSchema";
import ExistingFile from "../general/components/ExistingFile";
import NewFile from "../general/components/NewFile";
import { useTranslation } from "react-i18next";

function EditAssignment() {
  const { t } = useTranslation("global");
  const validationSchema = getEditAssignmentSchema(t);

  const { id } = useParams();
  const { token, selectedGroupId } = useSelector((x) => x.security) || {};
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { content, isDirty, onBlur, fileKey, isSaving, initialize } =
    useMyJoditEditor();
  const { data: resp, status } = useQuery(
    ["teacher-single-assignment", id],
    () => getSingleAssignmentForTeacher(id, selectedGroupId, token),
    {
      onSuccess: (resp) => {
        if (content == null) initialize(resp?.data?.description);
      },
    }
  );

  const { mutate, isLoading } = useMutation(
    (data) => updateAssignment(id, token, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-single-assignment", , "teacher-assignments"],
        });
        navigate(`/teacher/assignments/${id}`);
      },
    }
  );

  const formatDateTimeLocal = (dateString) => {
    if (!dateString) {
      return null;
    }

    const date = new Date(dateString);

    const pad = (num) => String(num).padStart(2, "0");
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
      date.getDate()
    )}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
  };

  const [newFiles, setNewFiles] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: resp?.data?.name,
      deadline: formatDateTimeLocal(resp?.data?.deadline),
      isGraded: resp?.data?.isGraded,
      allowSubmissionEdit: resp?.data?.allowSubmissionEdit,
      deletedFileAttachmentIds: [],
      groupId: selectedGroupId,
    },
    validationSchema: validationSchema,
    onSubmit: (v) => {
      let values = { ...v };
      values.newFileKeys = newFiles.filter((x) => x.key).map((x) => x.key);
      values.description = fileKey;
      values.deadline =
        values.deadline == null || values.deadline == ""
          ? null
          : new Date(values.deadline).toISOString();
      mutate(values);
    },
    enableReinitialize: true,
  });

  const handleRemoveExistingFile = (fileId) => {
    const updatedDeletedFiles = [
      ...formik.values.deletedFileAttachmentIds,
      fileId,
    ];
    formik.setFieldValue("deletedFileAttachmentIds", updatedDeletedFiles);
  };

  const onFileUpload = (id, key) => {
    setNewFiles((prev) => {
      let file = prev.find((x) => x.id == id);
      if (file == null) return prev;
      file.key = key;
      return prev;
    });
  };

  const handleFileChange = (event) => {
    const files = event.currentTarget.files;
    const updatedFiles = [
      ...newFiles,
      ...Array.from(files).map((x) => ({ file: x, id: uuidv4() })),
    ];
    setNewFiles(updatedFiles);
  };

  const handleRemoveNewFile = (id) => {
    const updatedFiles = newFiles.filter((x) => x.id != id);
    setNewFiles(updatedFiles);
  };
  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <Box>
          <PageTitle
            backPath={`/teacher/assignments/${id}`}
            status={status}
            title={t("Edit Assignment")}
            icon={faClipboardList}
          />
        </Box>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="100%"
          margin="8px auto"
          p="20px 0"
          gap="20px"
          bg="#EAF5F4"
        >
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Assignment name")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="name"
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="text"
                placeholder={t("Assignment name")}
                _placeholder={{ color: "#000000" }}
              />
            </Skeleton>
            {formik.errors.name && formik.touched.name && (
              <Text p="0" pl="4px" m="0" color="red">
                {formik.errors.name}
              </Text>
            )}
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Description")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <MyJoditEditor
                content={content}
                isSaving={isSaving}
                onBlur={onBlur}
              />
            </Skeleton>
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Deadline")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                value={formik.values.deadline}
                onChange={formik.handleChange}
                name="deadline"
                bg="white"
                color="#000000"
                borderRadius="4px"
                p="0 12px"
                type="datetime-local"
              />
              {/* <InputRightElement pointerEvents="none">
                  <CalendarIcon mr="6px" color={colors.primary} />
                </InputRightElement> */}
              {/* </InputGroup> */}
            </Skeleton>
          </FormControl>

          <FormControl display={"flex"} w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Add attachments")}
            </FormLabel>
            <FontAwesomeIcon
              color="green"
              fontSize="24px"
              icon={faPlusCircle}
              onClick={handleIconClick}
              style={{ cursor: "pointer" }}
            />
            <Input
              ref={fileInputRef}
              display="none"
              bg="white"
              color="#000000"
              borderRadius="4px"
              p="0 12px"
              type="file"
              multiple
              // textStyle={{ placeholder: "There is no file" }}
              paddingTop="7px"
              onChange={handleFileChange}
            />
          </FormControl>

          {newFiles.length > 0 && (
            <Box w={["90%", "80%", "70%", "60%", "50%"]}>
              <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                {t("Selected attachments")}:
              </FormLabel>
              <Flex w="100%" flexWrap="wrap" gap="12px">
                {newFiles?.map((x) => (
                  <NewFile
                    onUploadFunc={(key) => onFileUpload(x.id, key)}
                    file={x.file}
                    key={x.id}
                    onRemoveFunc={() => handleRemoveNewFile(x.id)}
                  />
                ))}
              </Flex>
            </Box>
          )}

          {resp?.data?.fileAttachments?.length > 0 && (
            <Box w={["90%", "80%", "70%", "60%", "50%"]}>
              <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                {t("Existing attachments")}:
              </FormLabel>

              <Skeleton w="100%" isLoaded={status == "success"}>
                <Flex w="100%" flexWrap="wrap" gap="12px">
                  {resp?.data?.fileAttachments
                    .filter(
                      (x) =>
                        !formik.values.deletedFileAttachmentIds.includes(x.id)
                    )
                    .map((x) => (
                      <ExistingFile
                        key={x.id}
                        fileKey={x.key}
                        onRemoveFunc={() => handleRemoveExistingFile(x.id)}
                      />
                    ))}
                </Flex>
              </Skeleton>
            </Box>
          )}

          <FormControl
            w={["90%", "80%", "70%", "60%", "50%"]}
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Graded assignment")}
              <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                {t(
                  "Assignments can be either graded or ungraded. Submissions of only graded assignments can be assessed."
                )}
              </FormLabel>
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Checkbox
                name="isGraded"
                onChange={formik.handleChange}
                isChecked={formik.values.isGraded}
                colorScheme="teal"
                borderColor={"teal"}
                size="lg"
              />
            </Skeleton>
          </FormControl>

          <FormControl
            w={["90%", "80%", "70%", "60%", "50%"]}
            display="flex"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Allow submission edit")}
              <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                {t(
                  "If submission edits are enabled, students can modify their submissions after submitting. If disabled, they cannot change their submissions. This setting can be adjusted even after the assignment is created."
                )}
              </FormLabel>
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Checkbox
                name="allowSubmissionEdit"
                onChange={formik.handleChange}
                isChecked={formik.values.allowSubmissionEdit}
                colorScheme="teal"
                borderColor={"teal"}
                size="lg"
              />
            </Skeleton>
          </FormControl>

          <Button
            isLoading={isLoading}
            isDisabled={
              (!formik.dirty && !isDirty && newFiles.length == 0) || isSaving
            }
            bg={colors.primary}
            color="white"
            w={["90%", "80%", "70%", "60%", "50%"]}
            h="40px"
            fontWeight="600"
            fontSize="16px"
            _hover={{ bg: "#2a6b68" }}
            onClick={formik.handleSubmit}
          >
            {t("Save")}
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
}

export default EditAssignment;
