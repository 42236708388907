import React from "react";
import PageTitle from "../general/components/PageTitle";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { colors } from "../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faPlus } from "@fortawesome/free-solid-svg-icons";

function ExamStart() {
  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle title="Exam" icon={faClipboardList} />

        <HStack alignItems="start" justifyContent="space-between">
          <VStack alignItems="start" gap="16px">
            {" "}
            <Text fontSize="22px" fontWeight="600">
              Niyazi Babayev <Text as="span"> | Feb 3rd</Text>
            </Text>
            <Text p="0" m="0" fontWeight="600">
              100 points{" "}
              <Text fontWeight="400" as="span">
                / 100%
              </Text>
            </Text>
          </VStack>
          <Text fontWeight="500" color="#00000099">
            Due Jan 19, 7:00 pm
          </Text>
        </HStack>
        <Divider mt="20px" borderColor={colors.gray} borderWidth="1px" />
        <Box m="20px 0" lineHeight="22px">
          <Text color="#00000099" w="730px">
            {" "}
            Invite font select move community stroke pixel text draft. Rotate
            invite asset draft main polygon background overflow follower.
            Arrange rectangle plugin rectangle align content underline link
            prototype. Follower opacity edit follower editor stroke.
          </Text>
        </Box>
        <Box
          w="468px"
          bg={colors.lightGreen}
          padding="24px 20px"
          m="4rem 0 2rem"
        >
          <Text fontSize="24px" fontWeight="600">
            Exam Details
          </Text>
          <Flex margin="20px 0" justifyContent="space-between">
            <VStack alignItems="start" gap="40px">
              <Text fontWeight="600" fontSize="16px">
                Exam Title
              </Text>
              <Text fontWeight="600" fontSize="16px">
                Deadline
              </Text>
              <Text fontWeight="600" fontSize="16px">
                Duration
              </Text>

              <Text fontWeight="600" fontSize="16px">
                Graded
              </Text>
              <Text fontWeight="600" fontSize="16px">
                Questions
              </Text>
            </VStack>
            <VStack alignItems="end" gap="40px">
              <Text fontWeight="400" fontSize="16px">
                Special Wet
              </Text>
              <Text fontWeight="700" fontSize="16px">
                10:00 am
              </Text>
              <Text fontWeight="700" fontSize="16px">
                1 hour 30 minutes
              </Text>
              <Text fontWeight="700" fontSize="16px">
                Yes
              </Text>
              <Text fontWeight="500" fontSize="16px">
                12
              </Text>
            </VStack>
          </Flex>

          <Flex justifyContent="space-between" margin="0 auto" m="2rem 0">
            <Button
              color={colors.primary}
              bg="inherit"
              border={`1px solid ${colors.primary}`}
              w="200px"
              _hover={{ bg: "#aae0d5", borderColor: "#aae0d5" }}
            >
              Cancel
            </Button>
            <Button
              gap="16px"
              _hover={{ bg: "#2a6b68" }}
              color={"white"}
              bg={colors.primary}
              border="1px solid "
              w="200px"
            >
              <FontAwesomeIcon icon={faPlus} /> <Text>Start</Text>
            </Button>
          </Flex>
        </Box>
      </Container>
    </Flex>
  );
}

export default ExamStart;
