import { httpClient } from "../helpers/httpClient";

export const signInPromise = (data) => {
  return httpClient.post("security/sign-in", data);
};

export const forgotpassword = (data) => {
  return httpClient.post("security/forgot-password", data);
};


export const whoAmIPromise = (token) => {
  return httpClient.get("security/who-am-i", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createStudentPromise = (institutionId, data, token) => {
  return httpClient.post(`security/create-student/${institutionId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createTeacherPromise = (institutionId, data, token) => {
  return httpClient.post(`security/create-teacher/${institutionId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateMyAccount = (token, data) => {
  return httpClient.post(`security/update-my-account`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

