import { Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { colors } from "../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <>
      <Flex
        height={["70vh", "60vh"]}
        w={"100%"}
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        backgroundColor="white"
      >
        <Text
          color={colors.primary}
          fontSize={["28px", "28px", "36px"]}
          fontWeight="700"
          bg="white"
          w={["90%", "80%", "70%", "60%", "50%", "40%"]}
          padding="32px 40px"
          borderRadius="28px"
          vorder
          textAlign="center"
        >
          Not Found
        </Text>
        <Button
          colorScheme="teal"
          borderRadius="20px"
          w={["120px", "120px", "140px"]}
          border={`1px solid ${colors.primary}`}
          onClick={() => navigate(-1)}
          display={"flex"}
          color="white"
          alignItems={"center"}
          justifyContent={"space-between"}
          py="22px"
          px={"20px"}
        >
          <FontAwesomeIcon padding="0" margin="0" icon={faArrowLeft} />
          Back
        </Button>
      </Flex>
    </>
  );
}
