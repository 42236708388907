import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Skeleton,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faPaperclip,
  faPaste,
  faPlusCircle,
  faSquareFull,
  faX,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {
  getMaterialsForTeacher,
  updateMaterial,
} from "../services/materialService";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { s3Client } from "../helpers/s3Client";
import useMyJoditEditor from "../hooks/useMyJoditEditor";
import MyJoditEditor from "../helpers/MyJoditEditor";
import {
  editMaterialSchema,
  getEditMaterialSchema,
} from "./validations/editMaterialSchema";
import ExistingFile from "../general/components/ExistingFile";
import NewFile from "../general/components/NewFile";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

function EditMaterial() {
  const { t } = useTranslation("global");
  const validationSchema = getEditMaterialSchema(t);

  const { id } = useParams();
  const { token } = useSelector((x) => x.security) || {};
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { content, isDirty, onBlur, fileKey, isSaving, initialize } =
    useMyJoditEditor();

  const { data: resp, status } = useQuery(
    ["teacher-single-material", id],
    () => getMaterialsForTeacher(id, token),
    {
      onSuccess: (resp) => {
        if (content == null) initialize(resp?.data?.description);
      },
    }
  );

  const { mutate, isLoading } = useMutation(
    (data) => updateMaterial(id, token, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-single-material"],
        });
        navigate(`/teacher/materials/${id}`);
      },
    }
  );

  const [newFiles, setNewFiles] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: resp?.data?.name,
      deletedFileAttachmentIds: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.description = fileKey;
      values.newFileKeys = newFiles.filter((x) => x.key).map((x) => x.key);
      mutate(values);
    },
    enableReinitialize: true,
  });

  const handleRemoveExistingFile = (fileId) => {
    const updatedDeletedFiles = [
      ...formik.values.deletedFileAttachmentIds,
      fileId,
    ];
    formik.setFieldValue("deletedFileAttachmentIds", updatedDeletedFiles);
  };
  const onFileUpload = (id, key) => {
    setNewFiles((prev) => {
      let file = prev.find((x) => x.id == id);
      if (file == null) return prev;
      file.key = key;
      return prev;
    });
  };

  const handleFileChange = (event) => {
    const files = event.currentTarget.files;
    const updatedFiles = [
      ...newFiles,
      ...Array.from(files).map((x) => ({ file: x, id: uuidv4() })),
    ];
    setNewFiles(updatedFiles);
  };

  const handleRemoveNewFile = (id) => {
    const updatedFiles = newFiles.filter((x) => x.id != id);
    setNewFiles(updatedFiles);
  };

  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };
  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <Box>
          <PageTitle
            status={status}
            title={t("Edit Materiall")}
            icon={faPaste}
            backPath={`/teacher/materials/${id}`}
          />
        </Box>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="100%"
          margin="8px auto"
          p="20px 0"
          gap="24px"
          bg="#EAF5F4"
        >
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Material name")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="name"
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="text"
                placeholder={t("Material name")}
                _placeholder={{ color: "#000000" }}
              />
            </Skeleton>
            {formik.errors.name && formik.touched.name && (
              <Text p="0" pl="4px" m="0" color="red">
                {formik.errors.name}
              </Text>
            )}
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Content")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <MyJoditEditor
                content={content}
                onBlur={onBlur}
                isSaving={isSaving}
              />
            </Skeleton>
          </FormControl>

          <FormControl display={"flex"} w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("New attachments")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <FontAwesomeIcon
                color="green"
                fontSize="24px"
                icon={faPlusCircle}
                onClick={handleIconClick}
                style={{ cursor: "pointer" }}
              />
            </Skeleton>
            <Input
              ref={fileInputRef}
              display="none"
              bg="white"
              color="#000000"
              borderRadius="4px"
              p="0 12px"
              type="file"
              multiple
              // textStyle={{ placeholder: "There is no file" }}
              paddingTop="7px"
              onChange={handleFileChange}
            />
          </FormControl>

          {newFiles.length > 0 && (
            <Box w={["90%", "80%", "70%", "60%", "50%"]}>
              <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                {t("Selected attachments")}:
              </FormLabel>

              <Flex w="100%" flexWrap="wrap" gap="12px">
                {newFiles?.map((x) => (
                  <NewFile
                    onUploadFunc={(key) => onFileUpload(x.id, key)}
                    file={x.file}
                    key={x.id}
                    onRemoveFunc={() => handleRemoveNewFile(x.id)}
                  />
                ))}
              </Flex>
            </Box>
          )}

          {resp?.data?.fileAttachments?.length > 0 && (
            <Box w={["90%", "80%", "70%", "60%", "50%"]}>
              <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                {t("Existing Files")}:
              </FormLabel>

              <Skeleton w="100%" isLoaded={status == "success"}>
                <Flex w="100%" flexWrap="wrap" gap="12px">
                  {resp?.data?.fileAttachments
                    .filter(
                      (x) =>
                        !formik.values.deletedFileAttachmentIds.includes(x.id)
                    )
                    .map((x) => (
                      <ExistingFile
                        key={x.id}
                        fileKey={x.key}
                        onRemoveFunc={() => handleRemoveExistingFile(x.id)}
                      />
                    ))}
                </Flex>
              </Skeleton>
            </Box>
          )}

          <Button
            isLoading={isLoading}
            isDisabled={
              (!formik.dirty && !isDirty && newFiles.length == 0) || isSaving
            }
            bg={colors.primary}
            color="white"
            w={["90%", "80%", "70%", "60%", "50%"]}
            h="40px"
            fontWeight="600"
            fontSize="16px"
            _hover={{ bg: "#2a6b68" }}
            onClick={formik.handleSubmit}
          >
            {t("Save")}
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
}

export default EditMaterial;
