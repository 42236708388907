import { httpClient } from "../helpers/httpClient";

export const getAssignments = (groupId, token, skip, take) => {
  return httpClient.get(`assignment/group/${groupId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      skip,
      take,

      // ...(take !== undefined && { take }),
    },
  });
};

// //! student //

export const getAssignmentGradesForStudentPromise = (token, groupId) => {
  return httpClient.get(`assignment/grades/for-student/${groupId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getSingleAssignmentForStudent = (assignmentId, groupId, token) => {
  return httpClient.get(`assignment/for-student`, {
    params: { assignmentId, groupId },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getAssignmentSearch = (groupId, token, name) => {
  return httpClient.get(`assignment/search/${groupId}?name=${name}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// //! teacher //

export const getAssignmentGradesForTeacherPromise = (token, groupId) => {
  return httpClient.get(`assignment/grades/for-teacher/${groupId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getSingleAssignmentForTeacher = (assignmentId, groupId, token) => {
  return httpClient.get(
    `assignment/for-teacher/assignment/${assignmentId}/group/${groupId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const getSubmissions = (assignmentSubmissionId, token) => {
  return httpClient.get(`assignment/submission/${assignmentSubmissionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSubmission = (assignmentSubmissionId, token) => {
  return httpClient.get(`assignment/submission/${assignmentSubmissionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

/// ! post //

export const createAssignment = (token, assignmentData) => {
  return httpClient.post(`assignment/create`, assignmentData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

///! PUT //
export const updateAssignment = (assignmentId, token, assignmentData) => {
  return httpClient.put(`assignment/update/${assignmentId}`, assignmentData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//! delete
export const deleteAssignment = (assignmentId, token) => {
  return httpClient.delete(`assignment/delete/${assignmentId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//! grade post
export const assignmentEvaluation = (data, token) => {
  return httpClient.post("assignment/grade", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const submitAssignmentPromise = (data, token) => {
  return httpClient.post("assignment/submit", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateAssignmentSubmissionPromise = (
  assignmentSubmissionId,
  data,
  token
) => {
  return httpClient.put(
    `assignment/update-assignment-submission/${assignmentSubmissionId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
