import { Box, Container, Flex, HStack, Skeleton, Text } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import PageTitle from "../general/components/PageTitle";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getAttendanceForStudent } from "../services/attendanceService";
import { format } from "date-fns";
import ProfilePicture from "../general/components/ProfilePicture";
import { useTranslation } from "react-i18next";

function Attendance() {
  const { t } = useTranslation("global");
  const { token, selectedGroupId, name, surname, profilePictureKey } =
    useSelector((x) => x.security);

  const { data: resp, status } = useQuery(
    ["student-atendance", selectedGroupId, token],
    () => getAttendanceForStudent(selectedGroupId, token)
  );

  const formatDate = (dateString, dateFormat) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return `${t("Invalid Date")}`;
    }
    return format(date, dateFormat);
  };

  const getStatusText = (attendanceRecord) => {
    if (attendanceRecord == null) {
      return (
        <Text fontSize="12px" fontWeight="500">
          {t("You were not enrolled in the group")}
        </Text>
      );
    }
    switch (attendanceRecord?.attendanceType) {
      case 0:
        return (
          <Text fontSize="16px" fontWeight="500">
            P
          </Text>
        );
      case 1:
        return (
          <Text fontSize="16px" fontWeight="500">
            A
          </Text>
        );
      default:
        return (
          <Text fontSize="12px" fontWeight="500">
            {t("Not available yet")}
          </Text>
        );
    }
  };
  const studentName = `${name} ${surname}`;

  const scrollContainerRef = useRef(null); //for scrollLeft

  useEffect(() => {
    const handleWheel = (event) => {
      scrollContainerRef.current.scrollLeft += event.deltaY; //dobavaleyem vertical scroll=> peredelali / convertirovali  vertical scroll into horizontal scroll.
      scrollContainerRef.current.scrollLeft += event.deltaX;
      event.preventDefault();
    };

    const scrollContainer = scrollContainerRef.current; // access to the actual DOM element that the ref is attached to.

    scrollContainer.addEventListener("wheel", handleWheel);
    return () => {
      // cleanup function (When the component unmounts, we remove the event listener to clean up and prevent memory leaks.)
      if (scrollContainer) {
        scrollContainer.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          title={t("Attendance")}
          status={"success"}
          icon={faUserCheck}
          backPath={"/student/general"}
        />
        <Flex m="20px 0" justifyContent="space-between">
          <Box
            alignItems="start"
            padding="20px 0"
            h="540px"
            // w="25%"

            pr={["4px", "4px", "4px", "12px"]}
            border="2px solid transparent"
            borderRightColor={colors.primary}
          >
            <Skeleton isLoaded={status == "success"}>
              <HStack
                h="63px"
                mt={["2.9rem", "4.160rem", "4.160rem", "4.160rem", "4.160rem"]}
                gap={["0", "0", "0", "6px"]}
              >
                <Flex>
                  <ProfilePicture url={profilePictureKey} />
                </Flex>
                <Flex alignItems="center">
                  <Text
                    fontSize={["15px", "18px", "14px", "16px", "17px", "20px"]}
                    textAlign="start"
                    fontWeight="600"
                  >
                    {studentName}
                  </Text>
                  <Text
                    fontSize={["12px", "18px", "16px", "16px", "17px", "20px"]}
                    pl={["1px", "3px", "2px", "2px", "4px", "4px"]}
                    fontWeight="500"
                    color="gray"
                  >
                    {resp?.data?.attendancePercentage}%
                  </Text>
                </Flex>
              </HStack>
            </Skeleton>
          </Box>

          <Flex
            w={["100%", "100%", "70%", "70%", "75%"]}
            padding="0 8px 0 10px "
            gap="12px"
            sx={{
              "::-webkit-scrollbar-thumb": {
                borderRadius: "12px",
                background: colors.primary,
              },
              "::-webkit-scrollbar": {
                height: "2px",
              },
              "::-webkit-scrollbar-track": {
                width: "6px",
              },
              "@media (max-width: 570px)": {
                w: "70% !important",
              },
            }}
            ref={scrollContainerRef}
            overflowX="scroll"
            overflowY="hidden"
          >
            {resp?.data?.lessons?.map((item) => (
              <Flex
                flexDir="column"
                gap="24px"
                // minW="49%"
                minW={["100%", "49%", "49%", "32.2%"]}
                sx={{
                  "@media (max-width: 570px)": {
                    minW: "100%",
                  },
                }}
              >
                <Skeleton isLoaded={status == "success"}>
                  <Box
                    sx={{
                      boxShadow: "-8px 7px 6px 0px rgba(169,151,151,0.75)",
                    }}
                    color="white"
                    bg={colors.primary}
                    borderRadius="6px"
                    padding="12px"
                    h={["50px", "63px", "63px", "63px", "63px"]}
                    w="100%"
                    display="flex"
                    alignItems="center"
                  >
                    <Text
                      fontSize={["12px", "18px", "14px", "16px", "20px"]}
                      fontWeight="500"
                    >
                      {formatDate(item.lessonDate, "MMM dd, yyyy")}
                    </Text>
                  </Box>
                </Skeleton>
                <Skeleton isLoaded={status == "success"}>
                  <Box
                    display="flex"
                    alignItems="center"
                    bg="white"
                    color={"black"}
                    borderRadius="6px"
                    padding="12px"
                    h={["50px", "63px", "63px", "63px", "63px"]}
                    w="100%"
                    border={`1px solid ${colors.primary}`}
                    fontSize="20px"
                    fontWeight="500"
                  >
                    {getStatusText(item?.attendanceRecord)}{" "}
                  </Box>
                </Skeleton>
              </Flex>
            ))}

            <Box m="3rem 0" w="100%" textAlign="center">
              {status === "error" && (
                <>
                  <Text fontWeight="600" fontSize="24px" color="red">
                    {t("errMessages.message1")}
                  </Text>
                  <Text fontWeight="600" fontSize="24px" color="red">
                    {t("errMessages.message2")}
                  </Text>
                </>
              )}

              {status === "success" && resp?.data?.lessons?.length === 0 && (
                <Text
                  textAlign="center"
                  fontWeight="600"
                  color="red"
                  fontSize="24px"
                >
                  {t("No records.")}
                </Text>
              )}
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
}

export default Attendance;
