import { ChakraProvider } from "@chakra-ui/react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import SignIn from "./general/SignIn";
import DefaultRoute from "./helpers/DefaultRoute";
import ForgotPassword from "./general/ForgotPassword";
import NotFound from "./general/NotFound";
import SelectGroup from "./student/SelectGroup";
import StudentGeneral from "./student/StudentGeneral";
import Layout from "./general/layouts/Layout";
import Assignments from "./student/Assignments";
import SingleAssignment from "./student/SingleAssignment";
import Materials from "./student/Materials";
import SingleMaterial from "./student/SingleMaterial";
import Exams from "./student/Exams";
import SingleExam from "./student/SingleExam";
import ExamStart from "./student/ExamStart";
import ExamViewSubmission from "./student/ExamViewSubmission";
import Attendance from "./student/Attendance";
import Payroll from "./teacher/PayrollT";
import SelectGroupT from "./teacher/SelectGroupT";
import GeneralT from "./teacher/GeneralT";
import AssignmentsT from "./teacher/Assignments";
import AddAssignmentT from "./teacher/AddAssignmentT";
import AssignmentSubmission from "./teacher/AssignmentSubmission";
import MaterialsT from "./teacher/MaterialsT";
import AddMaterialT from "./teacher/AddMaterailT";
import MaterialSubmission from "./teacher/MaterialSubmission";
import ExamsT from "./teacher/ExamsT";
import AddExamT from "./teacher/AddExamT";
import CreateExam from "./teacher/CreateExam";
import ExamSubmissionsT from "./teacher/ExamSubmissionsT";
import AttendanceT from "./teacher/AttendanceT";
import AddPayrollT from "./teacher/AddPayrollT";
import AccountT from "./teacher/AccountT";
import Account from "./student/Account";
import GeneralM from "./manager/GeneralM";
import Groups from "./manager/Groups";
import SingleGroup from "./manager/SingleGroup";
import AddGroup from "./manager/AddGroup";
import EditGroup from "./manager/EditGroup";
import Students from "./manager/Students";
import AddStudent from "./manager/AddStudent";
import AccountM from "./manager/AccountM";
import Teachers from "./manager/Teachers";
import AddTeacher from "./manager/AddTeacher";
import PayrollM from "./manager/PayrollM";
import SinglePayrollM from "./manager/SinglePayrollM";
import StudentPayment from "./manager/StudentPayment";
import StudentPaymentDetails from "./manager/StudentPaymentDetails";
import ProtectedRoute from "./helpers/ProtectedRoute";
import { ROLE_MANAGER, ROLE_STUDENT, ROLE_TEACHER } from "./helpers/constants";
import useWhoAmI from "./hooks/useWhoAmI";
import SingleStudent from "./manager/SingleStudent";
import SingleTeacher from "./manager/SingleTeacher";
import EditAssignment from "./teacher/EditAssignment";
import EditMaterial from "./teacher/EditMaterial";
import SinglePayrollT from "./teacher/SinglePayrollT";
import EditExam from "./teacher/EditExam";
import ExamForSubmission from "./student/ExamForSubmission";
import StudentExamViewSubmission from "./teacher/StudentExamViewSubmission";
import LoginLink from "./general/LoginLink";
import TeachersS from "./student/TeachersS";
import SingleTeacherS from "./student/SingleTeacherS";
import StudentsT from "./teacher/StudentsT";
import SingleStudentT from "./teacher/SingleStudentT";
import StudentPaymentDetailsS from "./student/StudentPaymentDetailsS";
import SignleTeacherPayrollsM from "./manager/SignleTeacherPayrollsM";
import CoTeachers from "./teacher/CoTeachers";
import SingleCoTeacher from "./teacher/SingleCoTeacher";
import Grades from "./student/Grades";
import GradesT from "./teacher/GradesT";
import CoManagers from "./manager/CoManagers";
import SingleCoManager from "./manager/SingleCoManager";
import ManagersT from "./teacher/ManagersT";
import SingleManagerT from "./teacher/SingleManagerT";
import OverviewExam from "./teacher/OverviewExam";

function App() {
  useWhoAmI();
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route element={<Layout />}>
            {/* //! Student */}

            <Route
              path="/student/general"
              element={
                <ProtectedRoute role={ROLE_STUDENT}>
                  <StudentGeneral />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/group/student-payment"
              element={
                <ProtectedRoute role={ROLE_STUDENT}>
                  <StudentPaymentDetailsS />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/teachers"
              element={
                <ProtectedRoute role={ROLE_STUDENT}>
                  <TeachersS />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/students"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <StudentsT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/co-teachers"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <CoTeachers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/co-teachers/:id"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <SingleCoTeacher />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/students/:id"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <SingleStudentT />
                </ProtectedRoute>
              }
            />

            <Route
              path="/teacher/managers/:id"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <SingleManagerT />
                </ProtectedRoute>
              }
            />

            <Route
              path="/teacher/managers"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <ManagersT />
                </ProtectedRoute>
              }
            />

            <Route
              path="/student/teachers/:id"
              element={
                <ProtectedRoute role={ROLE_STUDENT}>
                  <SingleTeacherS />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/assignments"
              element={
                <ProtectedRoute role={ROLE_STUDENT}>
                  <Assignments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/assignments/:id"
              element={
                <ProtectedRoute role={ROLE_STUDENT}>
                  <SingleAssignment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/materials"
              element={
                <ProtectedRoute role={ROLE_STUDENT}>
                  <Materials />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/materials/:id"
              element={
                <ProtectedRoute role={ROLE_STUDENT}>
                  <SingleMaterial />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/exams"
              element={
                <ProtectedRoute role={ROLE_STUDENT}>
                  <Exams />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/exams/:id"
              element={
                <ProtectedRoute role={ROLE_STUDENT}>
                  <SingleExam />
                </ProtectedRoute>
              }
            />

            <Route
              path="/student/attendance"
              element={
                <ProtectedRoute role={ROLE_STUDENT}>
                  <Attendance />
                </ProtectedRoute>
              }
            />

            <Route
              path="/student/grades"
              element={
                <ProtectedRoute role={ROLE_STUDENT}>
                  <Grades />
                </ProtectedRoute>
              }
            />

            <Route
              path="/student/group/account"
              element={
                <ProtectedRoute role={ROLE_STUDENT}>
                  <Account />
                </ProtectedRoute>
              }
            />

            {/* //! Teacher */}
            <Route
              path="/teacher/general"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <GeneralT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/group/account"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <AccountT />
                </ProtectedRoute>
              }
            />

            <Route
              path="/teacher/assignments"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <AssignmentsT />
                </ProtectedRoute>
              }
            />

            <Route
              path="/teacher/assignments/add-assignment"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <AddAssignmentT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/assignments/:id"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <AssignmentSubmission />
                </ProtectedRoute>
              }
            />

            <Route
              path="/teacher/assignments/edit-assignment/:id"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <EditAssignment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/materials/edit-material/:id"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <EditMaterial />
                </ProtectedRoute>
              }
            />

            <Route
              path="/teacher/materials"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <MaterialsT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/materials/add-material"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <AddMaterialT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/materials/:id"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <MaterialSubmission />
                </ProtectedRoute>
              }
            />

            <Route
              path="/teacher/exams"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <ExamsT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/exams/add-exam"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <AddExamT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/exams/edit-exam/:id"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <EditExam />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/exams/:id"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <ExamSubmissionsT />
                </ProtectedRoute>
              }
            />

            <Route
              path="/teacher/attendance"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <AttendanceT />
                </ProtectedRoute>
              }
            />

            <Route
              path="/teacher/grades"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <GradesT />
                </ProtectedRoute>
              }
            />

            <Route
              path="/teacher/group/payroll"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <Payroll />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/group/payroll/:id"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <SinglePayrollT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher/group/payroll/add-payroll"
              element={
                <ProtectedRoute role={ROLE_TEACHER}>
                  <AddPayrollT />
                </ProtectedRoute>
              }
            />

            {/* //! Manager */}

            <Route
              path="/manager/general"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <GeneralM />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/account"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <AccountM />
                </ProtectedRoute>
              }
            />

            <Route
              path="/manager/groups"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <Groups />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/groups/:id"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <SingleGroup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/groups/add-group"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <AddGroup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/groups/edit-group/:id"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <EditGroup />
                </ProtectedRoute>
              }
            />

            <Route
              path="/manager/students"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <Students />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/students/add-student"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <AddStudent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/students/:id"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <SingleStudent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/teachers/:id"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <SingleTeacher />
                </ProtectedRoute>
              }
            />

            <Route
              path="/manager/teachers"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <Teachers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/teachers/add-teacher"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <AddTeacher />
                </ProtectedRoute>
              }
            />

            <Route
              path="/manager/co-managers"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <CoManagers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/co-managers/:id"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <SingleCoManager />
                </ProtectedRoute>
              }
            />

            <Route
              path="/manager/payroll"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <PayrollM />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/payroll/teacher/:id"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <SignleTeacherPayrollsM />
                </ProtectedRoute>
              }
            />
            // problem
            <Route
              path="/manager/payroll/:id"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <SinglePayrollM />
                </ProtectedRoute>
              }
            />

            <Route
              path="/manager/student-payment"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <StudentPayment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manager/student-payment/:id"
              element={
                <ProtectedRoute role={ROLE_MANAGER}>
                  <StudentPaymentDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/student/student-payment"
            element={
              <ProtectedRoute role={ROLE_STUDENT}>
                <StudentPaymentDetailsS hasLayout={false} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/teacher/payroll"
            element={
              <ProtectedRoute role={ROLE_TEACHER}>
                <Payroll hasLayout={false} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/teacher/payroll/:id"
            element={
              <ProtectedRoute role={ROLE_TEACHER}>
                <SinglePayrollT hasLayout={false} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/teacher/payroll/add-payroll"
            element={
              <ProtectedRoute role={ROLE_TEACHER}>
                <AddPayrollT hasLayout={false} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/student/account"
            element={
              <ProtectedRoute role={ROLE_STUDENT}>
                <Account hasLayout={false} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/teacher/account"
            element={
              <ProtectedRoute role={ROLE_TEACHER}>
                <AccountT hasLayout={false} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/teacher/select-group"
            element={
              <ProtectedRoute role={ROLE_TEACHER}>
                <SelectGroupT />
              </ProtectedRoute>
            }
          />

          <Route
            path="/teacher/exams/create-exam/:id"
            element={
              <ProtectedRoute role={ROLE_TEACHER}>
                <CreateExam />
              </ProtectedRoute>
            }
          />

          <Route
            path="/teacher/exams/overview/:id"
            element={
              <ProtectedRoute role={ROLE_TEACHER}>
                <OverviewExam />
              </ProtectedRoute>
            }
          />

          <Route
            path="/student/select-group"
            element={
              <ProtectedRoute role={ROLE_STUDENT}>
                <SelectGroup />
              </ProtectedRoute>
            }
          />
          <Route
            path="/student/exams/exam-view-submission/:id"
            element={
              <ProtectedRoute role={ROLE_STUDENT}>
                <ExamViewSubmission />
              </ProtectedRoute>
            }
          />

          <Route
            path="/teacher/exams/exam-view-submission/:id"
            element={
              <ProtectedRoute role={ROLE_TEACHER}>
                <StudentExamViewSubmission />
              </ProtectedRoute>
            }
          />

          <Route
            path="/student/exams/exam-for-submission/:id"
            element={
              <ProtectedRoute role={ROLE_STUDENT}>
                <ExamForSubmission />
              </ProtectedRoute>
            }
          />
          <Route path="/sign-in" element={<SignIn />} />

          <Route path="/login-link" element={<LoginLink />} />

          <Route path="/" element={<DefaultRoute />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
