import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { colors } from "../Constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getExamForSubmissionPromise,
  submitExamPromise,
} from "../services/examService";
import { useFormik } from "formik";
import Countdown from "react-countdown";
import QuestionForSubmission from "./QuestionForSubmission";
import { useTranslation } from "react-i18next";

function ExamForSubmission() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { token } = useSelector((x) => x.security) || {};
  const [submissionError, setSubmissionError] = useState(null);
  const [requiredQuestions, setRequiredQuestions] = useState([]);
  const [initialSubmittedQuestions, setInitialSubmittedQuestions] = useState(
    []
  );
  const navigate = useNavigate();
  const { data: resp, status } = useQuery(
    ["student-exam-for-submission", token, id],
    () => getExamForSubmissionPromise(id, token),
    {
      onSuccess: (resp) => {
        if (initialSubmittedQuestions.length != 0) return;
        setRequiredQuestions(
          resp?.data?.questions?.filter((x) => x.isRequired)
        );
        setInitialSubmittedQuestions(
          resp?.data?.questions?.map((x) => ({
            questionId: x.id,
            submittedOptions: [],
          }))
        );
      },
    }
  );

  const { mutate, isLoading } = useMutation(
    (data) => submitExamPromise(data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["student-single-exam"],
        });
        navigate(`/student/exams/${id}`);
      },
    }
  );

  const validateSubmittedQuestions = (submittedQuestions) => {
    if (requiredQuestions.length == 0) return true;

    return requiredQuestions.every((x) =>
      submittedQuestions.some((y) => {
        let isValid = y.questionId == x.id && y.submittedOptions?.length > 0;
        if (x.questionType == 2 && isValid) {
          isValid = y.submittedOptions[0].submissionText.trim() != "";
        }
        return isValid;
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      examId: id,
      submittedQuestions: initialSubmittedQuestions,
    },
    onSubmit: (values) => {
      if (validateSubmittedQuestions(values.submittedQuestions)) {
        mutate(values);
        return;
      }
      setSubmissionError("Answer the required questions!");
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (submissionError != null) {
      if (validateSubmittedQuestions(formik.values.submittedQuestions)) {
        setSubmissionError(null);
      }
    }
  }, [formik.values.submittedQuestions]);

  const handleTextOptionChange = (e) => {
    let text = e.target.value;
    let questionId = e.target.getAttribute("questionId");
    let submittedQuestions = formik.values.submittedQuestions;

    let submittedQuestion = submittedQuestions.find(
      (x) => x.questionId == questionId
    );

    submittedQuestion.submittedOptions = [{ submissionText: text }];
    formik.setFieldValue("submittedQuestions", [...submittedQuestions]);
  };

  const handleMultipleChoiceOptionChange = (optionId, questionId) => {
    let submittedQuestions = formik.values.submittedQuestions;
    let submittedQuestion = submittedQuestions.find(
      (x) => x.questionId == questionId
    );

    submittedQuestion.submittedOptions = [{ optionId: parseInt(optionId) }];
    formik.setFieldValue("submittedQuestions", [...submittedQuestions]);
  };

  const handleCheckboxOptionChange = (e, questionId) => {
    let isChecked = e.target.checked;
    let optionId = e.target.value;

    let submittedQuestions = formik.values.submittedQuestions;
    let submittedQuestion = submittedQuestions.find(
      (x) => x.questionId == questionId
    );

    if (isChecked) {
      submittedQuestion.submittedOptions = [
        ...(submittedQuestion.submittedOptions ?? []),
        { optionId: parseInt(optionId) },
      ];
    } else {
      submittedQuestion.submittedOptions =
        submittedQuestion.submittedOptions.filter(
          (x) => x.optionId != optionId
        );
    }
    formik.setFieldValue("submittedQuestions", [...submittedQuestions]);
  };

  const getRemainingMs = () => {
    let startTime = new Date(resp?.data?.startTime);
    let endTime = new Date(resp?.data?.startTime);
    endTime.setSeconds(startTime.getSeconds() + resp?.data?.durationSeconds);
    return endTime.getTime() - new Date().getTime();
  };



  const timerRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed)
      return (
        <Text fontSize={["12px", "16px", "20px", "18px", "20px"]} color={"red"}>
          {t("Time is over!")}
        </Text>
      );
    return (
      <Text>
        {String(hours).padStart(2, "0")} : {String(minutes).padStart(2, "0")} :{" "}
        {String(seconds).padStart(2, "0")}
      </Text>
    );
  };


  const { t } = useTranslation("global");
  return (
    <Box position="relative" bg={colors.lightGreen} minH="100vh">
      <Box
        margin="0 auto"
        p={["1rem 0", "1rem 0", "1rem 0", "4rem 0"]}
        w={["94%", "98%", "80%", "70%", "50%"]}
        sx={{
          "@media (max-width: 992px)": {
            display: "flex",
            flexDirection: "column-reverse",
            position: "static",
          },
        }}
      >
        <VStack alignItems="start" gap="20px">
          <Skeleton w="100%" isLoaded={status == "success"}>
            <Box
              borderRadius="8px"
              border="1px solid transparent"
              borderBottomColor={colors.primary}
              // w="708px"
              w="100%"
              bg="white"
              padding={["12px 10px", "12px", "12px 18px", "12px 18px"]}
            >
              <Text
                fontSize={["20px", "20px", "24px", "32px", "36px"]}
                color={colors.primary}
                fontWeight="600"
              >
                {resp?.data?.name}
              </Text>
            </Box>
          </Skeleton>

          {resp?.data?.questions?.map((x, i) => {
            i++;
            return (
              <QuestionForSubmission
                handleCheckboxOptionChange={handleCheckboxOptionChange}
                handleMultipleChoiceOptionChange={
                  handleMultipleChoiceOptionChange
                }
                handleTextOptionChange={handleTextOptionChange}
                question={x}
                index={i}
                optionId={
                  formik.values.submittedQuestions.find(
                    (y) => y.questionId == x.id
                  )?.submittedOptions[0]?.optionId
                }
              />
            );
          })}

          <Button
            gap="16px"
            _hover={{ bg: "#2a6b68" }}
            color={"white"}
            bg={colors.primary}
            border="1px solid "
            w="160px"
            onClick={formik.handleSubmit}
            isLoading={isLoading}
          >
            <Text>{t("Submit")}</Text>
          </Button>
          {submissionError && (
            <Text m="0" color="red">
              {submissionError}
            </Text>
          )}
        </VStack>
        {resp?.data?.durationSeconds ? (
          <Flex
            mb="20px"
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDir="column-reverse"
          >
            <Flex
              position="absolute"
              top="14"
              right={["28", "28", "28", "4", "32"]}
              // w={["94%", "98%", "80%", "70%", "50%"]}
              fontWeight="400"
              fontSize="20px"
              justifyContent="center"
              alignItems="center"
              w={["100px", "120px", "140px", "120px", "160px"]}
              h={["100px", "120px", "140px", "120px", "160px"]}
              bg="white"
              border={`8px solid ${colors.primary}`}
              borderRadius="50%"
              sx={{
                "@media (max-width: 992px)": {
                  position: "static",
                },
              }}
            >
              <Countdown
                date={Date.now() + getRemainingMs()}
                renderer={timerRenderer}
              />
            </Flex>
            <Text
              position="absolute"
              top="5"
              right={["28", "28", "28", "10", "44"]}
              fontSize="18px"
              fontWeight="600"
              sx={{
                "@media (max-width: 992px)": {
                  position: "static",
                },
              }}
            >
              {t("Time left")}
            </Text>
          </Flex>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}

export default ExamForSubmission;
