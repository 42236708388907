import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import React from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faMoneyBillTransfer,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { createPayroll } from "../services/payrollService";
import { useFormik } from "formik";
import {
  addPayrollSchema,
  getAddPayrollSchema,
} from "./validations/addPayrollSchema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function AddPayrollT({ hasLayout = true }) {
  const { t } = useTranslation("global");
  const validationSchema = getAddPayrollSchema(t);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { token } = useSelector((x) => x.security) || {};

  const { mutate, isLoading } = useMutation(
    (data) => createPayroll(data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-payrolls"],
        });
        navigate(`/teacher${hasLayout ? "/group" : ""}/payroll`, {
          state: { fromAddPayroll: true },
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      periodStartDate: null,
      periodEndDate: null,
      hoursWorked: null,
      salary: null,
      currency: null,
      description: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const handleReturnToSelectGroup = () => {
    navigate("/teacher/payroll");
  };

  return (
    <Flex>
      {!hasLayout ? null : (
        <Box width="25%" display={["none", "none", "block", "block"]}></Box>
      )}
      <Container
        maxW={["100%", "100%", "75%", "75%"]}
        margin={!hasLayout ? "1rem auto 0" : "0rem auto .5rem "}
      >
        <PageTitle
          status={"success"}
          title={t("Add Payroll")}
          icon={faMoneyBillTransfer}
          backPath={hasLayout ? "/teacher/group/payroll" : "/teacher/payroll"}
        />
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w={["96%", "94%", "86%", "80%", "75%", "62%"]}
          margin="8px auto 40px"
          p="20px 0"
          gap="24px"
          bg="#EAF5F4"
        >
          <FormControl w="94%">
            <FormLabel
              color={"#00000061"}
              fontWeight="700"
              fontSize={["10px", "12px", "14px"]}
            >
              {t("Start period")}
            </FormLabel>
            <Input
              fontSize={["10px", "12px", "14px"]}
              name="periodStartDate"
              value={formik.values.periodStartDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="date"
              placeholder={t("Date created")}
              _placeholder={{
                color: "#666666",
                fontSize: ["12px", "14px", "16px", "16px"],
              }}
            />
            {formik.errors.periodStartDate &&
              formik.touched.periodStartDate && (
                <Text
                  fontSize={["10px", "12px", "14px"]}
                  p="0"
                  pl="4px"
                  m="0"
                  color="red"
                >
                  {formik.errors.periodStartDate}
                </Text>
              )}
          </FormControl>

          <FormControl w="94%">
            <FormLabel
              color={"#00000061"}
              fontWeight="700"
              fontSize={["10px", "12px", "14px"]}
            >
              {t("End period")}
            </FormLabel>
            <Input
              fontSize={["10px", "12px", "14px"]}
              name="periodEndDate"
              value={formik.values.periodEndDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="date"
              placeholder={t("End date")}
              _placeholder={{
                color: "#666666",
                fontSize: ["12px", "14px", "16px", "16px"],
              }}
            />
            {formik.errors.periodEndDate && formik.touched.periodEndDate && (
              <Text
                fontSize={["10px", "12px", "14px"]}
                p="0"
                pl="4px"
                m="0"
                color="red"
              >
                {formik.errors.periodEndDate}
              </Text>
            )}
          </FormControl>
          <FormControl w="94%">
            <FormLabel
              color={"#00000061"}
              fontWeight="700"
              fontSize={["10px", "12px", "14px"]}
            >
              {t("Number of hours worked")}
            </FormLabel>
            <Input
              fontSize={["10px", "12px", "14px"]}
              name="hoursWorked"
              value={formik.values.hoursWorked}
              onChange={formik.handleChange}
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="number"
              placeholder={t("Number of hours worked")}
              _placeholder={{
                color: "#666666",
                fontSize: ["12px", "14px", "16px", "16px"],
              }}
            />
          </FormControl>
          <FormControl w="94%">
            <FormLabel
              color={"#00000061"}
              fontWeight="700"
              fontSize={["10px", "12px", "14px"]}
            >
              {t("Salary")}
            </FormLabel>
            <Input
              fontSize={["10px", "12px", "14px"]}
              name="salary"
              value={formik.values.salary}
              onChange={formik.handleChange}
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="number"
              placeholder={t("Salary")}
              _placeholder={{
                color: "#666666",
                fontSize: ["12px", "14px", "16px", "16px"],
              }}
            />
          </FormControl>
          <FormControl w="94%">
            <FormLabel
              color={"#00000061"}
              fontWeight="700"
              fontSize={["10px", "12px", "14px"]}
            >
              {t("Currency")}
            </FormLabel>
            <Select
              name="currency"
              value={formik.values.currency}
              onChange={formik.handleChange}
              bg="white"
              borderRadius="4px"
              // p="0 12px"
              placeholder={t("Select Currency")}
              _placeholder={{
                color: "#666666",
                fontSize: ["12px", "14px", "16px", "16px"],
              }}
            >
              <option value="AZN">AZN</option>
              <option value="EURO">EURO</option>
              <option value="USD">USD</option>
            </Select>
          </FormControl>

          <FormControl w="94%">
            <FormLabel
              color={"#00000061"}
              fontWeight="700"
              fontSize={["10px", "12px", "14px"]}
            >
              {t("Description")}
            </FormLabel>
            <Input
              fontSize={["10px", "12px", "14px"]}
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="text"
              placeholder={t("Description")}
              _placeholder={{
                color: "#666666",
                fontSize: ["12px", "14px", "16px", "16px"],
              }}
            />
          </FormControl>
          <Button
            bg={colors.primary}
            color="white"
            w="200px"
            h="40px"
            fontWeight="600"
            fontSize="16px"
            _hover={{ bg: "#2a6b68" }}
            onClick={formik.handleSubmit}
            isDisabled={isLoading || !formik.isValid}
          >
            {isLoading ? `${t("Submitting...")}` : `${t("Submit")}`}
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
}

export default AddPayrollT;
