import JoditEditor from "jodit-react";
import React, { useRef } from "react";
import { Text, Spinner } from "@chakra-ui/react";
import { colors } from "../Constants";

export default React.memo(function MyJoditEditor({
  isReadOnly = false,
  content,
  onBlur,
  isInline = false,
  isSaving,
}) {
  const config = {
    readonly: isReadOnly,
    uploader: {
      insertImageAsBase64URI: true,
      url: "",
    },
    defaultMode: "1",
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    maxWidth: null,
    buttons: `bold,italic,underline,strikethrough,ul,ol,font,fontsize,paragraph,lineHeight,superscript,subscript,classSpan${
      isReadOnly ? "" : ",image"
    },spellcheck,speechRecognize,cut,copy`,
    inline: isInline,
    toolbarInlineForSelection: true,
    showPlaceholder: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
  };
  const ref = useRef(null);

  return (
    <>
      <JoditEditor
        ref={ref}
        value={content}
        config={config}
        onChange={() => {}}
        tabIndex={1}
        onBlur={onBlur}
      />
      {isSaving && (
        <Text
          alignItems={"center"}
          display={"flex"}
          fontWeight={"bold"}
          fontSize={"12px"}
          color={colors.primary}
        >
          Saving <Spinner ms={"5px"} size={"xs"} />
        </Text>
      )}
    </>
  );
});
