import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  Progress,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  faCheck,
  faClipboardList,
  faTriangleExclamation,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { colors } from "../Constants";
import { getGroupStatisticsPromise } from "../services/statisticsService";
import { useTranslation } from "react-i18next";

const GeneralCardsT = () => {
  const { t } = useTranslation("global");
  const { token, selectedGroupId } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(
    ["general-statistics", selectedGroupId, token],
    () => getGroupStatisticsPromise(selectedGroupId, token)
  );
  return (
    <>
      <Flex
        // border="1px solid yellow"
        justifyContent="space-between"
        flexWrap="wrap"
        // gap="24px"
        gap={{ base: "12px", md: "24px" }}
        w={`calc((100% / 3 * 2) - 16px)`}
        sx={{
          "@media (max-width: 992px)": {
            w: `calc(71% - 8px )`,
          },
          "@media (max-width: 768px)": {
            w: "100%",
          },
        }}
      >
        <Card
          w={{
            base: "calc((100% / 1))",
            sm: "calc((100% / 2) - 12px)",
            md: "calc((100% / 1) - 12px)",
            lg: "calc((100% / 2) - 12px)",
          }}
          bg={colors.lightGreen}
        >
          <Skeleton h="100%" isLoaded={status != "loading"}>
            <CardHeader
              sx={{
                "@media (max-width: 650px)": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: "12px",
                },
                "@media (max-width: 500px)": {
                  py: "8px",
                },
              }}
            >
              <Flex
                width="60px"
                h="60px"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bg="white"
                sx={{
                  "@media (max-width: 890px)": {
                    fontSize: "20px",
                    boxSize: "50px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                    boxSize: "40px",
                  },
                }}
              >
                <FontAwesomeIcon
                  color={colors.primary}
                  fontSize="24px"
                  icon={faClipboardList}
                />
              </Flex>
            </CardHeader>
            <CardBody
              py={"0"}
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "8px",
                },
              }}
            >
              <Heading
                fontSize="20px"
                sx={{
                  "@media (max-width: 650px)": {
                    textAlign: "center",
                    my: "12px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                  },
                  "@media (max-width: 530px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 430px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 375px)": {
                    fontSize: "12px",
                  },
                }}
              >
                {t("Average assignment grade")}
              </Heading>
            </CardBody>
            <CardFooter
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "4px",
                },
                "@media (max-width: 650px)": {
                  paddingY: "8px",
                },
              }}
            >
              <Flex
                padding="0 12px"
                sx={{
                  "@media (max-width: 750px)": {
                    paddingX: "4px",
                  },
                }}
                h="48px"
                bg="white"
                borderRadius="8px"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Progress
                  colorScheme="teal"
                  w="68%"
                  borderRadius="20px"
                  h="16px"
                  sx={{
                    "@media (max-width: 600px)": {
                      h: "12px",
                    },
                    "@media (max-width: 500px)": {
                      h: "12px",
                    },
                  }}
                  value={resp?.data?.averageAssignmentsGrade}
                />
                <Text
                  sx={{
                    "@media (max-width: 890px)": {
                      fontSize: "12px",
                    },
                    "@media (max-width: 760px)": {
                      fontSize: "14px",
                    },
                    "@media (max-width: 700px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  {" "}
                  {resp?.data?.averageAssignmentsGrade}
                  /100%
                </Text>
              </Flex>
            </CardFooter>
          </Skeleton>
        </Card>
        <Card
          w={{
            base: "calc((100% / 1))",
            sm: "calc((100% / 2) - 12px)",
            md: "calc((100% / 1) - 12px)",
            lg: "calc((100% / 2) - 12px)",
          }}
          bg={colors.lightGreen}
        >
          <Skeleton h="100%" isLoaded={status != "loading"}>
            <CardHeader
              sx={{
                "@media (max-width: 650px)": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: "12px",
                },
                "@media (max-width: 500px)": {
                  py: "8px",
                },
              }}
            >
              <Flex
                width="60px"
                h="60px"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bg="white"
                sx={{
                  "@media (max-width: 890px)": {
                    fontSize: "20px",
                    boxSize: "50px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                    boxSize: "40px",
                  },
                }}
              >
                <FontAwesomeIcon
                  color={colors.primary}
                  fontSize="24px"
                  icon={faClipboardList}
                />
              </Flex>
            </CardHeader>
            <CardBody
              py={"0"}
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "8px",
                },
              }}
            >
              <Heading
                fontSize="20px"
                sx={{
                  "@media (max-width: 650px)": {
                    textAlign: "center",
                    my: "12px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                  },
                  "@media (max-width: 530px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 430px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 375px)": {
                    fontSize: "12px",
                  },
                }}
              >
                {t("Average exam grade")}
              </Heading>
            </CardBody>
            <CardFooter
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "4px",
                },
                "@media (max-width: 650px)": {
                  paddingY: "8px",
                },
              }}
            >
              <Flex
                padding="0 12px"
                sx={{
                  "@media (max-width: 750px)": {
                    paddingX: "4px",
                  },
                }}
                h="48px"
                bg="white"
                borderRadius="8px"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Progress
                  colorScheme="teal"
                  w="68%"
                  borderRadius="20px"
                  h="16px"
                  sx={{
                    "@media (max-width: 600px)": {
                      h: "12px",
                    },
                    "@media (max-width: 500px)": {
                      h: "12px",
                    },
                  }}
                  value={resp?.data?.averageExamsGrade}
                />
                <Text
                  sx={{
                    "@media (max-width: 890px)": {
                      fontSize: "12px",
                    },
                    "@media (max-width: 760px)": {
                      fontSize: "14px",
                    },
                    "@media (max-width: 700px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  {resp?.data?.averageExamsGrade}
                  /100%
                </Text>
              </Flex>
            </CardFooter>
          </Skeleton>
        </Card>
        <Card
          w={{
            base: "calc((100% / 1))",
            sm: "calc((100% / 2) - 12px)",
            md: "calc((100% / 1) - 12px)",
            lg: "calc((100% / 2) - 12px)",
          }}
          bg={colors.lightGreen}
        >
          <Skeleton h="100%" isLoaded={status != "loading"}>
            <CardHeader
              sx={{
                "@media (max-width: 650px)": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: "12px",
                },
                "@media (max-width: 500px)": {
                  py: "8px",
                },
              }}
            >
              <Flex
                width="54px"
                h="54px"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bg="white"
                sx={{
                  "@media (max-width: 890px)": {
                    fontSize: "20px",
                    boxSize: "50px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                    boxSize: "40px",
                  },
                }}
              >
                <FontAwesomeIcon
                  color={colors.primary}
                  fontSize="24px"
                  icon={faUserCheck}
                />
              </Flex>
            </CardHeader>
            <CardBody
              py={"0"}
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "8px",
                },
              }}
            >
              <Heading
                fontSize="20px"
                sx={{
                  "@media (max-width: 650px)": {
                    textAlign: "center",
                    my: "12px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                  },
                  "@media (max-width: 530px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 430px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 375px)": {
                    fontSize: "12px",
                  },
                }}
              >
                {t("Average attendance")}
              </Heading>
            </CardBody>
            <CardFooter
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "4px",
                },
                "@media (max-width: 650px)": {
                  paddingY: "8px",
                },
              }}
            >
              <Flex
                padding="0 12px"
                sx={{
                  "@media (max-width: 750px)": {
                    paddingX: "4px",
                  },
                }}
                h="48px"
                bg="white"
                borderRadius="8px"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Progress
                  colorScheme="teal"
                  w="68%"
                  borderRadius="20px"
                  h="16px"
                  sx={{
                    "@media (max-width: 600px)": {
                      h: "12px",
                    },
                    "@media (max-width: 500px)": {
                      h: "12px",
                    },
                  }}
                  value={resp?.data?.averageAttendancePercentage}
                />
                <Text
                  sx={{
                    "@media (max-width: 890px)": {
                      fontSize: "12px",
                    },
                    "@media (max-width: 760px)": {
                      fontSize: "14px",
                    },
                    "@media (max-width: 700px)": {
                      fontSize: "12px",
                    },
                  }}
                  as="span"
                  fontWeight="bold"
                >
                  {resp?.data?.averageAttendancePercentage}
                  /100%
                </Text>
              </Flex>
            </CardFooter>
          </Skeleton>
        </Card>

        <Card
          w={{
            base: "calc((100% / 1))",
            sm: "calc((100% / 2) - 12px)",
            md: "calc((100% / 1) - 12px)",
            lg: "calc((100% / 2) - 12px)",
          }}
          bg={colors.lightGreen}
        >
          <Skeleton h="100%" isLoaded={status != "loading"}>
            <CardHeader
              sx={{
                "@media (max-width: 650px)": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: "12px",
                },
                "@media (max-width: 500px)": {
                  py: "8px",
                },
              }}
            >
              <Flex
                width="60px"
                h="60px"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bg="white"
                sx={{
                  "@media (max-width: 890px)": {
                    fontSize: "20px",
                    boxSize: "50px",
                  },
                  "@media (max-width: 700px)": {
                    fontSize: "16px",
                    boxSize: "40px",
                  },
                }}
              >
                <FontAwesomeIcon
                  color={colors.primary}
                  fontSize="24px"
                  icon={faClipboardList}
                />
              </Flex>
            </CardHeader>
            <CardBody
              py={"0"}
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "8px",
                },
              }}
            >
              <Heading
                fontSize="20px"
                sx={{
                  "@media (max-width: 650px)": {
                    textAlign: "center",
                    my: "12px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                  },
                  "@media (max-width: 530px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 430px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 375px)": {
                    fontSize: "12px",
                  },
                }}
              >
                {t("Missed deadlines")}
              </Heading>
            </CardBody>
            <CardFooter
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "4px",
                },
                "@media (max-width: 650px)": {
                  paddingY: "8px",
                },
              }}
            >
              <Flex
                padding="0 12px"
                sx={{
                  "@media (max-width: 750px)": {
                    paddingX: "4px",
                  },
                }}
                h="48px"
                bg="white"
                borderRadius="8px"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                {resp?.data?.numberOfMissedDeadlines ? (
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    w="92%"
                    borderRadius="8px"
                  >
                    {t("Missed deadlines this month")}:
                    <Text ml="8px" as="span" fontWeight="bold">
                      {resp?.data?.numberOfMissedDeadlines}
                    </Text>
                  </Text>
                ) : (
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    w="92%"
                    borderRadius="8px"
                  >
                    {t("No missed deadlines this month!")}
                  </Text>
                )}
                <FontAwesomeIcon
                  icon={
                    resp?.data?.numberOfMissedDeadlines
                      ? faTriangleExclamation
                      : faCheck
                  }
                  color={
                    resp?.data?.numberOfMissedDeadlines ? "#F5CC3A" : "#04C600"
                  }
                />{" "}
              </Flex>
            </CardFooter>
          </Skeleton>
        </Card>
      </Flex>
    </>
  );
};

export default GeneralCardsT;
