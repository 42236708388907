import { httpClient } from "../helpers/httpClient";

export const getMaterials = (groupId, token, skip, take) => {
  return httpClient.get(`material/group/${groupId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      skip,
      take,
    },
  });
};

export const getMaterialSearch = (groupId, token, name) => {
  return httpClient.get(`material/search/${groupId}?name=${name}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//! student //

export const getSingleMaterialForStudent = (materialId, token) => {
  return httpClient.get(`material/for-student/${materialId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const materailComply = (materialId, groupId, token) => {
  return httpClient.get(`material/comply`, {
    params: {
      materialId,
      groupId,
    },
    headers: { Authorization: `Bearer ${token}` },
  });
};

// //! teacher //
export const getMaterialsForTeacher = (materialId, token) => {
  return httpClient.get(`material/for-teacher/${materialId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addMaterial = (token, data) => {
  return httpClient.post(`material/create`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateMaterial = (materialId, token, data) => {
  return httpClient.put(`material/update/${materialId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//! delete
export const deleteMaterial = (materialId, token) => {
  return httpClient.delete(`material/delete/${materialId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
