import { Flex, HStack, Progress, Text } from "@chakra-ui/react";
import {
  faPaperclip,
  faRotate,
  faRotateBack,
  faRotateRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { colors } from "../../Constants";
import { s3Client } from "../../helpers/s3Client";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

export default function NewFile({ file, onRemoveFunc, onUploadFunc }) {
  const { t } = useTranslation("global");
  const initialStatus = {
    progress: 0,
    completed: false,
    failed: false,
    uploadId: null,
    key: `${uuidv4()}_${file.name}`,
  };
  const [status, setStatus] = useState(initialStatus);

  const onCompletedFn = () => {
    setStatus((prev) => ({ ...prev, completed: true, failed: false }));
    onUploadFunc(status.key);
  };
  const onErrFn = () => {
    setStatus((prev) => ({
      ...prev,
      failed: true,
      completed: false,
      progress: 0,
    }));
  };
  const onProgressFn = (progress) => {
    setStatus((prev) => ({ ...prev, progress: progress }));
  };
  const uploadFile = () => {
    s3Client
      .uploadMultipartFile(
        onCompletedFn,
        onErrFn,
        onProgressFn,
        status.key,
        file
      )
      .then((uploadId) =>
        setStatus((prev) => ({ ...prev, uploadId: uploadId }))
      )
      .catch((err) => onErrFn());
  };

  const retryUpload = () => {
    setStatus(initialStatus);
    uploadFile();
  };

  const abort = () => {
    if (status.uploadId == null || status.completed || status.failed) return;
    s3Client.abortMultipartUpload(status.uploadId, status.key).catch((e) => e);
  };

  useEffect(() => {
    uploadFile();
  }, []);

  return (
    <Flex
      p={"10px"}
      w="220px"
      height={"max-content"}
      bg={"white"}
      gap="12px"
      borderRadius={"12px"}
      direction={"column"}
      border="2px solid #D9D9D975"
      _hover={{ cursor: "pointer" }}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <HStack gap="12px">
          <FontAwesomeIcon
            color={colors.gray}
            fontSize="18px"
            icon={faPaperclip}
          />
          <Text
            whiteSpace={"nowrap"}
            maxW={"130px"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            fontSize={["14px", "12px", "16px"]}
            onClick={() => window.open(URL.createObjectURL(file), "_blank")}
            _hover={{ color: "purple", textDecoration: "underline" }}
          >
            {file.name}
          </Text>
        </HStack>
        <FontAwesomeIcon
          onClick={() => {
            abort();
            onRemoveFunc();
          }}
          color="red"
          fontSize="20px"
          icon={faXmark}
        />
      </Flex>
      {!status.failed && !(status.progress == 100 && status.completed) && (
        <Progress
          borderRadius={"12px"}
          colorScheme="green"
          size="sm"
          transitionDuration={"150ms"}
          value={status.progress}
        />
      )}

      {status.failed && (
        <Flex alignItems="center" justifyContent="space-between">
          <Text
            maxW={"130px"}
            fontWeight={"500"}
            color={"gray"}
            fontSize={["14px", "12px", "16px"]}
          >
            {t("Please try again!")}
          </Text>

          <FontAwesomeIcon
            onClick={retryUpload}
            color="gray"
            fontSize="16px"
            icon={faRotateBack}
          />
        </Flex>
      )}
    </Flex>
  );
}
