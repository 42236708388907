import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faEye,
  faEyeSlash,
  faPaste,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { createStudentPromise } from "../services/securityService";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAddStudentPaymentSchema } from "./validations/addStudentPaymentSchema";
import { getAddStudentSchema } from "./validations/addStudentSchema";

function AddStudent() {
  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const navigate = useNavigate();
  const { token, institutionId } = useSelector((x) => x.security) || {};
  const { mutate, isLoading } = useMutation(
    (data) => createStudentPromise(institutionId, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["student-institution"],
        });
        navigate("/manager/students");
      },
    }
  );

  const { t } = useTranslation("global");
  const validationSchema = getAddStudentSchema(t);

  const formik = useFormik({
    initialValues: {
      name: null,
      surname: null,
      username: null,
      email: null,
      phone: null,
      password: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <Box>
          <PageTitle
            status={"success"}
            title={t("sidebar.students")}
            icon={faPaste}
            backPath={"/manager/students"}
          />
        </Box>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="100%"
          margin="0 auto 3rem"
          p="2.5rem 0"
          gap="24px"
          bg="#EAF5F4"
          borderRadius="13px"
        >
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.name")}
            </FormLabel>
            <Input
              fontSize={["10px", "10px", "12px", "14px", "16px"]}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="text"
              placeholder={t("table.name")}
            />
            {formik.errors.name && formik.touched.name && (
              <Text p="0" pl="4px" m="0" color="red">
                {formik.errors.name}
              </Text>
            )}
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.surname")}
            </FormLabel>
            <Input
              fontSize={["10px", "10px", "12px", "14px", "16px"]}
              name="surname"
              value={formik.values.surname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="text"
              placeholder={t("table.surname")}
            />
            {formik.errors.surname && formik.touched.surname && (
              <Text p="0" pl="4px" m="0" color="red">
                {formik.errors.surname}
              </Text>
            )}
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.username")}
            </FormLabel>
            <Input
              fontSize={["10px", "10px", "12px", "14px", "16px"]}
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="text"
              placeholder={t("table.username")}
            />
            {formik.errors.username && formik.touched.username && (
              <Text p="0" pl="4px" m="0" color="red">
                {formik.errors.username}
              </Text>
            )}
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.email")}
            </FormLabel>
            <Input
              fontSize={["10px", "10px", "12px", "14px", "16px"]}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="email"
              placeholder={t("table.email")}
            />
            {formik.errors.email && formik.touched.email && (
              <Text p="0" pl="4px" m="0" color="red">
                {formik.errors.email}
              </Text>
            )}
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.phone")}
            </FormLabel>
            <Input
              fontSize={["10px", "10px", "12px", "14px", "16px"]}
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="text"
              placeholder={t("Number")}
            />
            {formik.errors.phone && formik.touched.phone && (
              <Text p="0" pl="4px" m="0" color="red">
                {formik.errors.phone}
              </Text>
            )}
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.password")}
            </FormLabel>
            <InputGroup>
              <Input
                fontSize={["10px", "10px", "12px", "14px", "16px"]}
                sx={{
                  "&[type='password']": {
                    color: "teal",
                  },
                }}
                bg="white"
                borderRadius="4px"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={t("Password")}
                p="0 12px"
                type={show ? "text" : "password"}
              />

              <InputRightElement top="50%" transform="translate(-50%, -50%);">
                <FontAwesomeIcon
                  color="teal"
                  onClick={handleClick}
                  icon={show ? faEye : faEyeSlash}
                />
              </InputRightElement>
            </InputGroup>
            {formik.errors.password && formik.touched.password && (
              <Text p="0" pl="4px" m="0" color="red">
                {formik.errors.password}
              </Text>
            )}
          </FormControl>
          <Button
            bg={colors.primary}
            color="white"
            isLoading={isLoading}
            onClick={formik.handleSubmit}
            fontWeight="600"
            w={["100px", "70px", "80px", "110px", "160px"]}
            h={["24px", "28px", "30px", "40px"]}
            fontSize={["10px", "10px", "12px", "14px", "16px"]}
            _hover={{ bg: "#2a6b68" }}
          >
            {t("Add")}
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
}

export default AddStudent;
