import { httpClient } from "../helpers/httpClient";

//! student //

export const getAttendanceForStudent = (groupId, token) => {
  return httpClient.get(`attendance/get-attendance-for-student/${groupId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//! TEACHER
export const createLesson = (data, token) => {
  return httpClient.post("attendance/create-lesson", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAttendanceForTeacher = (groupId, token) => {
  return httpClient.get(`attendance/get-attendance-for-teacher/${groupId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const markAttendance = (attendanceRecordId, data, token) => {
  return httpClient.post(
    `attendance/mark-attendance/${attendanceRecordId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

//! delete
export const deleteLesson = (lessonId, token) => {
  return httpClient.delete(`attendance/delete-lesson/${lessonId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
