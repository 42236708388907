import { httpClient } from "../helpers/httpClient";

export const getStudentStatisticsPromise = (groupId, token) => {
  return httpClient.get(`statistics/student/${groupId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getGroupStatisticsPromise = (groupId, token) => {
  return httpClient.get(`statistics/${groupId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getInstitutionStatisticsPromise = (institutionId, token) => {
  return httpClient.get(`statistics/institution/${institutionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};