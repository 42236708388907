import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteStudent,
  getStudentByIdPromise,
  updateStudentPromise,
} from "../services/studentService";
import { useFormik } from "formik";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useToast,
} from "@chakra-ui/react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faEye,
  faEyeSlash,
  faPaste,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../Constants";
import AddStudentToGroupModal from "./modal/AddStudentToGroupModal";
import { useTranslation } from "react-i18next";
import { editStudentSchema, getEditStudentSchema } from "./validations/editStudentSchema";
import { removeStudentFromGroup } from "../services/groupService";
import RemoveModal from "./modal/ConfirmationModalM";
import ConfirmationModal from "../teacher/modal/ConfirmationModalT";
import ProfilePicture from "../general/components/ProfilePicture";
import { format } from "date-fns";

export default function SingleStudent() {
  const { t } = useTranslation("global");
  const validationSchema = getEditStudentSchema(t);

  const { id } = useParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const { token } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(["single-student", token, id], () =>
    getStudentByIdPromise(id, token)
  );
  const { mutate, isLoading } = useMutation(
    (data) => updateStudentPromise(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["student-institution"],
        });
        navigate("/manager/students");
      },
      onError: (error) => {
        const errorMessage = error?.response?.data?.Message; 
        let translatedMessage;
        if (errorMessage) {
          if (errorMessage.includes("This email is already taken")) {
            translatedMessage = t("editCredentials.email");
          } else if (errorMessage.includes("This phone number is already taken")) {
            translatedMessage = t("editCredentials.phoneNumber");
          } else if (errorMessage.includes("This username is already taken")) {
            translatedMessage = t("editCredentials.username");
          }
        }
        if (translatedMessage) {
          toast({
            position: "top-right",
            title: t("Error"),
            description: translatedMessage,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }

    }
  );

  const formik = useFormik({
    initialValues: {
      name: resp?.data?.name,
      surname: resp?.data?.surname,
      username: resp?.data?.username,
      email: resp?.data?.email,
      phone: resp?.data?.phone,
      password: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate(values);
    },
    enableReinitialize: true,
  });

  const navigate = useNavigate();

  //remove student from gr
  const removeMutate = useMutation(
    ({ groupStudentId, saveHistory }) =>
      removeStudentFromGroup(groupStudentId, token, saveHistory),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["single-student"],
        });
      },
    }
  );

  const handleRemoveStudent = (groupStudentId, saveHistory) => {
    removeMutate.mutate({ groupStudentId, saveHistory });
  };

  // delete student
  const { mutate: deleteStudentMutate, isLoading: isDeleting } = useMutation(
    () => deleteStudent(id, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["student-institution"],
        });
        navigate("/manager/students");
      },
    }
  );

  const handleDeleteConfirm = () => {
    deleteStudentMutate();
  };

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <Box>
          <PageTitle
            status={"success"}
            title={t("Single Student")}
            icon={faPaste}
            backPath={'/manager/students'}
          />
        </Box>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="100%"
          margin="0 auto 3rem"
          p="2.5rem 0"
          gap="24px"
          bg="#EAF5F4"
          borderRadius="13px"
        >
          <ProfilePicture
            sizePx={["100px", "130px", "140px", "160px", "170px"]}
            url={resp?.data?.profilePictureKey}
          />
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.name")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                fontSize={["10px", "10px", "12px", "14px", "16px"]}
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="text"
                placeholder={t("table.name")}
              />
              {formik.errors.name && formik.touched.name && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.name}
                </Text>
              )}
            </Skeleton>
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.surname")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                fontSize={["10px", "10px", "12px", "14px", "16px"]}
                name="surname"
                value={formik.values.surname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="text"
                placeholder={t("table.surname")}
              />
              {formik.errors.surname && formik.touched.surname && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.surname}
                </Text>
              )}
            </Skeleton>
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.username")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                fontSize={["10px", "10px", "12px", "14px", "16px"]}
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="text"
                placeholder={t("table.username")}
              />
              {formik.errors.username && formik.touched.username && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.username}
                </Text>
              )}
            </Skeleton>
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.email")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                fontSize={["10px", "10px", "12px", "14px", "16px"]}
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="email"
                placeholder={t("table.email")}
              />
              {formik.errors.email && formik.touched.email && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.email}
                </Text>
              )}
            </Skeleton>
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.phone")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                fontSize={["10px", "10px", "12px", "14px", "16px"]}
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="text"
                placeholder={t("table.phone")}
              />
              {formik.errors.phone && formik.touched.phone && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.phone}
                </Text>
              )}
            </Skeleton>
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Joined date")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                fontSize={["10px", "10px", "12px", "14px", "16px"]}
                isDisabled
                value={format(
                  resp?.data?.createdTime ?? new Date(),
                  "dd MMM yyyy"
                )}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="text"
              />
            </Skeleton>
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.password")}
            </FormLabel>
            <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
              {t(
                "To reset the password enter the new password in the field below and press on save."
              )}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <InputGroup>
                <Input
                  fontSize={["10px", "10px", "12px", "14px", "16px"]}
                  sx={{
                    "&[type='password']": {
                      color: "teal",
                    },
                  }}
                  bg="white"
                  borderRadius="4px"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={t("table.password")}
                  p="0 12px"
                  type={show ? "text" : "password"}
                />
                <InputRightElement top="50%" transform="translate(-50%, -50%);">
                  <FontAwesomeIcon
                    color="teal"
                    onClick={handleClick}
                    icon={show ? faEye : faEyeSlash}
                  />
                </InputRightElement>
              </InputGroup>
              {formik.errors.password && formik.touched.password && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.password}
                </Text>
              )}
            </Skeleton>
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.groups")}
            </FormLabel>

            <HStack wrap="wrap" spacing={4}>
              {status == "loading" &&
                Array.from({ length: 3 }).map(() => {
                  return (
                    <Skeleton>
                      <Tag
                        size={"md"}
                        width={"70px"}
                        variant="solid"
                        colorScheme="green"
                      ></Tag>
                    </Skeleton>
                  );
                })}
              {status == "success" &&
                resp?.data?.groups?.map((x) => {
                  return (
                    <Tag size={"md"} variant="solid" colorScheme="green">
                      <TagLabel>{x.groupName}</TagLabel>
                      <RemoveModal
                        icon={faXmark}
                        buttonWidth="0"
                        buttonH="0"
                        text=""
                        onConfirm={handleRemoveStudent}
                        isLoading={isLoading}
                        groupStudentId={x.groupStudentId}
                      />
                    </Tag>
                  );
                })}
              {status != "loading" && (
                <AddStudentToGroupModal
                  studentId={id}
                  currentGroups={resp?.data?.groups}
                />
              )}
            </HStack>
          </FormControl>
          <ButtonGroup w="100%">
            <HStack margin="0 auto" mt="1rem">
              <Button
                bg={colors.primary}
                color="white"
                isLoading={isLoading}
                onClick={formik.handleSubmit}
                isDisabled={status != "success" || !formik.dirty}
                fontWeight="600"
                w={["100px", "70px", "80px", "110px", "160px"]}
                h={["24px", "28px", "30px", "40px"]}
                fontSize={["10px", "10px", "12px", "14px", "16px"]}
                _hover={{ bg: "#2a6b68" }}
              >
                {t("save")}
              </Button>
              <ConfirmationModal
                text={t("Delete student")}
                buttonWidth={["100px", "130px", "140px", "160px", "160px"]}
                buttonH={["24px", "28px", "30px", "40px"]}
                fontSize={["10px", "10px", "12px", "14px", "16px"]}
                isLoading={isDeleting}
                onConfirm={handleDeleteConfirm}
              />
            </HStack>
          </ButtonGroup>
        </Flex>
      </Container>
    </Flex>
  );
}
