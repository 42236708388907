import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import useSmartRedirection from "../hooks/useSmartRedirection";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setSecurityInfo } from "../redux/slices/securitySlice";
import { Box, Flex } from "@chakra-ui/react";

function LoginLink() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    dispatch(setSecurityInfo(token));

  }, [dispatch, navigate, token]);

  useSmartRedirection();
  return (
    <Flex justifyContent="center" alignItems="center"  fontSize="32px" fontWeight="600" w="100%" height="100vh" margin="0 auto" textAlign="center" bg="gray">
    Redirecting ...
  </Flex>
  );
}

export default LoginLink;
