import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  List,
  ListItem,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faCopy,
  faCreditCard,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import StudentpaymentModal from "./modal/StudentpaymentModal";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getGroupsOfStudentForManagerPromise } from "../services/studentService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteStudentPayment,
  getStudentPaymentsPromise,
} from "../services/studentPaymentService";
import { format } from "date-fns";
import PaymentReminderCard from "./cards/PaymentReminderCard";
import { useTranslation } from "react-i18next";
import ProfilePicture from "../general/components/ProfilePicture";
import ConfirmationModal from "../teacher/modal/ConfirmationModalT";
import SimpleBar from "simplebar-react";

export default function StudentPaymentDetails() {
  const { id } = useParams();
  const { token } = useSelector((x) => x.security);
  const [groupStudentId, setGroupStudentId] = useState(null);
  const { data: groupsResp, status: groupsSatus } = useQuery(
    ["groups-of-student", token, id],
    () => getGroupsOfStudentForManagerPromise(id, token),
    {
      onSuccess: (resp) => {
        !groupStudentId &&
          setGroupStudentId(resp?.data?.groups[0]?.groupStudentId);
      },
    }
  );
  const { data: studentPaymentsResp, status: studentPaymentsStatus } = useQuery(
    ["student-payments", token, groupStudentId],
    () => getStudentPaymentsPromise(groupStudentId, token),
    { enabled: groupStudentId != null }
  );

  const queryClient = useQueryClient();

  const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(
    (studentPaymentId) => deleteStudentPayment(studentPaymentId, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["student-payments"],
        });
      },
    }
  );

  const handleDeleteConfirm = (studentPaymentId) => {
    deleteMutate(studentPaymentId);
  };
  const { t } = useTranslation("global");

  const [isLessThan610px] = useMediaQuery("(max-width: 680px)");

  console.log(studentPaymentsResp);

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          status={"success"}
          title={t("sidebar.studentPayments")}
          icon={faCreditCard}
          backPath={"/manager/student-payment"}
        />
        <HStack alignItems="center" justifyContent="space-between">
          <Box>
            <Skeleton alignItems={"center"} isLoaded={groupsSatus == "success"}>
              <Text
                display={"flex"}
                alignItems={"center"}
                fontWeight="500"
                fontSize={["8px", "12px", "14px", "14px", "18px", "32px"]}
              >
                <ProfilePicture url={groupsResp?.data?.profilePictureKey} />
                {`${groupsResp?.data?.name} ${groupsResp?.data?.surname} (${groupsResp?.data?.username})`}
              </Text>
            </Skeleton>
          </Box>
          {groupsResp?.data?.groups?.length > 0 && (
            <StudentpaymentModal
              setGroupStudentId={setGroupStudentId}
              groups={groupsResp?.data?.groups}
            />
          )}
        </HStack>
        <Divider
          my={[".5rem", "1rem", "1rem"]}
          borderColor="teal"
          borderWidth="2px"
        />
        <Box top={0} textAlign="start">
          {groupsSatus === "error" && (
            <>
              <Text fontWeight="600" fontSize="20px" color="red">
                {t("errMessages.message1")}
              </Text>
            </>
          )}

          {groupsSatus === "success" && groupsResp?.data?.length === 0 && (
            <Text>{t("errMessages.message2")}</Text>
          )}
        </Box>
        <HStack wrap="wrap" gap="20px">
          {groupsSatus == "success" &&
            groupsResp?.data?.groups.map((x) => (
              <HStack
                transition=".3s"
                borderRadius="6px"
                onClick={() => setGroupStudentId(x.groupStudentId)}
                minW={["16%"]}
                padding={'10px'}
                height={["32px", "48px", "40px", "48px"]}
                border={x.groupStudentId == groupStudentId && "2px solid teal"}
                fontWeight="500"
                fontSize={["10px", "14px", "16px", "18px"]}
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                cursor="default"
                bg={"#EAF5F4"}
              >
                <Text>{x.groupName}</Text>
              </HStack>
            ))}
          {groupsSatus == "loading" &&
            Array.from({ length: 3 }).map(() => (
              <Skeleton>
                <HStack
                  transition=".3s"
                  borderRadius="6px"
                  w={["16%"]}
                  height={["20px", "48px", "40px", "48px"]}
                  fontWeight="500"
                  fontSize={["10px", "14px", "16px", "18px"]}
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  bg={"#EAF5F4"}
                ></HStack>
              </Skeleton>
            ))}
        </HStack>

        {groupsResp?.data?.groups?.length > 0 && (
          <PaymentReminderCard groupStudentId={groupStudentId} />
        )}
        {groupsResp?.data?.length !== 0 &&
          groupsResp?.data?.groups?.length !== 0 && (
            <Box w="100%">
              {isLessThan610px ? (
                <SimpleBar
                  autoHide={false}
                  style={{ maxHeight: "calc(100vh - 100px)" }}
                >
                  <Box
                    sx={{
                      "@media (max-width: 680px)": {
                        minW: "710px",
                      },
                    }}
                  >
                    <HStack
                      m="3.5rem 0 1rem"
                      mt={["1rem", "2rem", "3rem", "3.5rem"]}
                      padding="14px"
                      bg="#EAF5F4"
                      fontSize={["12px", "12px", "12px", "12px", "16px"]}
                      justifyContent="space-between"
                      h={["20px", "40px", "60px"]}
                      color={colors.primary}
                    >
                      <Text textAlign="start" w="19%" as="i">
                        {t("table.paymentDate")}
                      </Text>
                      <Text textAlign="center" w="19%" as="i">
                        {t("table.amountOfPayment")}
                      </Text>
                      <Text textAlign="center" w="19%" as="i">
                        {t("table.currency")}
                      </Text>
                      <Text textAlign="center" w="19%" as="i">
                        {t("table.startDatePeriod")}
                      </Text>
                      <Text textAlign="center" w="19%" as="i">
                        {t("table.endDatePeriod")}
                      </Text>
                      <Text textAlign="end" w="5%" as="i"></Text>
                    </HStack>

                    <List mb={["1rem", "1.5rem", "2rem"]} w="100%">
                      {studentPaymentsStatus == "loading" &&
                        Array.from({ length: 2 }).map(() => {
                          return (
                            <Skeleton>
                              <ListItem
                                cursor="default"
                                fontSize={[
                                  "12px",
                                  "12px",
                                  "8px",
                                  "12px",
                                  "16px",
                                ]}
                                fontWeight="400"
                                w="100%"
                                padding="20px 14px"
                              ></ListItem>
                              <Divider
                                w="100%"
                                mb="16px"
                                borderColor={"gray.300"}
                                borderWidth="1px"
                              />
                            </Skeleton>
                          );
                        })}

                      {studentPaymentsStatus == "success" &&
                        studentPaymentsResp?.data?.map((x) => {
                          return (
                            <>
                              <ListItem
                                cursor="default"
                                fontSize={["12px", "12px", "12px", "16px"]}
                                fontWeight="400"
                                w="100%"
                                padding="4px 14px"
                                px={[
                                  "12px",
                                  "12px",
                                  "8px",
                                  "10px",
                                  "12px",
                                  "16px",
                                ]}
                              >
                                <Flex justifyContent="space-between">
                                  <Text textAlign="start" w="19%">
                                    {format(x.paymentDate, "dd MMM yyyy")}
                                  </Text>
                                  <Text textAlign="center" w="19%">
                                    {x.amount}
                                  </Text>
                                  <Text textAlign="center" w="19%">
                                    {x.currency}
                                  </Text>
                                  <Text textAlign="center" w="19%">
                                    {format(x.periodStartDate, "dd MMM yyyy")}
                                  </Text>
                                  <Text textAlign="center" w="19%">
                                    {format(x.periodEndDate, "dd MMM yyyy")}
                                  </Text>

                                  <Text textAlign="end" w="5%">
                                    <ConfirmationModal
                                      icon={faXmark}
                                      iconFontSize={"20px"}
                                      buttonWidth={"0"}
                                      buttonH={"0"}
                                      iconColor="#e61919"
                                      text=""
                                      isLoading={isDeleting}
                                      onConfirm={() =>
                                        handleDeleteConfirm(x.id)
                                      }
                                    />
                                  </Text>
                                </Flex>
                              </ListItem>
                              <Divider
                                w="100%"
                                mb="16px"
                                borderColor={"gray.300"}
                                borderWidth="1px"
                              />
                            </>
                          );
                        })}
                      {studentPaymentsStatus == "error" && (
                        <Box m="28px 0" textAlign="center">
                          <Text fontWeight="600" fontSize="24px" color="red">
                            {t("errMessages.message1")}{" "}
                          </Text>
                          <Text fontWeight="600" fontSize="24px" color="red">
                            {t("errMessages.message2")}
                          </Text>
                        </Box>
                      )}

                      {studentPaymentsStatus === "success" &&
                        studentPaymentsResp?.data?.length === 0 && (
                          <Box m="28px 0" textAlign="center">
                            <Text
                              fontWeight="600"
                              fontSize="24px"
                              color={"gray"}
                            >
                              {t("No payment data for this group.")}
                            </Text>
                          </Box>
                        )}
                    </List>
                  </Box>
                </SimpleBar>
              ) : (
                <Box>
                  <HStack
                    m="3.5rem 0 1rem"
                    mt={["1rem", "2rem", "3rem", "3.5rem"]}
                    padding="14px"
                    bg="#EAF5F4"
                    fontSize={["12px", "12px", "12px", "12px", "16px"]}
                    justifyContent="space-between"
                    h={["20px", "40px", "60px"]}
                    color={colors.primary}
                  >
                    <Text textAlign="start" w="19%" as="i">
                      {t("table.paymentDate")}
                    </Text>
                    <Text textAlign="center" w="19%" as="i">
                      {t("table.amountOfPayment")}
                    </Text>
                    <Text textAlign="center" w="19%" as="i">
                      {t("table.currency")}
                    </Text>
                    <Text textAlign="center" w="19%" as="i">
                      {t("table.startDatePeriod")}
                    </Text>
                    <Text textAlign="center" w="19%" as="i">
                      {t("table.endDatePeriod")}
                    </Text>
                    <Text textAlign="end" w="5%" as="i"></Text>
                  </HStack>

                  <List mb={["1rem", "1.5rem", "2rem"]} w="100%">
                    {studentPaymentsStatus == "loading" &&
                      Array.from({ length: 2 }).map(() => {
                        return (
                          <Skeleton>
                            <ListItem
                              cursor="default"
                              fontSize={["12px", "12px", "8px", "12px", "16px"]}
                              fontWeight="400"
                              w="100%"
                              padding="20px 14px"
                            ></ListItem>
                            <Divider
                              w="100%"
                              mb="16px"
                              borderColor={"gray.300"}
                              borderWidth="1px"
                            />
                          </Skeleton>
                        );
                      })}

                    {studentPaymentsStatus == "success" &&
                      studentPaymentsResp?.data?.map((x) => {
                        return (
                          <>
                            <ListItem
                              onClick={() => console.log(x)}
                              cursor="default"
                              fontSize={["12px", "12px", "12px", "16px"]}
                              fontWeight="400"
                              w="100%"
                              padding="4px 14px"
                              px={[
                                "12px",
                                "12px",
                                "8px",
                                "10px",
                                "12px",
                                "16px",
                              ]}
                            >
                              <Flex justifyContent="space-between">
                                <Text textAlign="start" w="19%">
                                  {format(x.paymentDate, "dd MMM yyyy")}
                                </Text>
                                <Text textAlign="center" w="19%">
                                  {x.amount}
                                </Text>
                                <Text textAlign="center" w="19%">
                                  {x.currency}
                                </Text>
                                <Text textAlign="center" w="19%">
                                  {format(x.periodStartDate, "dd MMM yyyy")}
                                </Text>
                                <Text textAlign="center" w="19%">
                                  {format(x.periodEndDate, "dd MMM yyyy")}
                                </Text>
                                <Text textAlign="end" w="5%">
                                  <ConfirmationModal
                                    icon={faXmark}
                                    iconFontSize={"20px"}
                                    buttonWidth={"0"}
                                    buttonH={"0"}
                                    iconColor="#e61919"
                                    text=""
                                    isLoading={isDeleting}
                                    onConfirm={() => handleDeleteConfirm(x.id)}
                                  />
                                </Text>
                              </Flex>
                            </ListItem>
                            <Divider
                              w="100%"
                              mb="16px"
                              borderColor={"gray.300"}
                              borderWidth="1px"
                            />
                          </>
                        );
                      })}
                    {studentPaymentsStatus == "error" && (
                      <Box m="28px 0" textAlign="center">
                        <Text fontWeight="600" fontSize="24px" color="red">
                          {t("errMessages.message1")}{" "}
                        </Text>
                        <Text fontWeight="600" fontSize="24px" color="red">
                          {t("errMessages.message2")}
                        </Text>
                      </Box>
                    )}

                    {studentPaymentsStatus === "success" &&
                      studentPaymentsResp?.data?.length === 0 && (
                        <Box m="28px 0" textAlign="center">
                          <Text fontWeight="600" fontSize="24px" color={"gray"}>
                            {t("No payment data for this group.")}
                          </Text>
                        </Box>
                      )}
                  </List>
                </Box>
              )}
            </Box>
          )}

        {groupsResp?.data?.groups?.length == 0 && groupsSatus == "success" && (
          <Box m="28px 0" textAlign="center">
            <Text fontWeight="600" fontSize="24px" color={"gray"}>
              {t("No payment data for this student.")}
            </Text>
          </Box>
        )}
      </Container>
    </Flex>
  );
}
