import { httpClient } from "../helpers/httpClient";

export const myGroupsPromise = (token) => {
  return httpClient.get("group/my-groups", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getGroupsForManagerPromise = (institutionId, token) => {
  return httpClient.get(`group/institution/${institutionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getGroupDetailsPromise = (groupId, token) => {
  return httpClient.get(`group/details/${groupId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createGroupPromise = (data, token) => {
  return httpClient.post("group/create", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateGroupPromise = (groupId, data, token) => {
  return httpClient.put(`group/update/${groupId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addStudentToGroupPromise = (groupId, data, token) => {
  return httpClient.post(`group/add-student-to-group/${groupId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addTeacherToGroupPromise = (groupId, data, token) => {
  return httpClient.post(`group/add-teacher-to-group/${groupId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//! remove
//remove student
export const removeStudentFromGroup = (groupStudentId, token, saveHistory) => {
  return httpClient.delete(
    `group/remove-student-from-group/${groupStudentId}?saveHistory=${saveHistory}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

//remove teacher
export const removeTeacherFromGroup = (groupTeacherId, token) => {
  return httpClient.delete(
    `group/remove-teacher-from-group/${groupTeacherId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

//! delete

export const deleteGroup = (groupId, token) => {
  return httpClient.delete(`group/delete/${groupId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
