import React, { useEffect, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  HStack,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Skeleton,
  Text,
  VStack,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { colors } from "../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReadOnlyFile from "../general/components/ReadOnlyFile";
import {
  faClipboardList,
  faCopy,
  faEdit,
  faPaperclip,
  faSquareFull,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  assignmentEvaluation,
  deleteAssignment,
  getSingleAssignmentForTeacher,
  getSubmission,
} from "../services/assignmentService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format } from "date-fns";
import { s3Client } from "../helpers/s3Client";
import { useFormik } from "formik";
import {
  getSubmissionGradeSchema,
  submissionGradeSchema,
} from "./validations/submissionGradeSchema";
import ConfirmationModal from "./modal/ConfirmationModalT";
import InnerHtml from "../helpers/InnerHtml";
import ProfilePicture from "../general/components/ProfilePicture";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

function AssignmentSubmission() {
  const { t } = useTranslation("global");
  const validationSchema = getSubmissionGradeSchema(t);
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const { selectedGroupId, token } = useSelector((x) => x.security);
  const { id } = useParams();

  const { data: resp, status } = useQuery(
    ["teacher-single-assignment", id],
    () => getSingleAssignmentForTeacher(id, selectedGroupId, token)
  );
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (data) => assignmentEvaluation(data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-single-assignment"],
        });
        onClose();
      },
    }
  );

  const { data: submissionResp, isFetching } = useQuery(
    ["assignment-submission", selectedSubmission?.id],
    () => getSubmission(selectedSubmission?.id, token),
    {
      enabled: selectedSubmission?.id != null && isOpen,
      onSuccess: (resp)=>{
        formik.setFieldValue('grade', resp?.data?.grade || "")
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      grade: submissionResp?.data?.grade || null,
      assignmentSubmissionId: selectedSubmission?.id,
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      mutate(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
    }
  }, [isOpen]);

  const formatDate = (dateString, dateFormat) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return `${t("Invalid Date")}`;
    }
    return format(date, dateFormat);
  };

  const navigate = useNavigate();

  const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(
    () => deleteAssignment(id, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-single-assignment"],
        });
        navigate("/teacher/assignments");
      },
    }
  );

  const handleDeleteConfirm = () => {
    deleteMutate();
  };

  const getSubmissionColor = (deadline, submissionTime, isHover = false) => {
    return deadline == null
      ? isHover
        ? "#2a6b68"
        : colors.primary
      : new Date(submissionTime) <= new Date(deadline)
      ? isHover
        ? "#2a6b68"
        : colors.primary
      : isHover
      ? "darkred"
      : "red";
  };

  const [isLessThan610px] = useMediaQuery("(max-width: 680px)");

  return (
    console.log(formik.values),
    
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          title={resp?.data?.name}
          status={status}
          icon={faClipboardList}
          backPath={
            location.state
              ? `/teacher/assignments?take=${location.state.take}`
              : `/teacher/assignments`
          }
        />

        <HStack mt="8px" justifyContent="space-between">
          <Skeleton minW="40%" maxW="80%" isLoaded={status == "success"}>
            <Flex
              display={"flex"}
              alignItems={"center"}
              fontSize={["8px", "12px", "14px", "14px", "18px", "22px"]}
              fontWeight="600"
            >
              <ProfilePicture url={resp?.data?.teacherProfilePictureKey} />
              <HStack flexWrap="wrap">
                <Text whiteSpace="nowrap">
                  {`${resp?.data?.teacherName} ${resp?.data?.teacherSurname} (${resp?.data?.teacherUsername})`}
                  {" | "}
                </Text>
                <Text pl="4px" as="span">
                  {`${t("Posted")} - ${format(
                    resp?.data?.createdTime ?? new Date(),
                    "MMM do yyy"
                  )}`}
                </Text>
              </HStack>
            </Flex>
          </Skeleton>

          <Flex gap={["14px", "20px", "20px"]}>
            <Button
              onClick={() =>
                navigate(`/teacher/assignments/edit-assignment/${id}`)
              }
              fontSize={["10px", "10px", "12px", "14px", "16px"]}
              gap="16px"
              _hover={{ bg: "#2a6b68" }}
              color={"white"}
              bg={colors.primary}
              border="1px solid "
              w={["60px", "70px", "80px", "110px", "160px"]}
              h={["24px", "28px", "30px", "40px"]}
            >
              <FontAwesomeIcon icon={faEdit} /> <Text>{t("Edit")}</Text>
            </Button>

            <ConfirmationModal
              buttonWidth={["60px", "70px", "80px", "110px", "160px"]}
              buttonH={["24px", "28px", "30px", "40px"]}
              fontSize={["10px", "10px", "12px", "14px", "16px"]}
              isLoading={isDeleting}
              onConfirm={handleDeleteConfirm}
            />
          </Flex>
        </HStack>
        <Divider mt="20px" borderColor="teal" borderWidth="2px" />
        <Skeleton mb="20px" minH="8vh" isLoaded={status == "success"}>
          <Box m="20px 0" lineHeight="22px">
            <Text
              color="#00000099"
              // w="50%"
            >
              {resp?.data?.description ? (
                <InnerHtml fileKey={resp?.data?.description} />
              ) : (
                <Text
                  fontWeight="600"
                  w="100%"
                  textAlign="center"
                  m="2rem auto"
                >
                  {t("No description")}
                </Text>
              )}
            </Text>
          </Box>

          <Box m="2rem 0 4rem">
            <HStack flexWrap="wrap" gap="24px">
              {resp?.data?.fileAttachments?.map((x, index) => {
                return <ReadOnlyFile key={index} fileKey={x.key} />;
              })}
            </HStack>
          </Box>
        </Skeleton>

        <Box
          w={["100%", "100%", "90%", "55%"]}
          bg={colors.lightGreen}
          padding="24px 20px"
          px={["8px", "12", "16px", "20px"]}
          m="2rem 0 2rem"
        >
          <Skeleton isLoaded={status == "success"}>
            <Text
              fontSize={["12px", "14px", "16px", "20px", "24px"]}
              fontWeight="600"
            >
              {t("Assignment details")}
            </Text>
            <VStack w="100%" margin="20px 0" alignItems="start" gap="40px">
              <HStack
                gap="8px"
                w="100%"
                fontWeight="600"
                justifyContent="space-between"
                fontSize={["10px", "12px", "16px"]}
              >
                <Text> {t("Deadline")}</Text>
                <Text>
                  {resp?.data?.deadline
                    ? formatDate(resp?.data?.deadline, "HH:mm, MMM do, yyy")
                    : `${t("No deadline")}`}
                </Text>
              </HStack>
              <HStack
                gap="8px"
                w="100%"
                fontWeight="600"
                justifyContent="space-between"
                fontSize={["10px", "12px", "16px"]}
              >
                <Text> {t("Graded")}</Text>
                <Text>
                  {resp?.data?.isGraded ? `${t("Yes")}` : `${t("No")}`}
                </Text>
              </HStack>
              <HStack
                gap="8px"
                w="100%"
                fontWeight="600"
                justifyContent="space-between"
                fontSize={["10px", "12px", "16px"]}
              >
                <Text> {t("Allow submission edit")}</Text>
                <Text>
                  {resp?.data?.allowSubmissionEdit
                    ? `${t("Yes")}`
                    : `${t("No")}`}
                </Text>
              </HStack>
            </VStack>
          </Skeleton>
        </Box>

        <HStack
          border="1px solid #EAF5F4"
          borderRadius="6px"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          gap="12px"
          width="163px"
          height="48px"
          fontWeight="500"
          fontSize={["14px", "16px", "18px"]}
          bg="#EAF5F4"
        >
          <FontAwesomeIcon icon={faCopy} />
          <Text>{t("Submissions")}</Text>
        </HStack>

        <Box mb="28px" w="100%">
          {isLessThan610px ? (
            <SimpleBar
              autoHide={false}
              style={{ maxHeight: "calc(100vh - 100px)" }}
            >
              <Box
                sx={{
                  "@media (max-width: 610px)": {
                    minW: "710px",
                  },
                }}
              >
                <HStack
                  m="2rem 0"
                  mt={["1rem", "2rem"]}
                  padding="14px"
                  bg="#EAF5F4"
                  fontSize={["12px", "12px", "12px", "12px", "16px"]}
                  justifyContent="space-between"
                  h={["20px", "40px", "60px"]}
                  color={colors.primary}
                >
                  <Text textAlign="start" w="33%" as="i">
                    {t("Name")}
                  </Text>
                  {resp?.data?.isGraded && (
                    <Text textAlign="center" w="33%" as="i">
                      {t("Grade")}
                    </Text>
                  )}
                  <Text textAlign="end" w="33%" as="i">
                    {t("Submission")}
                  </Text>
                </HStack>

                <List mb={["1rem", "1.5rem", "2rem"]} w="100%">
                  {status === "loading" && (
                    <>
                      {Array.from({ length: 3 })?.map((_, index) => (
                        <Skeleton m="12px 0" key={index}>
                          <ListItem
                            fontSize={["12px", "12px", "8px", "12px", "16px"]}
                            padding="0 14px"
                          >
                            <Flex alignItems="center">
                              <Text
                                fontSize={[
                                  "12px",
                                  "12px",
                                  "8px",
                                  "12px",
                                  "16px",
                                ]}
                                fontWeight="500"
                                w="100%"
                              ></Text>
                              <Button mb="8px" w="180px"></Button>
                            </Flex>
                          </ListItem>
                        </Skeleton>
                      ))}
                    </>
                  )}

                  {resp?.data?.submissions?.map((x) => (
                    <React.Fragment key={x.id}>
                      <ListItem
                        fontSize={["12px", "12px", "12px", "16px"]}
                        fontWeight="400"
                        w="100%"
                        // padding="4px 14px"
                        px={["12px", "12px", "8px", "10px", "12px", "16px"]}
                        key={x.id}
                      >
                        <Flex
                          justifyContent={"space-between"}
                          alignItems="center"
                        >
                          <Text
                            display={"flex"}
                            alignItems={"center"}
                            fontSize={["10px", "12px", "16px"]}
                            fontWeight="500"
                            w="33%"
                            textAlign="start"
                          >
                            <ProfilePicture url={x.studentProfilePictureKey} />
                            {`${x.studentName} ${x.studentSurname} (${x.studentUsername})`}
                          </Text>
                          {resp?.data?.isGraded &&
                            (x.grade != null ? (
                              <Text w="33%" textAlign="center" as="i">
                                {x.grade}%
                              </Text>
                            ) : (
                              <Text w="33%" textAlign="center" as="i">
                                {t("Not available")}
                              </Text>
                            ))}

                          <Text textAlign="end" w="33%">
                            {x.submissionTime ? (
                              <Button
                                mb="8px"
                                minW={["140px", "180px", "190px"]}
                                _hover={{
                                  bg: getSubmissionColor(
                                    resp?.data?.deadline,
                                    x.submissionTime,
                                    true
                                  ),
                                }}
                                borderRadius="4px"
                                bg={getSubmissionColor(
                                  resp?.data?.deadline,
                                  x.submissionTime
                                )}
                                border="1px solid"
                                padding="6px"
                                fontSize={["10px", "12px", "16px"]}
                                color="white"
                                fontWeight="400"
                                onClick={() => {
                                  setSelectedSubmission(x);
                                  onOpen();
                                }}
                              >
                                <i>
                                  {formatDate(
                                    x.submissionTime,
                                    "HH:mm, MMM do, yyy"
                                  )}
                                </i>
                              </Button>
                            ) : (
                              <Button
                                fontSize={["10px", "12px", "16px"]}
                                mb="8px"
                                minW={["140px", "180px", "190px"]}
                                _hover={{ bg: "white" }}
                                cursor="default"
                                borderRadius="4px"
                                color={colors.primary}
                                border="1px solid"
                                padding="6px"
                                bg="white"
                                fontWeight="400"
                              >
                                <i>{t("No submission")}</i>
                              </Button>
                            )}
                          </Text>
                        </Flex>
                      </ListItem>
                      <Divider
                        w="100%"
                        mb={["8px", "8px", "16px"]}
                        borderColor={colors.gray}
                        borderWidth="1px"
                      />
                    </React.Fragment>
                  ))}
                </List>
              </Box>
            </SimpleBar>
          ) : (
            <Box>
              <HStack
                m="2rem 0"
                mt={["1rem", "2rem"]}
                padding="14px"
                bg="#EAF5F4"
                fontSize={["12px", "12px", "12px", "12px", "16px"]}
                justifyContent="space-between"
                h={["20px", "40px", "60px"]}
                color={colors.primary}
              >
                <Text textAlign="start" w="33%" as="i">
                  {t("Name")}
                </Text>
                {resp?.data?.isGraded && (
                  <Text textAlign="center" w="33%" as="i">
                    {t("Grade")}
                  </Text>
                )}
                <Text textAlign="end" w="33%" as="i">
                  {t("Submission")}
                </Text>
              </HStack>

              <List mb={["1rem", "1.5rem", "2rem"]} w="100%">
                {status === "loading" && (
                  <>
                    {Array.from({ length: 3 })?.map((_, index) => (
                      <Skeleton m="12px 0" key={index}>
                        <ListItem
                          fontSize={["12px", "12px", "8px", "12px", "16px"]}
                          padding="0 14px"
                        >
                          <Flex alignItems="center">
                            <Text
                              fontSize={["12px", "12px", "8px", "12px", "16px"]}
                              fontWeight="500"
                              w="100%"
                            ></Text>
                            <Button mb="8px" w="180px"></Button>
                          </Flex>
                        </ListItem>
                      </Skeleton>
                    ))}
                  </>
                )}

                {resp?.data?.submissions?.map((x) => (
                  <React.Fragment key={x.id}>
                    <ListItem
                      fontSize={["12px", "12px", "12px", "16px"]}
                      fontWeight="400"
                      w="100%"
                      // padding="4px 14px"
                      px={["12px", "12px", "8px", "10px", "12px", "16px"]}
                      key={x.id}
                    >
                      <Flex
                        justifyContent={"space-between"}
                        alignItems="center"
                      >
                        <Text
                          display={"flex"}
                          alignItems={"center"}
                          fontSize={["10px", "12px", "16px"]}
                          fontWeight="500"
                          w="33%"
                          textAlign="start"
                        >
                          <ProfilePicture url={x.studentProfilePictureKey} />
                          {`${x.studentName} ${x.studentSurname} (${x.studentUsername})`}
                        </Text>
                        {resp?.data?.isGraded &&
                          (x.grade != null ? (
                            <Text w="33%" textAlign="center" as="i">
                              {x.grade}%
                            </Text>
                          ) : (
                            <Text w="33%" textAlign="center" as="i">
                              {t("Not available")}
                            </Text>
                          ))}

                        <Text textAlign="end" w="33%">
                          {x.submissionTime ? (
                            <Button
                              mb="8px"
                              minW={["140px", "180px", "190px"]}
                              _hover={{
                                bg: getSubmissionColor(
                                  resp?.data?.deadline,
                                  x.submissionTime,
                                  true
                                ),
                              }}
                              borderRadius="4px"
                              bg={getSubmissionColor(
                                resp?.data?.deadline,
                                x.submissionTime
                              )}
                              border="1px solid"
                              padding="6px"
                              fontSize={["10px", "12px", "16px"]}
                              color="white"
                              fontWeight="400"
                              onClick={() => {
                                setSelectedSubmission(x);
                                onOpen();
                              }}
                            >
                              <i>
                                {formatDate(
                                  x.submissionTime,
                                  "HH:mm, MMM do, yyy"
                                )}
                              </i>
                            </Button>
                          ) : (
                            <Button
                              fontSize={["10px", "12px", "16px"]}
                              mb="8px"
                              minW={["140px", "180px", "190px"]}
                              _hover={{ bg: "white" }}
                              cursor="default"
                              borderRadius="4px"
                              color={colors.primary}
                              border="1px solid"
                              padding="6px"
                              bg="white"
                              fontWeight="400"
                            >
                              <i>{t("No submission")}</i>
                            </Button>
                          )}
                        </Text>
                      </Flex>
                    </ListItem>
                    <Divider
                      w="100%"
                      mb={["8px", "8px", "16px"]}
                      borderColor={colors.gray}
                      borderWidth="1px"
                    />
                  </React.Fragment>
                ))}
              </List>
            </Box>
          )}
        </Box>

        {selectedSubmission && (
          <Modal
            // size="lg"
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              setSelectedSubmission(null);
            }}
          >
            <ModalOverlay />
            <ModalContent
              // width="lg"
              maxW={["98%", "96%", "80%", "80%"]}
              // minH="10vh"
              p="35px 8px 0px 8px"
            >
              <ModalHeader pb="8px">
                <Flex
                  justifyContent="space-between"
                  alignItems="start"
                  gap="20px"
                >
                  <Text
                    fontSize={["16px", "18px", "20px", "22px"]}
                    fontWeight="500"
                  >
                    {selectedSubmission.studentName}{" "}
                    {selectedSubmission.studentSurname} | {t("submitted at")} -{" "}
                    <Text
                      as="span"
                      color={
                        resp?.data?.deadline
                          ? new Date(selectedSubmission.submissionTime) <=
                            new Date(resp.data.deadline)
                            ? "green"
                            : "red"
                          : "black"
                      }
                      fontSize={["16px", "18px", "20px", "22px"]}
                      fontWeight="700"
                    >
                      {formatDate(
                        selectedSubmission.submissionTime,
                        "HH:mm, MMM do, yyy"
                      )}
                    </Text>
                  </Text>
                  {resp?.data?.isGraded && (
                    <HStack
                      justifyContent="space-between"
                      alignItems="center"
                      fontSize={["10px", "12px", "16px"]}
                      fontWeight="500"
                      w={["15%", "15%", "25%", "20%", "15%"]}
                    >
                      <Skeleton isLoaded={!isFetching}>
                        <Text
                          // marginRight={"14px"}
                          color={colors.primary}
                          fontWeight="500"
                        >
                          {t("Grade")}:
                        </Text>
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Input
                            type="number"
                            name="grade"
                            variant={"flushed"}
                            // w="64%"
                            value={formik.values.grade}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder={t("Grade")}
                          />
                          <Text whiteSpace="nowrap">/ 100</Text>
                        </Flex>
                      </Skeleton>
                    </HStack>
                  )}
                </Flex>
                {formik.errors.grade &&
                  formik.touched.grade &&
                  resp?.data?.isGraded && (
                    <Text
                      fontSize={["10px", "12px", "14px"]}
                      fontWeight="500"
                      textAlign="end"
                      pr="20px"
                      color="red"
                    >
                      {formik.errors.grade}
                    </Text>
                  )}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody margin="0" pb={6}>
                <Text
                  color="#00000099"
                  // w="50%"
                >
                  <InnerHtml fileKey={submissionResp?.data?.description} />
                </Text>
                <Flex flexWrap="wrap" mt={7} gap="20px">
                  {" "}
                  {submissionResp?.data?.fileAttachments?.map((x, index) => (
                    <ReadOnlyFile key={index} fileKey={x.key} />
                  ))}
                </Flex>
              </ModalBody>
              {resp?.data?.isGraded && (
                <ModalFooter pt="10px" justifyContent="end">
                  <Button
                    w={["140px", "160px", "180px", "200px"]}
                    colorScheme="teal"
                    isLoading={isLoading}
                    onClick={() => formik.handleSubmit()}
                  >
                    {t("Save")}
                  </Button>
                </ModalFooter>
              )}
            </ModalContent>
          </Modal>
        )}
      </Container>
    </Flex>
  );
}

export default AssignmentSubmission;
