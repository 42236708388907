import AWS from "aws-sdk";

AWS.config.update({
  s3BucketEndpoint: true,
  endpoint: process.env.REACT_APP_AWS_ENDPOINT,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  s3ForcePathStyle: true,
});

const s3 = new AWS.S3();

class S3Client {
  getObjectTags(key) {
    return s3
      .getObjectTagging({
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: key,
      })
      .promise();
  }

  getUrl(key, expiresAfterMinutes = 60) {
    return s3.getSignedUrl("getObject", {
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Key: key,
      Expires: expiresAfterMinutes * 60,
    });
  }
  uploadFile(key, file) {
    return new Promise((res, rej) => {
      s3.upload({
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: key,
        Body: file,
      })
        .promise()
        .then(() => this.setPendingTag(key))
        .then(() => res(key))
        .catch((e) => rej(e));
    });
  }
  uploadMultipartFile(onCompletedFn, onErrFn, onProgressFn, key, file) {
    const partSize = 5 * 1024 * 1024;
    const numParts = Math.ceil(file.size / partSize);
    let partCounter = 0;
    return new Promise((res, rej) => {
      this.createMultipartUpload(key, file.type)
        .then((x) => {
          res(x.UploadId);
          const promises = [];
          for (let partNum = 1; partNum <= numParts; partNum++) {
            const start = (partNum - 1) * partSize;
            const end = Math.min(start + partSize, file.size);
            const partBlob = file.slice(start, end);
            let promise = s3
              .uploadPart({
                Bucket: process.env.REACT_APP_AWS_BUCKET,
                Key: key,
                PartNumber: partNum,
                UploadId: x.UploadId,
                Body: partBlob,
              })
              .promise()
              .then((data) => {
                partCounter++;
                onProgressFn((partCounter / numParts) * 100);
                return { ETag: data.ETag, PartNumber: partNum };
              })
              .catch(onErrFn);
            promises.push(promise);
          }
          Promise.all(promises)
            .then((results) => {
              return this.completeMultipartUpload(x.UploadId, key, results);
            })
            .then(() => this.setPendingTag(key))
            .then(onCompletedFn)
            .catch(onErrFn);
        })
        .catch((e) => rej(e));
    });
  }
  createMultipartUpload(key, contentType) {
    return s3
      .createMultipartUpload({
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: key,
        ContentType: contentType,
      })
      .promise();
  }
  abortMultipartUpload(uploadId, key) {
    return s3
      .abortMultipartUpload({
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: key,
        UploadId: uploadId,
      })
      .promise();
  }
  completeMultipartUpload(uploadId, key, parts) {
    return s3
      .completeMultipartUpload({
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: key,
        UploadId: uploadId,
        MultipartUpload: {
          Parts: parts,
        },
      })
      .promise();
  }
  deleteFile(key) {
    return s3
      .deleteObject({
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: key,
      })
      .promise();
  }
  setPendingTag(key) {
    return s3
      .putObjectTagging({
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: key,
        Tagging: {
          TagSet: [{ Key: "status", Value: "pending" }],
        },
      })
      .promise();
  }
}

export const s3Client = new S3Client();
