import { Box, Button, Flex, HStack, Text, Skeleton } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { colors } from "../Constants";
import Question from "./Question";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getExamOverviewPromise } from "../services/examService";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import QuestionEdit from "./QuestionEdit";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

function CreateExam() {
  const { token } = useSelector((x) => x.security) || {};
  const { id } = useParams();
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    if(location.state?.examId == null) navigate('/')
  },[])

  const { data: resp, status } = useQuery(
    ["teacher-exam-overview", id],
    () => getExamOverviewPromise(id, token),
    {
      onSuccess: (resp) => {
        if (resp?.data?.questions?.length == 0)
          setQuestions([{ id: uuidv4() }]);
      },
    }
  );

  const addQuestion = () => {
    const newQuestion = { id: uuidv4() };
    setQuestions([...questions, newQuestion]);
  };

  const { t } = useTranslation("global");

  return (
    <Box bg={colors.lightGreen} minH="100vh">
      <HStack p="28px" justifyContent="space-between">
        <Skeleton isLoaded={status == "success"}>
          <Text
            fontWeight="500"
            fontSize={["16px", "20px", "20px", "24px", "32px", "36px"]}
          >
            {resp?.data?.name}
          </Text>
        </Skeleton>
        <Button
          onClick={() => navigate(`/teacher/exams/${id}`)}
          w={["48%", "25%", "20%", "14%", "10%", "10%"]}
          colorScheme="teal"
        >
          {t("Back to exam")}
        </Button>
      </HStack>
      <Box
        margin="0 auto"
        p="4rem 0"
        py={["1.5rem", "2rem", "3rem", "4rem"]}
        width={["96%", "90%", "85%", "80%", "70%"]}
      >
        <Flex flexDir="column" gap="20px">
          {resp?.data?.questions.map((x) => (
            <Flex
              flexDir="row"
              borderRadius="12px"
              border={`1px solid ${colors.primary}`}
              // w="970px"
              bg="white"
              gap="12px"
              padding="16px 10px"
            >
              <Box width="10px" h="inherit" bg={colors.primary}></Box>
              <QuestionEdit
                isGraded={resp?.data?.isGraded}
                id={x.id}
                description={x.description}
                isRequired={x.isRequired}
                coefficient={x.coefficient}
                questionType={x.questionType}
                fetchedOptions={x.options}
              />
            </Flex>
          ))}

          {questions.map((question, index) => (
            <Flex
              key={question.id}
              flexDir="row"
              borderRadius="12px"
              border={`1px solid ${colors.primary}`}
              // w="970px"
              bg="white"
              gap="12px"
              padding="16px 10px"
            >
              <Box width="10px" h="inherit" bg={colors.primary}></Box>
              <Question
                isGraded={resp?.data?.isGraded}
                setQuestions={setQuestions}
                questionId={question.id}
              />
            </Flex>
          ))}
          <Button
            gap="16px"
            _hover={{ bg: "#2a6b68" }}
            color={"white"}
            bg={colors.primary}
            border="1px solid "
            w="144px"
            onClick={addQuestion}
          >
            <Text>{t("Add question")}</Text>
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}

export default CreateExam;
