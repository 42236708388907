import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Select,
  Switch,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faClipboardList,
  faTableList,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { createExamPromise } from "../services/examService";
import useMyJoditEditor from "../hooks/useMyJoditEditor";
import { useFormik } from "formik";
import MyJoditEditor from "../helpers/MyJoditEditor";
import { addExamSchema, getAddExamSchema } from "./validations/addExamSchema";
import { useTranslation } from "react-i18next";

function AddExamT() {
  const { t } = useTranslation("global");
  const validationSchema = getAddExamSchema(t);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { token, selectedGroupId } = useSelector((x) => x.security) || {};
  const { content, isDirty, onBlur, fileKey, isSaving } = useMyJoditEditor();
  const { mutate, isLoading } = useMutation(
    (data) => createExamPromise(data, token),
    {
      onSuccess: (resp) => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-exams"],
        });
        navigate(`/teacher/exams/create-exam/${resp.data}`, {
          state: { examId: resp.data },
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      checked: false,
      name: null,
      hours: null,
      minutes: null,
      seconds: null,
      deadline: null,
      isGraded: false,
      gradingType: 0,
      isAllowedForSubmissionView: false,
      groupId: selectedGroupId,
    },
    onSubmit: (v) => {
      let values = { ...v };
      let durationSeconds = null;

      if (values.checked) {
        durationSeconds =
          (parseInt(values.hours) || 0) * 3600 +
          (parseInt(values.minutes) || 0) * 60 +
          (parseInt(values.seconds) || 0);
      }
      values.description = fileKey;
      const data = {
        name: values.name,
        description: values.description,
        durationSeconds: durationSeconds,
        deadline:
          values.deadline == null || values.deadline == ""
            ? values.deadline
            : new Date(values.deadline).toISOString(),
        isGraded: values.isGraded,
        gradingType: parseInt(values.gradingType),
        isAllowedForSubmissionView: values.isAllowedForSubmissionView,
        groupId: values.groupId,
      };

      mutate(data);
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const ref = useRef();
  const handleTimerClick = (e) => {
    if (e.target.checked) {
      formik.setFieldValue("checked", true);
      formik.setFieldValue("hours", formik.values.hours ?? 0);
      formik.setFieldValue("minutes", formik.values.minutes ?? 0);
      formik.setFieldValue("seconds", formik.values.seconds ?? 0);
    } else {
      formik.setFieldValue("checked", false);
    }
    ref.current.click();
  };

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <Box>
          <PageTitle
            status={"success"}
            title={t("Add Exam")}
            icon={faTableList}
            backPath={"/teacher/exams"}
          />
        </Box>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="100%"
          margin="8px auto"
          p="20px 0"
          gap="24px"
          bg="#EAF5F4"
        >
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Exam name")}
            </FormLabel>
            <Input
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              bg="white"
              borderRadius="4px"
              p="0 12px"
              type="text"
              placeholder={t("Exam name")}
              _placeholder={{ color: "#666666" }}
            />
            {formik.errors.name && formik.touched.name && (
              <Text p="0" pl="4px" m="0" color="red">
                {formik.errors.name}
              </Text>
            )}
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Description of the exam")}
            </FormLabel>
            <MyJoditEditor
              isSaving={isSaving}
              content={content}
              onBlur={onBlur}
            />
          </FormControl>

          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              <Accordion w={"100%"} allowToggle>
                <AccordionItem border={"none"}>
                  <Flex>
                    <Box
                      color={"#00000061"}
                      fontWeight="700"
                      fontSize="14px"
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      {t("Timer")}
                    </Box>
                    <Checkbox
                      onChange={handleTimerClick}
                      colorScheme="teal"
                      borderColor={"teal"}
                      size={"lg"}
                    >
                      <AccordionButton display={"none"} ref={ref} />
                    </Checkbox>
                  </Flex>

                  <AccordionPanel>
                    <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                      {t(
                        "In case all values are 0 - each submission's duration will be considered as due."
                      )}
                    </FormLabel>
                    <InputGroup gap="12px">
                      <FormControl>
                        <FormLabel
                          color={"#00000061"}
                          fontWeight="700"
                          fontSize="12px"
                        >
                          {t("Hours")}
                        </FormLabel>
                        <Input
                          onBlur={formik.handleBlur}
                          name="hours"
                          value={formik.values.hours}
                          onChange={formik.handleChange}
                          bg="white"
                          borderRadius="4px"
                          p="0 12px"
                          placeholder={t("Hours")}
                          _placeholder={{ color: "#666666" }}
                        />
                        {formik.errors.hours && formik.touched.hours && (
                          <Text p="0" pl="4px" m="0" color="red">
                            {formik.errors.hours}
                          </Text>
                        )}
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={"#00000061"}
                          fontWeight="700"
                          fontSize="12px"
                        >
                          {t("Minutes")}
                        </FormLabel>
                        <Input
                          onBlur={formik.handleBlur}
                          name="minutes"
                          value={formik.values.minutes}
                          onChange={formik.handleChange}
                          bg="white"
                          borderRadius="4px"
                          p="0 12px"
                          placeholder={t("Minutes")}
                          _placeholder={{ color: "#666666" }}
                        />
                        {formik.errors.minutes && formik.touched.minutes && (
                          <Text p="0" pl="4px" m="0" color="red">
                            {formik.errors.minutes}
                          </Text>
                        )}
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={"#00000061"}
                          fontWeight="700"
                          fontSize="12px"
                        >
                          {t("Seconds")}
                        </FormLabel>
                        <Input
                          name="seconds"
                          onBlur={formik.handleBlur}
                          value={formik.values.seconds}
                          onChange={formik.handleChange}
                          bg="white"
                          borderRadius="4px"
                          p="0 12px"
                          placeholder={t("Seconds")}
                          _placeholder={{ color: "#666666" }}
                        />
                        {formik.errors.seconds && formik.touched.seconds && (
                          <Text p="0" pl="4px" m="0" color="red">
                            {formik.errors.seconds}
                          </Text>
                        )}
                      </FormControl>
                    </InputGroup>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </FormLabel>
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Deadline")}
            </FormLabel>
            <Input
              bg="white"
              color="#666666"
              name="deadline"
              onChange={formik.handleChange}
              borderRadius="4px"
              p="0 12px"
              type="datetime-local"
            />
          </FormControl>

          <FormControl w={["90%", "80%", "70%", "60%", "50%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Grading type")}
            </FormLabel>
            <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
              {t(
                "Automatic - grades questions automatically; open-ended questions receive full credit if no correct answer is predefined, but if one is set, submissions are evaluated without regard to upper or lower case."
              )}{" "}
              <br />
              {t(
                "Manual - enables teachers to individually grade each question."
              )}
              <br />
              {t(
                "In both cases, the teacher can adjust the grade for each question or change the overall exam grade. This setting can be adjusted even after the exam is created."
              )}
            </FormLabel>
            <InputGroup>
              <Select
                isDisabled={!formik.values.isGraded}
                onChange={formik.handleChange}
                value={formik.values.gradingType}
                name="gradingType"
                defaultValue={0}
                bg="white"
              >
                <option defaultChecked value={0}>
                  {t("Automatic")}
                </option>
                <option value={1}>{t("Manual")}</option>
              </Select>
            </InputGroup>
          </FormControl>

          <FormControl
            w={["90%", "80%", "70%", "60%", "50%"]}
            display="flex"
            alignItems="center"
          >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Graded exam")}
              <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                {t(
                  "Exams can be either graded or ungraded. Submissions of only graded assignments can be assessed. It is possible to change an exam's status after it has been added."
                )}
              </FormLabel>
            </FormLabel>
            <Checkbox
              name="isGraded"
              onChange={formik.handleChange}
              isChecked={formik.values.isGraded}
              colorScheme="teal"
              borderColor={"teal"}
              size="lg"
            />
          </FormControl>
          <FormControl
            w={["90%", "80%", "70%", "60%", "50%"]}
            display="flex"
            alignItems="center"
          >
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("Allow for submission view")}
              <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                {t(
                  "If the exam allows submission view, then students can see their submitted questions. Otherwise, they cannot view their submissions."
                )}
              </FormLabel>
            </FormLabel>
            <Checkbox
              name="isAllowedForSubmissionView"
              onChange={formik.handleChange}
              isChecked={formik.values.isAllowedForSubmissionView}
              colorScheme="teal"
              borderColor={"teal"}
              size="lg"
            />
          </FormControl>

          <Button
            isLoading={isLoading}
            isDisabled={(!formik.dirty && !isDirty) || isSaving}
            colorScheme="teal"
            w={["90%", "80%", "70%", "60%", "50%"]}
            h="40px"
            fontWeight="600"
            fontSize="16px"
            onClick={formik.handleSubmit}
          >
            {t("Create")}
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
}

export default AddExamT;
