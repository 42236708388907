import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Select,
  Skeleton,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../general/components/PageTitle";
import {
  faCamera,
  faClipboardList,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../Constants";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {
  getGroupDetailsPromise,
  updateGroupPromise,
} from "../services/groupService";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { s3Client } from "../helpers/s3Client";
import ConfirmationModal from "../teacher/modal/ConfirmationModalT";
import {
  editGroupSchema,
  getEditGroupSchema,
} from "./validations/editGroupSchema";
import { v4 as uuidv4 } from "uuid";
import Resizer from "react-image-file-resizer";

function EditGroup() {
  const { t } = useTranslation("global");
  const validationSchema = getEditGroupSchema(t);

  const img =
    "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png";
  const { id } = useParams();
  const { token } = useSelector((x) => x.security) || {};
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState();
  const { data: resp, status } = useQuery(["group-details", token, id], () =>
    getGroupDetailsPromise(id, token)
  );
  useEffect(() => {
    setUploadedImage(
      resp?.data?.groupPictureKey
        ? s3Client.getUrl(resp?.data?.groupPictureKey)
        : img
    );
  }, [resp]);
  const { mutate, isLoading } = useMutation(
    (data) => updateGroupPromise(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["group-details", "get-assignments"],
        });
        navigate(`/manager/groups/${id}`);
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      name: resp?.data?.name,
      newGroupPictureKey: null,
      isGroupPictureDeleted: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate(values);
    },
    enableReinitialize: true,
  });

  const fileInputRef = useRef(null);
  const toast = useToast();
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file == null) return;
    const fileExt = file.name.split(".").pop();
    const fileMbSize = file.size / 1024 / 1024;
    if (!file.type.match(/image-*/)) return;
    if (fileMbSize > 2) {
      toast({
        position: "top-right",
        title: "Error",
        description: "Image should be less than 2MB!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    Resizer.imageFileResizer(
      file,
      file.width,
      file.height,
      fileExt,
      30,
      0,
      (reziedFile) => {
        setImageLoading(true);
        s3Client.uploadFile(`${uuidv4()}_${fileExt}`, reziedFile).then((x) => {
          formik.setFieldValue("newGroupPictureKey", x);
          setImageLoading(false);
        });
      },
      "file"
    );
    setUploadedImage(URL.createObjectURL(file));
  };

  const handleDeletePictureClick = () => {
    formik.setFieldValue("isGroupPictureDeleted", true);
    formik.setFieldValue("newGroupPictureKey", null);
    setUploadedImage(img);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <Box>
          <PageTitle
            status="success"
            title={t("Edit Group")}
            icon={faClipboardList}
            backPath={`/manager/groups/${id}`}
          />
        </Box>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w={["96%", "90%", "90%", "85%", "70%", "65%"]}
          margin="8px auto"
          p="20px 0"
          gap="24px"
          bg="#EAF5F4"
          borderRadius="13px"
          minH="70vh"
        >
          <VStack gap="20px">
            <Box position="relative">
              <Skeleton
                h={{ base: "150px", md: "240px" }}
                borderRadius="12px"
                w="100%"
                isLoaded={status == "success"}
              >
                <Image
                  accept="image/*"
                  id="groupPicture"
                  borderRadius="12px"
                  objectFit="cover"
                  h={{ base: "150px", md: "240px" }}
                  w={["210px", "310px", "310px", "410px", "510px"]}
                  src={uploadedImage}
                />
              </Skeleton>
              <Center
                w="32px"
                h="32px"
                bg="white"
                bottom={"8px"}
                right="5px"
                borderRadius="50%"
                position="absolute"
                color="teal"
              >
                <FontAwesomeIcon
                  icon={faCamera}
                  onClick={handleUploadButtonClick}
                  style={{ cursor: "pointer" }}
                />
              </Center>
            </Box>
            <Input
              type="file"
              id="fileInput"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />

            <Button
              isDisabled={
                !resp?.data?.groupPictureKey &&
                !formik.values.newGroupPictureKey
              }
              gap="16px"
              colorScheme="red"
              onClick={handleDeletePictureClick}
              w={"220px"}
            >
              <FontAwesomeIcon color={"white"} icon={faTrash} />
              <Text>{t("Delete Group Picture")}</Text>
            </Button>
          </VStack>
          <FormControl w="90%">
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("addGroup.grName")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
              <Input
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="text"
                placeholder={t("addGroup.grName")}
                value={formik.values.name}
                _placeholder={{ color: "#666666" }}
              />
              {formik.errors.name && formik.touched.name && (
                <Text p="0" pl="4px" m="0" color="red">
                  {formik.errors.name}
                </Text>
              )}
            </Skeleton>
          </FormControl>
          {/* <input type="image" src={img} /> */}

          <Button
            isLoading={isLoading}
            onClick={formik.handleSubmit}
            isDisabled={!formik.dirty || imageLoading}
            bg={colors.primary}
            color="white"
            w="200px"
            h="40px"
            fontWeight="600"
            fontSize="16px"
            _hover={{ bg: "#2a6b68" }}
          >
            {t("save")}
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
}

export default EditGroup;
