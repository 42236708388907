import { httpClient } from "../helpers/httpClient";

export const getExamSearch = (groupId, token, name) => {
  return httpClient.get(`exam/search/${groupId}?name=${name}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getExams = (groupId, token, skip, take) => {
  return httpClient.get(`exam/group/${groupId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      skip,
      take,
    },
  });
};

export const getExamGradesForStudentPromise = (token, groupId) => {
  return httpClient.get(`exam/grades/for-student/${groupId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getSingleExamForStudent = (examId, groupId, token) => {
  return httpClient.get(`exam/for-student`, {
    params: { examId, groupId },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const createExamPromise = (data, token) => {
  return httpClient.post("exam/create", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getExamForTeacher = (examId, groupId, token) => {
  return httpClient.get(`exam/for-teacher/exam/${examId}/group/${groupId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getExamGradesForTeacherPromise = (token, groupId) => {
  return httpClient.get(`exam/grades/for-teacher/${groupId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};


export const updateExamPromise = (examId, data, token) => {
  return httpClient.put(`exam/update/${examId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getExamForUpdatePromise = (examId, token) => {
  return httpClient.get(`exam/for-update/${examId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getExamOverviewPromise = (examId, token) => {
  return httpClient.get(`exam/overview/exam/${examId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createQuestionPromise = (data, token) => {
  return httpClient.post(`exam/create/question`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateQuestionPromise = (questionId, data, token) => {
  return httpClient.put(`exam/update/question/${questionId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getExamForSubmissionPromise = (examId, token) => {
  return httpClient.get(`exam/start/${examId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const submitExamPromise = (data, token) => {
  return httpClient.post(`exam/submit`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getExamForStudentSubmissionViewPromise = (examId, token) => {
  return httpClient.get(`exam/view/${examId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getExamSubmissionForTeacherPromise = (examSubmissionId, token) => {
  return httpClient.get(
    `exam/for-teacher/exam-submission/${examSubmissionId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const gradeSubmittedQuestionPromise = (
  submittedQuestionId,
  data,
  token
) => {
  return httpClient.put(
    `exam/grade/submitted-question/${submittedQuestionId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const gradeExamSubmissionPromise = (examSubmissionId, data, token) => {
  return httpClient.put(
    `exam/grade/exam-submission/${examSubmissionId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

//! delete
export const deleteExam = (examId, token) => {
  return httpClient.delete(`exam/delete/${examId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteQuestion = (questionId, token) => {
  return httpClient.delete(`exam/delete/question/${questionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
