import React, { useCallback, useState } from "react";
import { s3Client } from "../helpers/s3Client";
import { v4 as uuidv4 } from "uuid";

export default function useMyJoditEditor() {
  const [content, setContent] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [fileKey, setFileKey] = useState(null);

  const resetEditor = () => {
    setContent(null);
    setIsDirty(false);
    setFileKey(null);
    setIsSaving(false);
  };

  const onBlur = useCallback((newContent) => {
    console.log('he');
    setIsDirty(true);
    setContent(newContent);
    createHtmlFile(newContent);
  },[content])

  const createHtmlFile = (htmlContent) => {
    if (htmlContent == content) return;
    let key = `${uuidv4()}.html`;
    const blob = new Blob([htmlContent], { type: "text/html" });
    const file = new File([blob], key, {
      type: "text/html",
      lastModified: Date.now(),
    });
    setIsSaving(true);
    s3Client.uploadFile(key, file).then((x) => {
      setFileKey(x);
      setIsSaving(false);
    });
  };

  const initialize = (fileKey) => {
    if(fileKey == null) return
    setFileKey(fileKey)
    let url = s3Client.getUrl(fileKey);

    fetch(url)
      .then((res) => {
        if(!res.ok){
          throw new Error()
        }
        return res.text()
      })
      .catch(e=>{})
      .then((x) => setContent(x))
  };

  return {
    content,
    isDirty,
    resetEditor,
    onBlur,
    fileKey,
    isSaving,
    initialize
  };
}
