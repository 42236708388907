import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  List,
  ListItem,
  Skeleton,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  faChalkboardTeacher,
  faClipboardList,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import PageTitle from "../general/components/PageTitle";
import { colors } from "../Constants";
import { Link, useNavigate } from "react-router-dom";
import SearchBar from "../student/components/SearchBar";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getCoManagersPromise } from "../services/managerService";
import { useTranslation } from "react-i18next";
import ProfilePicture from "../general/components/ProfilePicture";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

export default function CoManagers() {
  const { token } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(["co-managers", token], () =>
    getCoManagersPromise(token)
  );
  const navigate = useNavigate();

  const [t] = useTranslation("global");

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleSearchChange = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.length > 0) {
      try {
        const filteredData = resp?.data?.filter((student) =>
          `${student.name} ${student.surname}`
            .toLowerCase()
            .includes(term.toLowerCase())
        );
        setSearchResults(filteredData);
        setShowDropdown(true);
      } catch (error) {
        console.error("Error", error);
      }
    } else {
      setShowDropdown(false);
      setSearchResults([]);
    }
  };

  const [isLessThan610px] = useMediaQuery("(max-width: 610px)");

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <PageTitle
          status={"success"}
          title={t("Co-managers")}
          icon={faChalkboardTeacher}
          backPath={"/manager/general"}
        />
        <HStack justifyContent="space-between">
          <Box
            w="24%"
            sx={{
              "@media (min-width: 1024px)": {
                w: "240px",
              },
              "@media (max-width: 1024px)": {
                w: "210px",
              },
              "@media (max-width: 768px)": {
                w: "250px",
              },
              "@media (max-width: 425px)": {
                w: "220px",
              },
              "@media (max-width: 375px)": {
                w: "202px",
              },
            }}
          >
            <SearchBar
              onChange={handleSearchChange}
              disabled={
                status === "loading" ||
                status === "error" ||
                (status === "success" && (!resp || resp?.data?.length === 0))
              }
            />
            {showDropdown && (
              <Box
                sx={{
                  "::-webkit-scrollbar-thumb": {
                    borderRadius: "12px",
                    background: colors.primary,
                  },
                  "::-webkit-scrollbar": {
                    width: "10px",
                  },

                  "@media (max-width: 910px)": {
                    w: "40%",
                  },
                  "@media (max-width: 750px)": {
                    w: "40%",
                  },
                  "@media (max-width: 610px)": {
                    w: "40%",
                  },
                  "@media (max-width: 420px)": {
                    w: "60%",
                  },
                }}
                w="30%"
                position="absolute"
                zIndex="1000"
                maxHeight="400px"
                overflowY="auto"
                border="1px solid #ddd"
                borderRadius="4px"
                bg="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                mt="2"
              >
                {searchResults.length === 0 ? (
                  <Text p="4" textAlign="center">
                    {t("No results found for")} "
                    <Text as="span" color={"gray"}>
                      {searchTerm}
                    </Text>
                    "
                  </Text>
                ) : (
                  searchResults.map((result) => (
                    <Box
                      key={result.id}
                      p="4"
                      borderBottom="1px solid #eee"
                      cursor="pointer"
                      onClick={() => {
                        setShowDropdown(false);
                        navigate(`/manager/co-managers/${result.id}`);
                      }}
                    >
                      {`${result.name} ${result.surname}`}
                    </Box>
                  ))
                )}
              </Box>
            )}
          </Box>
        </HStack>
        {resp?.data?.length !== 0 && (
          <Box mb="28px" w="100%">
            {isLessThan610px ? (
              <SimpleBar
                autoHide={false}
                style={{ maxHeight: "calc(100vh - 100px)" }}
              >
                <Box
                  sx={{
                    "@media (max-width: 610px)": {
                      minW: "600px",
                    },
                  }}
                >
                  {(status === "loading" || resp?.data?.length > 0) && (
                    <HStack
                      m="2rem 0 1rem"
                      padding="14px"
                      bg="#EAF5F4"
                      fontSize={["12px", "12px", "12px", "12px", "16px"]}
                      justifyContent="space-between"
                      h="60px"
                      color="teal.400" // Example color
                    >
                      <Text w="140px" as="i">
                        {t("table.name")}
                      </Text>
                      <Text textAlign="center" w="210px" as="i">
                        {t("table.email")}
                      </Text>
                      <Text textAlign="center" w="174px" as="i">
                        {t("table.phone")}
                      </Text>
                    </HStack>
                  )}
                  <List mb="2rem" w="100%">
                    {status === "loading" &&
                      Array.from({ length: 5 }).map((_, index) => (
                        <Skeleton key={index}>
                          <ListItem
                            cursor="default"
                            fontSize={["12px", "12px", "12px", "12px", "16px"]}
                            fontWeight="500"
                            w="100%"
                            padding="20px 14px"
                          />
                          <Divider
                            w="100%"
                            mb="16px"
                            borderColor="gray.300"
                            borderWidth="1px"
                          />
                        </Skeleton>
                      ))}
                    {resp?.data?.map((x) => (
                      <React.Fragment key={x.id}>
                        <ListItem
                          onClick={() =>
                            navigate(`/manager/co-managers/${x.id}`)
                          }
                          cursor="default"
                          fontSize={["12px", "12px", "12px", "12px", "16px"]}
                          fontWeight="500"
                          w="100%"
                          padding="4px 14px"
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Text
                              display="flex"
                              alignItems="center"
                              _hover={{ color: "teal.400", cursor: "pointer" }}
                            >
                              <Flex>
                                <ProfilePicture url={x.profilePictureKey} />
                              </Flex>
                              {`${x.name} ${x.surname}`}
                            </Text>
                            <Text textAlign="center" w="210px">
                              {x.email}
                            </Text>
                            <Text textAlign="center" w="174px">
                              {x.phone}
                            </Text>
                          </Flex>
                        </ListItem>
                        <Divider
                          w="100%"
                          mb="16px"
                          borderColor="gray.300"
                          borderWidth="1px"
                        />
                      </React.Fragment>
                    ))}
                  </List>
                </Box>
              </SimpleBar>
            ) : (
              <Box>
                {(status === "loading" || resp?.data?.length > 0) && (
                  <HStack
                    m="2rem 0 1rem"
                    padding="14px"
                    bg="#EAF5F4"
                    fontSize={["12px", "12px", "12px", "12px", "16px"]}
                    justifyContent="space-between"
                    h="60px"
                    color="teal.400" // Example color
                  >
                    <Text w="140px" as="i">
                      {t("table.name")}
                    </Text>
                    <Text textAlign="center" w="210px" as="i">
                      {t("table.email")}
                    </Text>
                    <Text textAlign="center" w="174px" as="i">
                      {t("table.phone")}
                    </Text>
                  </HStack>
                )}
                <List mb="2rem" w="100%">
                  {status === "loading" &&
                    Array.from({ length: 5 }).map((_, index) => (
                      <Skeleton key={index}>
                        <ListItem
                          cursor="default"
                          fontSize={["12px", "12px", "12px", "12px", "16px"]}
                          fontWeight="500"
                          w="100%"
                          padding="20px 14px"
                        />
                        <Divider
                          w="100%"
                          mb="16px"
                          borderColor="gray.300"
                          borderWidth="1px"
                        />
                      </Skeleton>
                    ))}
                  {resp?.data?.map((x) => (
                    <React.Fragment key={x.id}>
                      <ListItem
                        onClick={() => navigate(`/manager/co-managers/${x.id}`)}
                        cursor="default"
                        fontSize={["12px", "12px", "12px", "12px", "16px"]}
                        fontWeight="500"
                        w="100%"
                        padding="4px 14px"
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text
                            display="flex"
                            alignItems="center"
                            _hover={{ color: "teal.400", cursor: "pointer" }}
                          >
                            <Flex>
                              <ProfilePicture url={x.profilePictureKey} />
                            </Flex>
                            {`${x.name} ${x.surname}`}
                          </Text>
                          <Text textAlign="center" w="210px">
                            {x.email}
                          </Text>
                          <Text textAlign="center" w="174px">
                            {x.phone}
                          </Text>
                        </Flex>
                      </ListItem>
                      <Divider
                        w="100%"
                        mb="16px"
                        borderColor="gray.300"
                        borderWidth="1px"
                      />
                    </React.Fragment>
                  ))}
                </List>
              </Box>
            )}
          </Box>
        )}
        <Box textAlign="center">
          {status === "error" && (
            <>
              <Text fontWeight="600" fontSize="24px" color="red">
                {t("errMessages.message1")}
              </Text>
              <Text fontWeight="600" fontSize="24px" color="red">
                {t("errMessages.message2")}
              </Text>
            </>
          )}

          {status === "success" && resp?.data?.length === 0 && (
            <Text> {t("No co-managers found.")}</Text>
          )}
        </Box>
      </Container>
    </Flex>
  );
}
