import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { whoAmIPromise } from "../services/securityService";
import { setWhoAmI } from "../redux/slices/securitySlice";

export default function useWhoAmI() {
  const dispatch = useDispatch();
  const { token } = useSelector((x) => x.security) || {};
  const {mutate} = useMutation(whoAmIPromise, {
    onSuccess: (resp) => {
      dispatch(setWhoAmI(resp.data));
    },
  });
  useEffect(() => {
    if (token != null) mutate(token);
  }, [token]);
}