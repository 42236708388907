import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Radio,
  Skeleton,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { colors } from "../Constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getExamForStudentSubmissionViewPromise } from "../services/examService";
import InnerHtml from "../helpers/InnerHtml";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function ExamViewSubmission() {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(
    ["student-exam-view-submission", token, id],
    () => getExamForStudentSubmissionViewPromise(id, token)
  );

  return (
    <Box bg={colors.lightGreen} minH="100vh">
      <HStack p="12px" justifyContent="space-between">
        <Button
          onClick={() => navigate(`/student/exams/${id}`)}
          w={["48%", "25%", "20%", "14%", "10%", "10%"]}
          colorScheme="teal"
        >
          {t("Back to exam")}
        </Button>
      </HStack>
      <VStack
        position="relative"
        alignItems="start"
        gap="20px"
        margin="0 auto"
        p={["2rem 0", "2rem 0", "4rem 0", "4rem 0"]}
        w={["94%", "98%", "80%", "70%", "50%"]}
      >
        <Skeleton w="100%" isLoaded={status == "success"}>
          <Box
            borderRadius="8px"
            border="1px solid transparent"
            borderBottomColor={colors.primary}
            // w="708px"
            w="100%"
            bg="white"
            padding={["12px 10px", "12px", "12px 18px", "12px 18px"]}
          >
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Text
                w="60%"
                pr="12px"
                fontSize={["20px", "20px", "24px", "32px", "36px"]}
                color={colors.primary}
                fontWeight="600"
              >
                {resp?.data?.name}
              </Text>
              {resp?.data?.isGraded && (
                <Flex columnGap={"10px"} alignItems="center">
                  {resp?.data?.grade == null ? (
                    <Text
                      fontSize={["12px", "14px", "16px", "18px", "20px"]}
                      color={colors.primary}
                      fontWeight="600"
                    >
                      <Text
                        fontSize={["12px", "14px", "16px", "18px", "20px"]}
                        fontWeight="600"
                        color="#fcbe03"
                      >
                        {t("Grade is pending")}{" "}
                        <FontAwesomeIcon icon={faSpinner} />
                      </Text>
                    </Text>
                  ) : (
                    <Text
                      fontSize={["12px", "14px", "16px", "18px", "20px"]}
                      color={colors.primary}
                      fontWeight="600"
                    >
                      {t("Grade")}: {resp?.data?.grade}%
                    </Text>
                  )}
                </Flex>
              )}
            </Flex>
          </Box>
        </Skeleton>

        {resp?.data?.questions?.map((x, i) => {
          i++;
          if (x.questionType == 2) {
            let submissionText =
              x.submissionForQuestion?.submittedOptions[0]?.submissionText;
            let isCorrect = null;
            if (submissionText != null) {
              isCorrect =
                submissionText ==
                x.options[0]?.correctAnswerText;
            }

            return (
              <Flex
                borderRadius="12px"
                border={`1px solid ${
                  isCorrect == null
                    ? colors.primary
                    : isCorrect
                    ? "green"
                    : "red"
                }`}
                // w="708px"
                w="100%"
                bg="white"
                gap="12px"
                padding={[
                  "8px",
                  "10px 8px",
                  "12px 8px",
                  "16px 10px",
                  "16px 10px",
                ]}
              >
                <Box
                  width="10px"
                  h="inherit"
                  bg={
                    isCorrect == null
                      ? colors.primary
                      : isCorrect
                      ? "green"
                      : "red"
                  }
                ></Box>
                <FormControl>
                  <Flex mb={"1rem"} justifyContent={"space-between"}>
                    {resp?.data?.isGraded && x.coefficient != null ? (
                      <Text
                        fontSize={["12px", "14px", "16px", "16px"]}
                        fontWeight={"bold"}
                        color={colors.primary}
                      >
                        {t("Max grade")}: {x.coefficient}
                      </Text>
                    ) : (
                      <></>
                    )}

                    {x.coefficient != null &&
                      x.submissionForQuestion?.grade != null && (
                        <Text fontWeight={"bold"} color={colors.primary}>
                          {t("Your grade")}: {x.submissionForQuestion?.grade}
                        </Text>
                      )}
                  </Flex>
                  <FormLabel
                    fontWeight="400"
                    p="10px 12px"
                    fontSize={["12px", "14px", "18px", "18px"]}
                    bg={colors.lightGreen}
                  >
                    {x.isRequired && <span style={{ color: "red" }}>* </span>}{" "}
                    {`${t("Question")} ${i}: `}
                    <InnerHtml fileKey={x.description} />
                  </FormLabel>
                  <Textarea
                    isDisabled={true}
                    fontWeight={"bold"}
                    color={"green"}
                    value={x.options[0]?.correctAnswerText ?? submissionText}
                    border="none"
                    resize={"none"}
                    placeholder={t("Text...")}
                    rows="1"
                  />
                  <Divider borderWidth="1px" />
                  {x.options[0]?.correctAnswerText != null &&
                    x.options[0]?.correctAnswerText != submissionText && (
                      <Textarea
                        isDisabled={true}
                        color={"red"}
                        value={submissionText}
                        border="none"
                        resize={"none"}
                        rows="1"
                      />
                    )}
                </FormControl>
              </Flex>
            );
          }

          if (x.questionType == 1) {
            let submittedOptionIds =
              x.submissionForQuestion.submittedOptions.map((y) => y.optionId);
            let isCorrect = null;
            if (submittedOptionIds.length > 0) {
              let correctOptionIds = x.options.filter(y=>y.isCorrect).map(y=>y.id)
              isCorrect = submittedOptionIds.length == correctOptionIds.length && correctOptionIds.every(y=>submittedOptionIds.some(z=>z==y))
            }
            return (
              <Flex
                borderRadius="12px"
                border={`1px solid ${
                  isCorrect == null
                    ? colors.primary
                    : isCorrect
                    ? "green"
                    : "red"
                }`}
                // w="708px"
                w="100%"
                bg="white"
                gap="12px"
                padding={[
                  "8px",
                  "10px 8px",
                  "12px 8px",
                  "16px 10px",
                  "16px 10px",
                ]}
              >
                <Box
                  width="10px"
                  h="inherit"
                  bg={
                    isCorrect == null
                      ? colors.primary
                      : isCorrect
                      ? "green"
                      : "red"
                  }
                ></Box>
                <FormControl>
                  <Flex mb={"1rem"} justifyContent={"space-between"}>
                    {resp?.data?.isGraded && x.coefficient != null ? (
                      <Text
                        fontSize={["12px", "14px", "16px", "16px"]}
                        fontWeight={"bold"}
                        color={colors.primary}
                      >
                        {t("Max grade")}: {x.coefficient}
                      </Text>
                    ) : (
                      <></>
                    )}

                    {x.coefficient != null &&
                      x.submissionForQuestion?.grade != null && (
                        <Text fontWeight={"bold"} color={colors.primary}>
                          {t("Your grade")}: {x.submissionForQuestion?.grade}
                        </Text>
                      )}
                  </Flex>
                  <FormLabel
                    fontWeight="400"
                    p="10px 12px"
                    fontSize={["12px", "14px", "18px", "18px"]}
                    bg={colors.lightGreen}
                  >
                    {x.isRequired && <span style={{ color: "red" }}>* </span>}{" "}
                    {`${t("Question")} ${i}: `}
                    <InnerHtml fileKey={x.description} />
                  </FormLabel>
                  <Stack gap={["4px", "6px", "8px", "12px"]} direction="column">
                    {x.options.map((y) => {
                      let submittedOptions =
                        x.submissionForQuestion.submittedOptions;
                      return (
                        <HStack>
                          <Checkbox
                            isChecked={submittedOptions.some(
                              (z) => z.optionId == y.id
                            )}
                            isDisabled={true}
                            value={y.id}
                          >
                            {y.description}
                          </Checkbox>
                          {y.isCorrect &&
                            submittedOptions.some(
                              (z) => z.optionId == y.id
                            ) && (
                              <Text
                                ms={"1rem"}
                                color={"green"}
                                fontWeight={"bold"}
                                fontSize={["12px", "14px", "16px", "16px"]}
                              >
                                {t("Correct")}
                              </Text>
                            )}
                          {y.isCorrect &&
                            !submittedOptions.some(
                              (z) => z.optionId == y.id
                            ) && (
                              <Text
                                ms={"1rem"}
                                color={"red"}
                                fontWeight={"bold"}
                              >
                                {t("Also correct")}
                              </Text>
                            )}
                          {submittedOptions.some((z) => z.optionId == y.id) &&
                            !y.isCorrect && (
                              <Text
                                fontSize={["12px", "14px", "16px", "16px"]}
                                ms={"1rem"}
                                color={"red"}
                                fontWeight={"bold"}
                              >
                                {t("Wrong")}
                              </Text>
                            )}
                        </HStack>
                      );
                    })}
                  </Stack>
                </FormControl>
              </Flex>
            );
          }

          if (x.questionType == 0) {
            let submittedOptionId =
              x.submissionForQuestion.submittedOptions[0]?.optionId;
            let isCorrect = null;
            if (submittedOptionId != null) {
              isCorrect =
                submittedOptionId == x.options.find((y) => y.isCorrect)?.id;
            }
            return (
              <Flex
                borderRadius="12px"
                border={`1px solid ${
                  isCorrect == null
                    ? colors.primary
                    : isCorrect
                    ? "green"
                    : "red"
                }`}
                // w="708px"

                w="100%"
                bg="white"
                gap="12px"
                padding={[
                  "8px",
                  "10px 8px",
                  "12px 8px",
                  "16px 10px",
                  "16px 10px",
                ]}
              >
                <Box
                  width="10px"
                  h="inherit"
                  bg={
                    isCorrect == null
                      ? colors.primary
                      : isCorrect
                      ? "green"
                      : "red"
                  }
                ></Box>
                <FormControl>
                  <Flex mb={"1rem"} justifyContent={"space-between"}>
                    {resp?.data?.isGraded && x.coefficient != null ? (
                      <Text
                        fontSize={["12px", "14px", "16px", "16px"]}
                        fontWeight={"bold"}
                        color={colors.primary}
                      >
                        {t("Max grade")}: {x.coefficient}
                      </Text>
                    ) : (
                      <></>
                    )}

                    {x.coefficient != null &&
                      x.submissionForQuestion?.grade != null && (
                        <Text fontWeight={"bold"} color={colors.primary}>
                          {t("Your grade")}: {x.submissionForQuestion?.grade}
                        </Text>
                      )}
                  </Flex>
                  <FormLabel
                    fontWeight="400"
                    p="10px 12px"
                    fontSize={["12px", "14px", "18px", "18px"]}
                    bg={colors.lightGreen}
                  >
                    {x.isRequired && <span style={{ color: "red" }}>* </span>}
                    {`${t("Question")} ${i}: `}
                    <InnerHtml fileKey={x.description} />
                  </FormLabel>
                  <Stack ap={["4px", "6px", "8px", "12px"]} direction="column">
                    {x.options.map((y) => {
                      let submittedOptionId =
                        x.submissionForQuestion.submittedOptions[0]?.optionId;
                      return (
                        <HStack>
                          <Radio
                            isDisabled={true}
                            isChecked={submittedOptionId == y.id}
                            value={y.id}
                          >
                            {y.description}
                          </Radio>
                          {y.isCorrect && (
                            <Text
                              ms={"1rem"}
                              color={"green"}
                              fontWeight={"bold"}
                              fontSize={["12px", "14px", "16px", "16px"]}
                            >
                              {t("Correct")}
                            </Text>
                          )}

                          {submittedOptionId == y.id && !y.isCorrect && (
                            <Text
                              fontSize={["12px", "14px", "16px", "16px"]}
                              ms={"1rem"}
                              color={"red"}
                              fontWeight={"bold"}
                            >
                              {t("Wrong")}
                            </Text>
                          )}
                        </HStack>
                      );
                    })}
                  </Stack>
                </FormControl>
              </Flex>
            );
          }
        })}
      </VStack>
    </Box>
  );
}

export default ExamViewSubmission;
