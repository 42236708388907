import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { colors } from "../../Constants";
import { useTranslation } from "react-i18next";

function SearchBar({ disabled, onChange }) {
  const [t] = useTranslation("global");

  return (
    <InputGroup
      sx={{
        "@media (max-width: 910px)": {
          fontSize: "12px",
        },
      }}
    >
      <InputRightElement pointerEvents="none">
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          marginLeft={3}
          color={colors.primary}
        />
      </InputRightElement>
      <Input
        disabled={disabled}
        borderRadius="4px"
        paddingRight={"8"}
        border="1px solid #00000061"
        type="search"
        placeholder={t("search")}
        _placeholder={{ fontSize: ["12px","14px", "16px", "16px"] }} 
        onChange={onChange}
      />
    </InputGroup>
  );
}

export default SearchBar;
