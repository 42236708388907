import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  Progress,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { colors } from "../../Constants";
import { ROLE_TEACHER, ROLE_STUDENT } from "../../helpers/constants";
import {
  faCheck,
  faClipboardList,
  faTriangleExclamation,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import {
  getStudentStatisticsPromise,
  getGroupStatisticsPromise,
} from "../../services/statisticsService";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

const GeneralCards = () => {
  const { t } = useTranslation("global");
  const { token, selectedGroupId, roleName } =
    useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(
    ["general-statistics", selectedGroupId, token],
    () => {
      switch (roleName) {
        case ROLE_TEACHER:
          return getGroupStatisticsPromise(selectedGroupId, token);
        case ROLE_STUDENT:
          return getStudentStatisticsPromise(selectedGroupId, token);
        default:
          break;
      }
    }
  );

  return (
    <>
      <Flex
        justifyContent="space-between"
        flexWrap="wrap"
        // gap="24px"
        gap={{ base: "12px", md: "24px" }}
        w={`calc((100% / 3 * 2) - 16px)`}
        sx={{
          "@media (max-width: 992px)": {
            w: `calc(71% - 8px )`,
          },
          "@media (max-width: 768px)": {
            w: "100%",
          },
        }}
      >
        <Card
          w={{
            base: "calc((100% / 1))",
            sm: "calc((100% / 2) - 12px)",
            md: "calc((100% / 1) - 12px)",
            lg: "calc((100% / 2) - 12px)",
          }}
          bg={colors.lightGreen}
        >
          <Skeleton isLoaded={status != "loading"} h="100%">
            <CardHeader
              sx={{
                "@media (max-width: 650px)": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: "12px",
                },
                "@media (max-width: 500px)": {
                  py: "8px",
                },
              }}
            >
              <Flex
                width="60px"
                h="60px"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bg="white"
                sx={{
                  "@media (max-width: 890px)": {
                    fontSize: "20px",
                    boxSize: "50px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                    boxSize: "40px",
                  },
                }}
              >
                <FontAwesomeIcon
                  color={colors.primary}
                  fontSize="22px"
                  icon={faClipboardList}
                />
              </Flex>
            </CardHeader>
            <CardBody
              py={"0"}
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "8px",
                },
              }}
            >
              <Heading
                fontSize="20px"
                sx={{
                  "@media (max-width: 650px)": {
                    textAlign: "center",
                    my: "12px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                  },
                  "@media (max-width: 530px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 430px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 375px)": {
                    fontSize: "12px",
                  },
                }}
              >
                {t("Assignments")}
              </Heading>
            </CardBody>
            <CardFooter
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "4px",
                },
                "@media (max-width: 650px)": {
                  paddingY: "8px",
                },
                "@media (max-width: 500px)": {
                  paddingY: "6px",
                },
              }}
            >
              <Flex
                bg="white"
                padding="0 12px"
                h="48px"
                borderRadius="8px"
                width="100%"
                alignItems="center"
                // sx={{
                //   "@media (max-width: 890px)": {
                //     padding: "0 8px",
                //   },
                //   "@media (max-width: 700px)": {
                //     padding: "0 4px",
                //   },
                // }}
              >
                <Text
                  fontSize="14px"
                  sx={{
                    "@media (max-width: 890px)": {
                      // fontSize: "12px",
                      w: "100%",
                      // // p: "0",
                    },
                    "@media (max-width: 760px)": {
                      fontSize: "14px",
                      // // p: "0",
                    },
                    "@media (max-width: 700px)": {
                      fontSize: "12px",
                      // // p: "0",
                    },
                    "@media (max-width: 530px)": {
                      fontSize: "14px",
                      // // p: "0",
                    },
                    "@media (max-width: 375px)": {
                      fontSize: "12px",
                      // // p: "0",
                    },
                  }}
                  fontWeight="400"
                  w="92%"
                  borderRadius="8px"
                >
                  {resp?.data?.undoneAssignmentsCount
                    ? `${t("You have")} ${
                        resp?.data?.undoneAssignmentsCount
                      } ${t("undone assignments")}!`
                    : `${t("No undone assignments!")}`}
                </Text>
                <FontAwesomeIcon
                  icon={
                    resp?.data?.undoneAssignmentsCount
                      ? faTriangleExclamation
                      : faCheck
                  }
                  color={
                    resp?.data?.undoneAssignmentsCount ? "#F5CC3A" : "#04C600"
                  }
                />{" "}
              </Flex>
            </CardFooter>
          </Skeleton>
        </Card>

        <Card
          w={{
            base: "calc((100% / 1))",
            sm: "calc((100% / 2) - 12px)",
            md: "calc((100% / 1) - 12px)",
            lg: "calc((100% / 2) - 12px)",
          }}
          bg={colors.lightGreen}
        >
          <Skeleton isLoaded={status != "loading"} h="100%">
            <CardHeader
              sx={{
                "@media (max-width: 650px)": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: "12px",
                },
                "@media (max-width: 500px)": {
                  py: "8px",
                },
              }}
            >
              <Flex
                width="60px"
                h="60px"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bg="white"
                sx={{
                  "@media (max-width: 890px)": {
                    fontSize: "20px",
                    boxSize: "50px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                    boxSize: "40px",
                  },
                }}
              >
                <FontAwesomeIcon
                  color={colors.primary}
                  fontSize="22px"
                  icon={faClipboardList}
                />
              </Flex>
            </CardHeader>
            <CardBody
              py={"0"}
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "8px",
                },
              }}
            >
              <Heading
                fontSize="20px"
                sx={{
                  "@media (max-width: 650px)": {
                    textAlign: "center",
                    my: "12px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                  },
                  "@media (max-width: 530px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 430px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 375px)": {
                    fontSize: "12px",
                  },
                }}
              >
                {t("Materials")}{" "}
              </Heading>
            </CardBody>
            <CardFooter
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "4px",
                },
                "@media (max-width: 650px)": {
                  paddingY: "8px",
                },
                "@media (max-width: 500px)": {
                  paddingY: "6px",
                },
              }}
            >
              <Flex
                bg="white"
                padding="0 12px"
                h="48px"
                borderRadius="8px"
                width="100%"
                alignItems="center"
                // sx={{
                //   "@media (max-width: 890px)": {
                //     padding: "0 8px",
                //   },
                //   "@media (max-width: 700px)": {
                //     padding: "0 4px",
                //   },
                // }}
              >
                <Text
                  fontSize="14px"
                  sx={{
                    "@media (max-width: 890px)": {
                      // fontSize: "12px",
                      w: "100%",
                      // // p: "0",
                    },
                    "@media (max-width: 760px)": {
                      fontSize: "14px",
                      // // p: "0",
                    },
                    "@media (max-width: 700px)": {
                      fontSize: "12px",
                      // // p: "0",
                    },
                    "@media (max-width: 530px)": {
                      fontSize: "14px",
                      // // p: "0",
                    },
                    "@media (max-width: 375px)": {
                      fontSize: "12px",
                      // // p: "0",
                    },
                  }}
                  fontWeight="400"
                  w="92%"
                  borderRadius="8px"
                >
                  {resp?.data?.unreadMaterialsCount
                    ? `${t("You have")} ${
                        resp?.data?.unreadMaterialsCount
                      } ${t("unread materials!")}`
                    : `${t("No unread materials!")}`}
                </Text>
                <FontAwesomeIcon
                  icon={
                    resp?.data?.unreadMaterialsCount
                      ? faTriangleExclamation
                      : faCheck
                  }
                  color={
                    resp?.data?.unreadMaterialsCount ? "#F5CC3A" : "#04C600"
                  }
                />{" "}
              </Flex>
            </CardFooter>
          </Skeleton>
        </Card>

        <Card
          w={{
            base: "calc((100% / 1))",
            sm: "calc((100% / 2) - 12px)",
            md: "calc((100% / 1) - 12px)",
            lg: "calc((100% / 2) - 12px)",
          }}
          bg={colors.lightGreen}
        >
          <Skeleton isLoaded={status != "loading"} h="100%">
            <CardHeader
              sx={{
                "@media (max-width: 650px)": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: "12px",
                },
                "@media (max-width: 500px)": {
                  py: "8px",
                },
              }}
            >
              <Flex
                width="60px"
                h="60px"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bg="white"
                sx={{
                  "@media (max-width: 890px)": {
                    fontSize: "20px",
                    boxSize: "50px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                    boxSize: "40px",
                  },
                }}
              >
                <FontAwesomeIcon
                  color={colors.primary}
                  fontSize="22px"
                  icon={faClipboardList}
                />
              </Flex>
            </CardHeader>
            <CardBody
              py={"0"}
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "8px",
                },
              }}
            >
              <Heading
                fontSize="20px"
                sx={{
                  "@media (max-width: 650px)": {
                    textAlign: "center",
                    my: "12px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                  },
                  "@media (max-width: 530px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 430px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 375px)": {
                    fontSize: "12px",
                  },
                }}
              >
                {t("Exams")}{" "}
              </Heading>
            </CardBody>
            <CardFooter
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "4px",
                },
                "@media (max-width: 650px)": {
                  paddingY: "8px",
                },
                "@media (max-width: 500px)": {
                  paddingY: "6px",
                },
              }}
            >
              <Flex
                bg="white"
                padding="0 12px"
                h="48px"
                borderRadius="8px"
                width="100%"
                alignItems="center"
                // sx={{
                //   "@media (max-width: 890px)": {
                //     padding: "0 8px",
                //   },
                //   "@media (max-width: 700px)": {
                //     padding: "0 4px",
                //   },
                // }}
              >
                <Text
                  fontSize="14px"
                  sx={{
                    "@media (max-width: 890px)": {
                      // fontSize: "12px",
                      w: "100%",
                      // // p: "0",
                    },
                    "@media (max-width: 760px)": {
                      fontSize: "14px",
                      // // p: "0",
                    },
                    "@media (max-width: 700px)": {
                      fontSize: "12px",
                      // // p: "0",
                    },
                    "@media (max-width: 530px)": {
                      fontSize: "14px",
                      // // p: "0",
                    },
                    "@media (max-width: 375px)": {
                      fontSize: "12px",
                      // // p: "0",
                    },
                  }}
                  fontWeight="400"
                  w="92%"
                  borderRadius="8px"
                >
                  {resp?.data?.undoneExamsCount
                    ? `${t("You have")} ${resp?.data?.undoneExamsCount} ${t("undone exams")}!`
                    : t("No undone assignments!")}
                </Text>
                <FontAwesomeIcon
                  icon={
                    resp?.data?.undoneExamsCount
                      ? faTriangleExclamation
                      : faCheck
                  }
                  color={resp?.data?.undoneExamsCount ? "#F5CC3A" : "#04C600"}
                />{" "}
              </Flex>
            </CardFooter>
          </Skeleton>
        </Card>

        <Card
          w={{
            base: "calc((100% / 1))",
            sm: "calc((100% / 2) - 12px)",
            md: "calc((100% / 1) - 12px)",
            lg: "calc((100% / 2) - 12px)",
          }}
          bg={colors.lightGreen}
        >
          <Skeleton isLoaded={status != "loading"} h="100%">
            <CardHeader
              sx={{
                "@media (max-width: 650px)": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: "12px",
                },
                "@media (max-width: 500px)": {
                  py: "8px",
                },
              }}
            >
              <Flex
                width="60px"
                h="60px"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bg="white"
                sx={{
                  "@media (max-width: 890px)": {
                    fontSize: "20px",
                    boxSize: "50px",
                  },
                  "@media (max-width: 700px)": {
                    fontSize: "16px",
                    boxSize: "40px",
                  },
                }}
              >
                <FontAwesomeIcon
                  color={colors.primary}
                  fontSize="22px"
                  icon={faClipboardList}
                />
              </Flex>
            </CardHeader>
            <CardBody
              py={"0"}
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "8px",
                },
              }}
            >
              <Heading
                fontSize="20px"
                sx={{
                  "@media (max-width: 650px)": {
                    textAlign: "center",
                    my: "12px",
                  },
                  "@media (max-width: 890px)": {
                    fontSize: "16px",
                  },
                  "@media (max-width: 530px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 430px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 375px)": {
                    fontSize: "12px",
                  },
                }}
              >
                {t("Attendance")}
              </Heading>
            </CardBody>
            <CardFooter
              sx={{
                "@media (max-width: 900px)": {
                  paddingX: "4px",
                },
                "@media (max-width: 650px)": {
                  paddingY: "8px",
                },
              }}
            >
              <Flex
                padding="0 12px"
                sx={{
                  "@media (max-width: 750px)": {
                    paddingX: "4px",
                  },
                }}
                h="48px"
                bg="white"
                borderRadius="8px"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Progress
                  colorScheme="teal"
                  w="68%"
                  borderRadius="20px"
                  h="16px"
                  sx={{
                    "@media (max-width: 600px)": {
                      h: "12px",
                    },
                    "@media (max-width: 500px)": {
                      h: "12px",
                    },
                  }}
                  value={resp?.data?.attendancePercentage}
                />
                <Text
                  sx={{
                    "@media (max-width: 890px)": {
                      fontSize: "12px",
                    },
                    "@media (max-width: 760px)": {
                      fontSize: "14px",
                    },
                    "@media (max-width: 700px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  {resp?.data?.attendancePercentage}/100%
                </Text>
              </Flex>
            </CardFooter>
          </Skeleton>
        </Card>
      </Flex>
    </>
  );
};

export default GeneralCards;
