import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { resetSecurity } from "../../redux/slices/securitySlice";
import { useTranslation } from "react-i18next";

export default function LogOutModal({
  hoverColor = "red",
  hoverBg = "none",
  color = "black",
  bgColor = "transparent",
  // colorScheme="none"
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const [t] = useTranslation("global");

  const [isLoading, setIsLoading] = useState(false);

  const handleLogOut = () => {
    setIsLoading(true);
    try {
      dispatch(resetSecurity());
    } finally {
      setIsLoading(false);
      onClose();
    }
  };
  return (
    <>
      <Button
        sx={{
          "@media (max-width: 700px)": {
            h: "32px",
            w: "32px",
          },
          "@media (max-width: 500px)": {
            h: "28px",
            w: "28px",
          },
        }}
        variant="ghost"
        onClick={onOpen}
        fontSize="16px"
        transition=".5s"
        _hover={{ bg: hoverBg, color: hoverColor }}
        color={color}
        bgColor={bgColor}
        // colorScheme={colorScheme}
      >
        <FontAwesomeIcon icon={faPowerOff} />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          width={{ base: "70%", sm: "60%", md: "50%", lg: "40%" }}
          containerProps={{ justifyContent: "center" }}
        >
          <ModalHeader>
            <Text as="h1" fontSize="20px" fontWeight="bold">
              {t("logOut.header")}
            </Text>{" "}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            pt={{ base: "0", sm: "0", md: "0" }}
            pb={{ base: "0", sm: 2, md: 2 }}
          >
            <Text
              fontSize={{ base: "14px", sm: "16px", md: "16px", lg: "16px" }}
              fontWeight="500"
            >
              {t("logOut.body")}
            </Text>
          </ModalBody>

          <ModalFooter justifyContent="center" w="100%" margin="4px auto">
            <Button
              w="36%"
              onClick={onClose}
              mr={3}
              fontSize={{ base: "14px", sm: "16px", md: "18px" }}
              height={{ base: "36px", sm: "40px", md: "44px" }}
            >
              {t("logOut.cancel")}
            </Button>
            <Button
              onClick={handleLogOut}
              isLoading={isLoading}
              w="36%"
              colorScheme="red"
              fontSize={{ base: "14px", sm: "16px", md: "18px" }}
              height={{ base: "36px", sm: "40px", md: "44px" }}
            >
              {t("logOut.logOut")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
