import { jwtDecode } from "jwt-decode";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { resetSecurity } from "../redux/slices/securitySlice";

export default function ProtectedRoute({
  children,
  role,
  redirectPath = "/sign-in",
}) {
  let { token } = useSelector((x) => x.security) ?? {};
  const dispatch = useDispatch();
  try {
    let decodedToken = jwtDecode(token);
    const now = Math.ceil(Date.now() / 1000);
    if (!(decodedToken.exp > now)) {
      dispatch(resetSecurity());
      return <Navigate to={redirectPath} />;
    }
    if (decodedToken.role_name != role) return <Navigate to={"/*"} />;
  } catch (error) {
    dispatch(resetSecurity());
    return <Navigate to={redirectPath} />;
  }
  return children;
}
