import { Flex, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { colors } from "../../Constants";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";

function IconAndCard(item) {
  const location = useLocation();

  const singleAssignment = location.pathname.toLowerCase().includes("/student")
    ? `student/assignments/${item.id}`
    : location.pathname.toLowerCase().includes("/teacher")
    ? `teacher/assignments/${item.id}`
    : location.pathname.toLowerCase().includes("/teacher")
    ? `manager/assignments/${item.id}`
    : `some-other-path/${item.id}`;
  return (
    <div>
      <Flex
        padding="16px 20px"
        sx={{
          "@media (max-width: 610px)": {
            padding: "8px 8px",
          },
          "@media (max-width: 420px)": {
            padding: "4px",
          },
        }}
        border="2px solid"
        borderColor={colors.primary}
        borderRadius="12px"
        color={colors.primary}
        alignItems="center"
        justifyContent="space-between"
      >
        <Link to={`/${singleAssignment}`}>
          <HStack
            fontSize="32px"
            sx={{
              "@media (max-width: 610px)": {
                fontSize: "30px",
              },
              "@media (max-width: 420px)": {
                fontSize: "20px",
              },
            }}
            transition=".2s"
            _hover={{ color: "#3a706e" }}
          >
            <FontAwesomeIcon icon={faClipboardList} />
            <Text
              sx={{
                "@media (max-width: 610px)": {
                  fontSize: "18px",
                },
                "@media (max-width: 500px)": {
                  fontSize: "16px",
                },
                "@media (max-width: 420px)": {
                  fontSize: "12px",
                },
              }}
              fontSize="18px"
              fontWeight="600"
              w="100%"
            >
              {item.title}
            </Text>
          </HStack>
        </Link>
        <Text
          sx={{
            "@media (max-width: 500px)": {
              fontSize: "12px",
            },
            "@media (max-width: 420px)": {
              fontSize: "10px",
            },
          }}
          color="black"
          textAlign="end"
          w="44%"
        >
          {item.deadline}
        </Text>
      </Flex>
    </div>
  );
}

export default IconAndCard;
