import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCoTeacherByIdPromise } from "../services/teacherService";
import { faClipboardList, faUsers } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import PageTitle from "../general/components/PageTitle";
import { useTranslation } from "react-i18next";
import ProfilePicture from "../general/components/ProfilePicture";

export default function SingleCoTeacher() {
  const { id } = useParams();
  const { token } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(
    ["single-co-teacher", token, id],
    () => getCoTeacherByIdPromise(id, token)
  );

  const [t] = useTranslation("global");

  return (
    <Flex>
      <Box display={["none", "none", "block", "block"]} width="25%"></Box>
      <Container maxW={["100%", "100%", "75%", "75%"]}>
        <Box>
          <PageTitle
            status={"success"}
            title={t("sidebar.teachers.teacher")}
            icon={faUsers}
            backPath={'/teacher/co-teachers'}
          />
        </Box>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="100%"
          margin="0 auto 3rem"
          p="2.5rem 0"
          gap="24px"
          bg="#EAF5F4"
          borderRadius="13px"
        >
          <ProfilePicture
                       sizePx={["100px", "130px", "140px", "160px", "170px"]}

            url={resp?.data?.profilePictureKey}
          />
          <FormControl w={["90%", "80%", "70%", "80%", "60%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.name")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
               <Input fontSize={["10px", "10px", "12px", "14px", "16px"]}
                disabled={true}
                value={resp?.data?.name}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="text"
                placeholder={t("table.name")}
              />
            </Skeleton>
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "60%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.surname")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
               <Input fontSize={["10px", "10px", "12px", "14px", "16px"]}
                disabled={true}
                value={resp?.data?.surname}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="text"
                placeholder={t("table.surname")}
              />
            </Skeleton>
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "60%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.email")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
               <Input fontSize={["10px", "10px", "12px", "14px", "16px"]}
                disabled={true}
                value={resp?.data?.email}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="email"
                placeholder={t("table.email")}
              />
            </Skeleton>
          </FormControl>
          <FormControl w={["90%", "80%", "70%", "80%", "60%"]}>
            <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
              {t("table.phone")}
            </FormLabel>
            <Skeleton isLoaded={status == "success"}>
               <Input fontSize={["10px", "10px", "12px", "14px", "16px"]}
                disabled={true}
                value={resp?.data?.phone}
                bg="white"
                borderRadius="4px"
                p="0 12px"
                type="text"
                placeholder={t("table.phone")}
              />
            </Skeleton>
          </FormControl>
        </Flex>
      </Container>
    </Flex>
  );
}
