import Axios from "axios";

const api = Axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      return error;
    }
    return error;
  }
);

class HttpClient {
  get(url, configs) {
    return api.get(url, configs);
  }

  post(url, data, configs) {
    return api.post(url, data, configs);
  }

  put(url, data, configs) {
    return api.put(url, data, configs);
  }

  delete(url, config) {
    return api.delete(url, config);
  }
}

export const httpClient = new HttpClient();
