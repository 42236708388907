import { configureStore } from "@reduxjs/toolkit";
import securityReducer from "./slices/securitySlice";
import languageReducer from "./slices/languageSlice"; 
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { encryptTransform } from "redux-persist-transform-encrypt";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["security","language"],
  transforms: [
    encryptTransform({
      secretKey: "study-port-secret-key-444",
      onError: function (error) {
        
      },
    }),
  ],
};

const reducer = combineReducers({
  security: securityReducer,
  language: languageReducer,

});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
});
