import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Radio,
  RadioGroup,
  Input,
  Button,
  Checkbox,
  Flex,
  Select,
  Switch,
  Text,
  Divider,
} from "@chakra-ui/react";
import { colors } from "../Constants";
import MyJoditEditor from "../helpers/MyJoditEditor";
import useMyJoditEditor from "../hooks/useMyJoditEditor";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTrash } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { useMutation, useQueryClient } from "react-query";
import { createQuestionPromise } from "../services/examService";
import { useSelector } from "react-redux";
import {
  getAddQuestionSchema,
} from "./validations/addQuestionSchema";
import { useTranslation } from "react-i18next";

const Question = ({ isGraded, questionId, setQuestions }) => {
  const { t } = useTranslation("global");
  const validationSchema = getAddQuestionSchema(t);
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { token } = useSelector((x) => x.security) || {};
  const ref = useRef(null);
  const { content, isDirty, onBlur, fileKey, isSaving } = useMyJoditEditor();

  const { mutate, isLoading } = useMutation(
    (data) => createQuestionPromise(data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["teacher-exam-overview"],
        });
        setQuestions((prev) => prev.filter((x) => x.id != questionId));
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      description: null,
      examId: id,
      coefficient: null,
      isRequired: false,
      questionType: null,
      options: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.description = fileKey;
      mutate(values);
    },
  });

  useEffect(()=>{
    formik.setFieldValue('description', content)
  },[content])

  const handleAddOption = () => {
    if (ref.current.value.trim() !== "") {
      formik.setFieldValue("options", [
        ...formik.values.options,
        {
          id: uuidv4(),
          description: ref.current.value,
          isCorrect: false,
          correctAnswerText: null,
        },
      ]);
      ref.current.value = "";
    }
  };

  const handleSelectChange = (e) => {
    let selectedOption = parseInt(e.target.value);
    formik.setFieldValue('questionType', selectedOption)
    if (selectedOption == 2) {
      formik.setFieldValue("options", [
        {
          id: uuidv4(),
          description: null,
          isCorrect: true,
          correctAnswerText: null,
        },
      ]);
    } else {
      formik.setFieldValue("options", []);
    }
  };

  const handleCorrectTextAnswerChange = (e) => {
    formik.setFieldValue("options", [
      {
        description: null,
        isCorrect: true,
        correctAnswerText: e.target.value,
      },
    ]);
  };

  const handleCorrectMultipleChoiceOptionChange = (id) => {
    let newOptions = formik.values.options.map((x) => {
      if (x.id == id) {
        x.isCorrect = true;
      } else {
        x.isCorrect = false;
      }
      return x;
    });
    formik.setFieldValue("options", newOptions);
  };

  const handleCorrectCheckboxOptionChange = (e) => {
    let isChecked = e.target.checked;
    let id = e.target.value;
    let newOptions = formik.values.options.map((x) => {
      if (x.id == id) x.isCorrect = isChecked;
      return x;
    });
    formik.setFieldValue("options", newOptions);
  };

  const handleDeleteOption = (id) => {
    let newOptions = formik.values.options.filter((x) => x.id != id);
    formik.setFieldValue("options", newOptions);
  };

  return (
    <Box w="100%">
      <FormControl
        w="100%"
        sx={{
          "@media (max-width: 768px)": {
            display: "flex",
            flexDirection: "column",
          },
        }}
        // gap="20px"
        display="flex"
        justifyContent="space-between"
      >
        <Box
          width={["96%", "90%", "50%", "50%", "50%"]}
          mt="12px"
          sx={{
            "@media (max-width: 768px)": {
              w: "100%",
              margin: "0 auto",
            },
          }}
        >
          <Box pointerEvents="none">
            <FormLabel fontWeight="600" pl="9px" fontSize={["16px", "18px"]}>
              {t("Type the question...")}
            </FormLabel>
          </Box>
          <Box
            mb="1rem"
            pb={"20px"}
            border="2px solid "
            padding="8px"
            borderColor={colors.primary}
            borderRadius="12px"
          >
            <MyJoditEditor
              isInline={true}
              content={content}
              onBlur={onBlur}
              isSaving={isSaving}
            />
            {formik.errors.description && formik.touched.description && (
              <Text pt="4px" m="0" color="red">
                {formik.errors.description}
              </Text>
            )}
          </Box>

          {formik.values.questionType != null ? (
            <>
              {formik.values.questionType == 2 ? (
                <Textarea
                  mb="12px"
                  resize={"none"}
                  rows="1"
                  _hover="none"
                  _focus={{
                    boxShadow: "none",
                    outline: "none",
                    border: "1px solid transparent",
                    borderBottomColor: "#BDBCBC",
                  }}
                  border="1px solid transparent"
                  borderRadius="0"
                  borderBottomColor="#BDBCBC"
                  placeholder={t("Enter the correct answer, or leave empty")}
                  value={formik.values.options[0]?.correctAnswerText}
                  onChange={handleCorrectTextAnswerChange}
                />
              ) : (
                <>
                  {formik.values.questionType == 0 ? (
                    <RadioGroup
                      onChange={handleCorrectMultipleChoiceOptionChange}
                    >
                      <VStack alignItems="start">
                        {formik.values.options.map((option, index) => (
                          <Flex
                            minW="100px"
                            justifyContent="space-between"
                            alignItems={"center"}
                          >
                            <Radio key={index} value={option.id}>
                              {option.description}
                            </Radio>
                            <FontAwesomeIcon
                              onClick={() => handleDeleteOption(option.id)}
                              style={{ marginLeft: "20px", color: "red" }}
                              icon={faClose}
                            />
                          </Flex>
                        ))}
                      </VStack>
                    </RadioGroup>
                  ) : (
                    <></>
                  )}

                  {formik.values.questionType == 1 ? (
                    <VStack alignItems="start">
                      {formik.values.options.map((option, index) => (
                        <Flex
                          minW="100px"
                          justifyContent="space-between"
                          alignItems={"center"}
                        >
                          <Checkbox
                            onChange={handleCorrectCheckboxOptionChange}
                            key={index}
                            value={option.id}
                          >
                            {option.description}
                          </Checkbox>
                          <FontAwesomeIcon
                            onClick={() => handleDeleteOption(option.id)}
                            style={{ marginLeft: "20px", color: "red" }}
                            icon={faClose}
                          />
                        </Flex>
                      ))}
                    </VStack>
                  ) : (
                    <></>
                  )}
                  {formik.errors.options && formik.touched.options && (
                    <Text pt="4px" m="0" color="red">
                      {formik.errors.options}
                    </Text>
                  )}
                  <Flex margin="20px 0" gap="12px" alignItems="center">
                    <Input ref={ref} placeholder={t("Enter new option...")} />
                    <Button w="140px" onClick={handleAddOption}>
                      {t("Add")}
                    </Button>
                  </Flex>
                </>
              )}
            </>
          ) : (
            <Text
              fontWeight={"700"}
              color={colors.primary}
              fontSize={["16px", "18px", "20px"]}
            >
              {t("Select question type to get started.")}
            </Text>
          )}
        </Box>
        <Box
          w="40%"
          sx={{
            "@media (max-width: 768px)": {
              w: "100%",
            },
          }}
        >
          <Divider
            w="100%"
            mb="16px"
            border="1px dashed "
            borderColor={colors.primary}
            borderWidth="1px"
            display={{ base: "block", sm: "block", md: "none" }}
          />
          <VStack alignItems="start">
            <FormControl w="90%" gap="20px">
              <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                {t("Question type")}
              </FormLabel>
              <Select onChange={handleSelectChange}>
                <option selected disabled value={null}>
                  {t("Select question type")}
                </option>
                <option value={0}>{t("Multiple choice")}</option>
                <option value={1}>{t("Checkboxes")}</option>
                <option value={2}>{t("Text")}</option>
              </Select>
            </FormControl>

            <FormControl w="90%" gap="20px">
              <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                {t("Coefficient")}
                <FormLabel fontSize="12px" fontWeight="400" color="#fcbe03">
                  {t(
                    "Coefficients are essential for calculating the grade proportionally. If you leave a coefficient blank, that question won't impact the overall grade. If all questions are left without coefficients, the overall grade will be 0, regardless of the answers."
                  )}
                </FormLabel>
              </FormLabel>
              <Input
                name="coefficient"
                onChange={formik.handleChange}
                value={formik.values.coefficient}
                onBlur={formik.handleBlur}
                type="number"
              />
              {formik.errors.coefficient && formik.touched.coefficient && (
                <Text pt="4px" m="0" color="red">
                  {formik.errors.coefficient}
                </Text>
              )}
            </FormControl>

            <FormControl gap="20px">
              <Box pointerEvents="none">
                {" "}
                <FormLabel color={"#00000061"} fontWeight="700" fontSize="14px">
                  {t("Required")}
                </FormLabel>
              </Box>
              <Switch
                name="isRequired"
                isChecked={formik.values.isRequired}
                onChange={formik.handleChange}
              />
            </FormControl>

            <Flex columnGap={"10px"} width={"100%"} justifyContent={"end"}>
              <Button
                isLoading={isLoading}
                isDisabled={(!formik.dirty && !isDirty) || isSaving}
                mt={"1rem"}
                bg={colors.primary}
                color="white"
                onClick={formik.handleSubmit}
                w="50%"
                h="40px"
                fontWeight="600"
                fontSize="16px"
                _hover={{ bg: "#2a6b68" }}
              >
                {t("Save")}
              </Button>

              <Button
                mt={"1rem"}
                colorScheme="red"
                color="white"
                onClick={() =>
                  setQuestions((prev) => {
                    return prev.filter((x) => x.id != questionId);
                  })
                }
                gap="16px"
                w="50%"
                h="40px"
                fontWeight="600"
                fontSize="16px"
                _hover={{ bg: "darkred" }}
              >
                <FontAwesomeIcon icon={faTrash} />
                <Text>{t("Delete")}</Text>
              </Button>
            </Flex>
          </VStack>
        </Box>
      </FormControl>
    </Box>
  );
};

export default Question;
