import { Flex, HStack, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { colors } from "../../Constants";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { s3Client } from "../../helpers/s3Client";

export default function ReadOnlyFile({ fileKey }) {
  return (
    <Flex
      py={"10px"}
      px={"4px"}
      w={["200px","220px"]}
      height={"max-content"}
      bg={"white"}
      gap="12px"
      borderRadius={"12px"}
      direction={"column"}
      border="2px solid #D9D9D975"
      _hover={{ cursor: "pointer" }}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <HStack gap="12px">
          <FontAwesomeIcon
            color={colors.gray}
            fontSize={["18px"]}
            icon={faPaperclip}
          />
          <Text
            whiteSpace={"nowrap"}
            maxW={"160px"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            fontSize={["12px", "12px", "14px", "16px", "16px"]}

            onClick={() => window.open(s3Client.getUrl(fileKey, "_blank"))}
            _hover={{ color: "purple", textDecoration: "underline" }}
          >
            {fileKey.split(/_(.*)/s)[1]}
          </Text>
        </HStack>
      </Flex>
    </Flex>
  );
}
