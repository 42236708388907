import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { colors } from "../../Constants";
import {
  faCheck,
  faClipboardList,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "react-query";
import { getInstitutionStatisticsPromise } from "../../services/statisticsService";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const GeneralCards = ({}) => {
  const { token, institutionId } = useSelector((x) => x.security) || {};
  const { data: resp, status } = useQuery(
    ["institution-statistics", token, institutionId],
    () => getInstitutionStatisticsPromise(institutionId, token)
  );

  const { t } = useTranslation("global");
  return (
    <Flex
      justifyContent="space-between"
      flexWrap="wrap"
      // gap="24px"
      gap={{ base: "12px", md: "24px" }}
      w={`calc((100% / 3 * 2) - 16px)`}
      sx={{
        "@media (max-width: 992px)": {
          w: `calc(71% - 8px )`,
        },
        "@media (max-width: 768px)": {
          w: "100%",
        },
      }}
    >
      <Card
        w={{
           base: "calc((100% / 1))",
          sm: "calc((100% / 2) - 12px)",
          md: "calc((100% / 1) - 12px)",
          lg: "calc((100% / 2) - 12px)",
        }}
        bg={colors.lightGreen}
      >
        <Skeleton h="100%" isLoaded={status == "success"}>
          <CardHeader
            sx={{
              "@media (max-width: 650px)": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                py: "12px",
              },
              "@media (max-width: 500px)": {
                py: "8px",
              },
            }}
          >
            <Flex
              width="60px"
              h="60px"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              bg="white"
              sx={{
                "@media (max-width: 890px)": {
                  fontSize: "20px",
                  boxSize: "50px",
                },
                "@media (max-width: 890px)": {
                  fontSize: "16px",
                  boxSize: "40px",
                },
              }}
            >
              <FontAwesomeIcon
                color={colors.primary}
                fontSize="24px"
                icon={faClipboardList}
              />
            </Flex>
          </CardHeader>
          <CardBody
            py={"0"}
            sx={{
              "@media (max-width: 900px)": {
                paddingX: "8px",
              },
            }}
          >
            <Heading
              fontSize="20px"
              sx={{
                "@media (max-width: 650px)": {
                  textAlign: "center",
                  my: "12px",
                },
                "@media (max-width: 890px)": {
                  fontSize: "16px",
                },
                "@media (max-width: 530px)": {
                  fontSize: "12px",
                },
                "@media (max-width: 430px)": {
                  fontSize: "12px",
                },
                "@media (max-width: 375px)": {
                  fontSize: "12px",
                },
              }}
            >
              {t("sidebar.groups.groups")}
            </Heading>
          </CardBody>
          <CardFooter
            sx={{
              "@media (max-width: 900px)": {
                paddingX: "8px",
              },
              "@media (max-width: 650px)": {
                paddingY: "8px",
              },
              "@media (max-width: 500px)": {
                paddingY: "6px",
              },
            }}
          >
            <Flex
              bg="white"
              padding="0 12px"
              h="48px"
              borderRadius="8px"
              width="100%"
              alignItems="center"
              // sx={{
              //   "@media (max-width: 890px)": {
              //     padding: "0 8px",
              //   },
              //   "@media (max-width: 700px)": {
              //     padding: "0 4px",
              //   },
              // }}
            >
              <Text
                fontSize="14px"
                sx={{
                  "@media (max-width: 890px)": {
                    // fontSize: "12px",
                    w: "100%",
                    // // p: "0",
                  },
                  "@media (max-width: 760px)": {
                    fontSize: "14px",
                    // // p: "0",
                  },
                  "@media (max-width: 700px)": {
                    fontSize: "12px",
                    // // p: "0",
                  },
                  "@media (max-width: 530px)": {
                    fontSize: "14px",
                    // // p: "0",
                  },
                  "@media (max-width: 375px)": {
                    fontSize: "12px",
                    // // p: "0",
                  },
                }}
                fontWeight="400"
                w="92%"
                borderRadius="8px"
              >
                {t("generalPage.groupsCard")}
              </Text>
              <Text fontWeight={"bold"} color={"#04C600"}>
                {resp?.data?.numberOfGroups}
              </Text>
            </Flex>
          </CardFooter>
        </Skeleton>
      </Card>

      <Card
        w={{
           base: "calc((100% / 1))",
          sm: "calc((100% / 2) - 12px)",
          md: "calc((100% / 1) - 12px)",
          lg: "calc((100% / 2) - 12px)",
        }}
        bg={colors.lightGreen}
      >
        <Skeleton h="100%" isLoaded={status == "success"}>
          <CardHeader
            sx={{
              "@media (max-width: 650px)": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                py: "12px",
              },
              "@media (max-width: 500px)": {
                py: "8px",
              },
            }}
          >
            <Flex
              width="54px"
              h="54px"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              bg="white"
              sx={{
                "@media (max-width: 890px)": {
                  fontSize: "20px",
                  boxSize: "50px",
                },
                "@media (max-width: 890px)": {
                  fontSize: "16px",
                  boxSize: "40px",
                },
              }}
            >
              <FontAwesomeIcon
                color={colors.primary}
                fontSize="24px"
                icon={faClipboardList}
              />
            </Flex>
          </CardHeader>
          <CardBody
            py={"0"}
            sx={{
              "@media (max-width: 900px)": {
                paddingX: "8px",
              },
            }}
          >
            <Heading
              fontSize="20px"
              sx={{
                "@media (max-width: 650px)": {
                  textAlign: "center",
                  my: "12px",
                },
                "@media (max-width: 890px)": {
                  fontSize: "16px",
                },
                "@media (max-width: 530px)": {
                  fontSize: "12px",
                },
                "@media (max-width: 430px)": {
                  fontSize: "12px",
                },
                "@media (max-width: 375px)": {
                  fontSize: "12px",
                },
              }}
            >
              {t("sidebar.students")}
            </Heading>
          </CardBody>
          <CardFooter
            sx={{
              "@media (max-width: 900px)": {
                paddingX: "8px",
              },
              "@media (max-width: 650px)": {
                paddingY: "8px",
              },
              "@media (max-width: 500px)": {
                paddingY: "6px",
              },
            }}
          >
            <Flex
              bg="white"
              padding="0 12px"
              h="48px"
              borderRadius="8px"
              width="100%"
              alignItems="center"
              sx={{
                "@media (max-width: 890px)": {
                  padding: "0 8px",
                },
                "@media (max-width: 700px)": {
                  padding: "0 4px",
                },
              }}
            >
              <Text
                fontSize="14px"
                sx={{
                  "@media (max-width: 890px)": {
                    // fontSize: "12px",
                    w: "100%",
                    // p: "0",
                  },
                  "@media (max-width: 760px)": {
                    fontSize: "14px",
                    // p: "0",
                  },
                  "@media (max-width: 700px)": {
                    fontSize: "12px",
                    // p: "0",
                  },
                  "@media (max-width: 530px)": {
                    fontSize: "14px",
                    // // p: "0",
                  },
                  "@media (max-width: 375px)": {
                    fontSize: "12px",
                    // p: "0",
                  },
                }}
                fontWeight="400"
                w="92%"
                borderRadius="8px"
              >
                {t("generalPage.studentsCard")}
              </Text>
              <Text fontWeight={"bold"} color={"#04C600"}>
                {resp?.data?.numberOfStudents}
              </Text>
            </Flex>
          </CardFooter>
        </Skeleton>
      </Card>

      <Card
        w={{
           base: "calc((100% / 1))",
          sm: "calc((100% / 2) - 12px)",
          md: "calc((100% / 1) - 12px)",
          lg: "calc((100% / 2) - 12px)",
        }}
        bg={colors.lightGreen}
      >
        <Skeleton h="100%" isLoaded={status != "loading"}>
          <CardHeader
            sx={{
              "@media (max-width: 650px)": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                py: "12px",
              },
              "@media (max-width: 500px)": {
                py: "8px",
              },
            }}
          >
            <Flex
              width="60px"
              h="60px"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              bg="white"
              sx={{
                "@media (max-width: 890px)": {
                  fontSize: "20px",
                  boxSize: "50px",
                },
                "@media (max-width: 890px)": {
                  fontSize: "16px",
                  boxSize: "40px",
                },
              }}
            >
              <FontAwesomeIcon
                color={colors.primary}
                fontSize="24px"
                icon={faClipboardList}
              />
            </Flex>
          </CardHeader>
          <CardBody
            py={"0"}
            sx={{
              "@media (max-width: 900px)": {
                paddingX: "8px",
              },
            }}
          >
            <Heading
              fontSize="20px"
              sx={{
                "@media (max-width: 650px)": {
                  textAlign: "center",
                  my: "12px",
                },
                "@media (max-width: 890px)": {
                  fontSize: "16px",
                },
                "@media (max-width: 530px)": {
                  fontSize: "12px",
                },
                "@media (max-width: 430px)": {
                  fontSize: "12px",
                },
                "@media (max-width: 375px)": {
                  fontSize: "12px",
                },
              }}
            >
              {t("sidebar.teachers.teachers")}
            </Heading>
          </CardBody>

          <CardFooter
            sx={{
              "@media (max-width: 900px)": {
                paddingX: "8px",
              },
              "@media (max-width: 650px)": {
                paddingY: "8px",
              },
              "@media (max-width: 500px)": {
                paddingY: "6px",
              },
            }}
          >
            <Flex
              bg="white"
              padding="0 12px"
              minH="48px"
              borderRadius="8px"
              width="100%"
              alignItems="center"
              // sx={{
              //   "@media (max-width: 890px)": {
              //     padding: "0 8px",
              //   },
              //   "@media (max-width: 700px)": {
              //     padding: "0 4px",
              //   },
              // }}
            >
              <Text
                fontSize="14px"
                sx={{
                  "@media (max-width: 890px)": {
                    // fontSize: "12px",
                    w: "100%",
                    // p: "0",
                  },
                  "@media (max-width: 760px)": {
                    fontSize: "14px",
                    // p: "0",
                  },
                  "@media (max-width: 700px)": {
                    fontSize: "12px",
                    // p: "0",
                  },
                  "@media (max-width: 530px)": {
                    fontSize: "14px",
                    // p: "0",
                  },
                  "@media (max-width: 375px)": {
                    fontSize: "12px",
                    // p: "0",
                  },
                }}
                fontWeight="400"
                w="92%"
                borderRadius="8px"
              >
                {t("generalPage.teachersCard")}
              </Text>
              <Text fontWeight={"bold"} color={"#04C600"}>
                {resp?.data?.numberOfTeachers}
              </Text>
            </Flex>
          </CardFooter>
        </Skeleton>
      </Card>

      <Card
        w={{
           base: "calc((100% / 1))",
          sm: "calc((100% / 2) - 12px)",
          md: "calc((100% / 1) - 12px)",
          lg: "calc((100% / 2) - 12px)",
        }}
        bg={colors.lightGreen}
      >
        <Skeleton h="100%" isLoaded={status != "loading"}>
          <CardHeader
            sx={{
              "@media (max-width: 650px)": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                py: "12px",
              },
              "@media (max-width: 500px)": {
                py: "8px",
              },
            }}
          >
            <Flex
              width="60px"
              h="60px"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              bg="white"
              sx={{
                "@media (max-width: 890px)": {
                  fontSize: "20px",
                  boxSize: "50px",
                },
                "@media (max-width: 700px)": {
                  fontSize: "16px",
                  boxSize: "40px",
                },
              }}
            >
              <FontAwesomeIcon
                color={colors.primary}
                fontSize="24px"
                icon={faUserCheck}
              />
            </Flex>
          </CardHeader>
          <CardBody
            py={"0"}
            sx={{
              "@media (max-width: 900px)": {
                paddingX: "8px",
              },
            }}
          >
            <Heading
              fontSize="20px"
              sx={{
                "@media (max-width: 650px)": {
                  textAlign: "center",
                  my: "12px",
            
                },
                "@media (max-width: 890px)": {
                  fontSize: "16px",
                },
                "@media (max-width: 530px)": {
                  fontSize: "12px",
                },
                "@media (max-width: 430px)": {
                  fontSize: "12px",
                },
                "@media (max-width: 375px)": {
                  fontSize: "12px",
                },
              }}
            >
              {t("sidebar.studentPayments")}
            </Heading>
          </CardBody>
          <CardFooter
            sx={{
              "@media (max-width: 900px)": {
                paddingX: "8px",
              },
              "@media (max-width: 650px)": {
                paddingY: "8px",
              },
            }}
          >
            <Flex
              padding="0 12px"
              sx={{
                "@media (max-width: 750px)": {
                  paddingX: "8px",
                },
              }}
              h="48px"
              bg="white"
              borderRadius="8px"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                fontSize="14px"
                sx={{
                  "@media (max-width: 890px)": {
                    // fontSize: "12px",
                    w: "100%",
                    // p: "0",
                  },
                  "@media (max-width: 760px)": {
                    fontSize: "14px",
                    // p: "0",
                  },
                  "@media (max-width: 700px)": {
                    fontSize: "12px",
                    // p: "0",
                  },
                  "@media (max-width: 530px)": {
                    fontSize: "14px",
                    // p: "0",
                  },
                  "@media (max-width: 375px)": {
                    fontSize: "12px",
                    // p: "0",
                  },
                }}
                fontWeight="400"
                w="92%"
                borderRadius="8px"
              >
                {t("generalPage.studentPaymentsCard")}
              </Text>
              <Text
                fontWeight={"bold"}
                color={resp?.data?.unpaidStudentPayments ? "red" : "#04C600"}
              >
                {resp?.data?.unpaidStudentPayments}
              </Text>
            </Flex>
          </CardFooter>
        </Skeleton>
      </Card>
    </Flex>
  );
};

export default GeneralCards;
